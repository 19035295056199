import { useEffect, useState } from 'react';
import ListSelector from '../../../tools/list_selector';
import Autocomplete from '../../../tools/auto-complete';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../../hooks/use-store';
import { safe_get } from '../../../utils';
import { SideOverlay } from '../../../../side-overlay';
import ContentEditableTextArea from '../../../../pages/tools/editable-textarea/editable-textarea';
import { emitObject } from '../../../../pages/tools/interfaces/interfaces';
import FetchExamples from '../../../sop-components/fetch_examples/fetch_examples';
import FetchTemplates from '../../../sop-components/fetch_templates/fetch_templates';
import { useParams } from 'react-router';
import { toJS } from 'mobx';

const About_college = observer((props: any) => {
  const { sopStore ,sopProfileSelectionStore , newContentStore} = useStore();
  const [aboutCollegeData, setAboutCollegeData] = useState({});
  const [infoIconClicked, setInfoIconClicked] = useState(false);
  const [suggestion, setSuggestion] = useState({});
  const [selectedOption, setSelectedOption] = useState({});
  const [university, setUniversity] = useState({});
  const [course, setCourse] = useState({});
  const {id} = useParams()

  

  const regex = /enter your text/;

  useEffect(() => {
    if(id=='155'){
    sopStore.getTemplates({
      tool_id: 155,
      screen: 'S1',
      fileid:sopStore.sopId!=155 ? sopStore.sopId : 0
    });
    sopStore.getExamples({
      tool_id: 155,
      screen: 'S1',
    });
  }
  else{
    sopStore.getTemplates({
      tool_id: 152,
      screen: 'S1',
      fileid:sopStore.sopId!=152 ? sopStore.sopId : 0
    });
    sopStore.getExamples({
      tool_id: 152,
      screen: 'S1',
    });
  }
  }, []);

  const onBlurTextArea = (textAreaEvent: emitObject) => {
    if (sopStore.final_output_screen) {
      setAboutCollegeData({ ...aboutCollegeData, [textAreaEvent.key]: textAreaEvent.value });
    } else {
      sopStore.updateSopJsonData(textAreaEvent.key, textAreaEvent.value);
    }
  };

  const template_close_handler = () => {
    sopStore.toggle_show_template();
  };

  const onTemplateClicked = (question, slot) => {
    sopStore.setSlot(slot);
    sopStore.set_selected_textarea(question, '', '');
    sopStore.toggle_show_template();
  };

  const templatesAndExamplesLength = () => {
    if (infoIconClicked) {
      if (
        sopStore.examplesForScreen &&
        sopStore.examplesForScreen[sopStore.selected_text_area.name].length > 0
      ) {
        return sopStore.examplesForScreen[sopStore.selected_text_area.name].length;
      }
    } else {
      if (
        sopStore.templatesForScreen?.[sopStore.selected_text_area.name] &&
        sopStore.templatesForScreen[sopStore.selected_text_area.name].length > 0
      ) {
        return sopStore.templatesForScreen[sopStore.selected_text_area.name].length;
      } else return 0;
    }
  };

  useEffect(()=>{

    if(sopProfileSelectionStore.clearSopStep === false && newContentStore.libraryClick === false){
      if(sopStore.isAustraliaSop === true){
     sopStore.clearSop();
     setUniversity("");
     setSelectedOption({});
      setCourse("");
      }
    }
  },[])

  return (
    <>
      <div className="flex flex-col justify-center">
        <p className="text-[#BF66FF] text-base font-normal uppercase mt-5 mb-2 ">Basic Details</p>

        <div className="flex justify-between gap-10">
          <Autocomplete
            placeholder={'Enter College / University'}
            slot={'university'}
            label={'College / University'}
            val={safe_get(sopStore.sopjsonData, 'university.label', '')}
            required={true}
            sopStore={sopStore}
            setSuggestion={setSuggestion}
            value={university}
            setValue={setUniversity}
          />
          <ListSelector
            placeholder={'Select Degree'}
            slot={'degree'}
            label={'Degree'}
            val={safe_get(sopStore.sopjsonData, 'degree', {})}
            required={true}
            sopStore={sopStore}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
          <Autocomplete
            placeholder={'Enter Course'}
            slot={'course'}
            label={'Course'}
            val={safe_get(sopStore.sopjsonData, 'course.label', '')}
            required={true}
            sopStore={sopStore}
            setSuggestion={setSuggestion}
            value={course}
            setValue={setCourse}
          />
        </div>

        <p className="text-[#BF66FF] text-base font-normal uppercase mt-7 mb-2 ">Your Interests</p>
        {sopStore.questions?.map((item: any) => {
          if (item?.screen == 'S1' && item?.slots !== null) {
            return (
              <ContentEditableTextArea
                placeholder={
                  'E.g. - I want to master ‘your field’, so to expand my domain knowledge and make better choices for my career.'
                }
                onTemplateClicked={() => onTemplateClicked(item.question, item.slots)}
                setInfoIconClicked={setInfoIconClicked}
                onBlur={onBlurTextArea}
                htmlText={sopStore.sopjsonData[item['slots']]}
                heightInRem={8}
                display={item['question']}
                slot={item['slots']}
                regex={regex}
                showTemplateIcon={
                  sopStore.final_output_screen
                    ? false
                    : true && sopStore.templateExists(item['question'])
                }
                showInfoIcon={
                  sopStore.final_output_screen
                    ? false
                    : true && sopStore.exampleExists(item['question'])
                }
              />
            );
          }
        })}
      </div>
      <SideOverlay
        children={
          <div>
            {infoIconClicked ? (
              <FetchExamples sopStore={sopStore} />
            ) : (
              <FetchTemplates
                setState={setAboutCollegeData}
                state={aboutCollegeData}
                sopStore={sopStore}
              />
            )}
          </div>
        }
        onClose={template_close_handler}
        show={sopStore.showTemplate}
        title="Templates"
        length={
          templatesAndExamplesLength()
        }
      />
    </>
  );
});

export default About_college;
