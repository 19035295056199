
export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const makeid_alpa_numeric = (length) => {
  var result = '';
  var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;

  for (var i = 0; i < length; i++) {
      var randomIndex = getRandomInt(charactersLength);
      result += characters.charAt(randomIndex);
  }

  return result;
};

function getRandomInt(max) {
  var randomBuffer = new Uint32Array(1);
  window.crypto.getRandomValues(randomBuffer);
  return randomBuffer[0] % max;
}

