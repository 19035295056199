import { observer } from 'mobx-react-lite';
import { useStore } from '../../hooks/use-store';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Dialog, Transition } from '@headlessui/react';
import PDF from './PDF';
import coinsIcon from '../../../src/icons/coins.svg';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { SopSelectionModal } from '../sop-file/sop-selection-modal';
import { useNavigate, useParams } from 'react-router';
import React from 'react';
import { toJS } from 'mobx';


const SopFooter = observer((props: any) => {
  const {
    newContentStore,
    sopProfileSelectionStore,
    userStore,
    canadaSopStore,
    editorStore
  } = useStore();
  const { sopStore, steps } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const generatecontent = async () => {
    await saveandnextclickhandler();
    if(newContentStore.libraryClick){
      sopStore.setFolderId(Number(params.folderid)??0);
      if(window.location.pathname.includes('/sop')){
        navigate(`/sop/${sopStore.sopId}/${sopStore.parentId}`);
      }
      else if(window.location.pathname.includes('/canadasop')){
        navigate(`/canadasop/${sopStore.sopId}/${sopStore.parentId}`);
      }
      else if(window.location.pathname.includes('/australiasop')){
        navigate(`/australiasop/${sopStore.sopId}/${sopStore.parentId}`);
      }
      else if(window.location.pathname.includes('/uksop')){
        navigate(`/uksop/${sopStore.sopId}/${sopStore.parentId}`);
      }
      else if(window.location.pathname.includes('/lor')){
        navigate(`/lor/${sopStore.sopId}/${sopStore.parentId}`);
      }
      else if(window.location.pathname.includes('/germanysop')){
        navigate(`/germanysop/${sopStore.sopId}/${sopStore.parentId}`);
      }
      else {
        navigate(`/sop/${sopStore.sopId}/${sopStore.folderid}`);
      
    }

    }
    else{
    if (sopStore.toolId == 155 || window.location.pathname.includes('/australiasop')) {
        navigate(`/australiasop/${sopStore.sopId}/${sopStore.folderid}`);
    } 
    else if (sopStore.toolId == 152 || window.location.pathname.includes('/sop')) {
        navigate(`/sop/${sopStore.sopId}/${sopStore.folderid}`);
    }
    else if (sopStore.toolId == 154 || window.location.pathname.includes('/canadasop')) {
        navigate(`/canadasop/${sopStore.sopId}/${sopStore.folderid}`);
      
    } 
    else if (sopStore.toolId == 153 || window.location.pathname.includes('/uksop')) {  
        navigate(`/uksop/${sopStore.sopId}/${sopStore.folderid}`); 
      
    } 
    else if (sopStore.toolId == 156 || window.location.pathname.includes('/germanysop')) {    
        navigate(`/germanysop/${sopStore.sopId}/${sopStore.folderid}`);
      
    } 
    else if (sopStore.toolId == 151 || window.location.pathname.includes('/lor')) {
      // As per Murugan, order of execution should be different for LOR.
      // Not sure why. Changing the flow accordingly
    navigate(`/lor/${sopStore.sopId}/${sopStore.folderid}`);
      
    }
    }

    if(sopStore.toolId !== 151 ){
      await sopStore.generatecontent(
        sopProfileSelectionStore.sopProfileSelection
          ? sopProfileSelectionStore.sopProfileSelection.profile_id
          : ''
      );
    }
    
     if(sopStore.toolId === 151|| window.location.pathname.includes('/lor')){
      editorStore.setLoading(true);
      await sopStore.generatecontent(
        sopProfileSelectionStore.sopProfileSelection
          ? sopProfileSelectionStore.sopProfileSelection.profile_id
          : ''
      );
      editorStore.setLoading(false);
     }

    userStore.credits = sopStore.availableCredits;
    sopStore.set_active_step('');
    sopStore.isFileCreated = false;
  };

  useEffect(() => {
    // Reset the selected fields to null when the component render
    sopStore.selecteduniversitiy = null;
    sopStore.selectedDegree = null;
    sopStore.selectedCourse = null;
    newContentStore.credits = sopStore.availableCredits;
    sopStore.isFileCreated = false;
  }, []);
  const sopBackClickHandler = () => {
    const index = sopStore.stepsArr.indexOf(sopStore.activeStep);
    if (index - 1 >= 0) {
      sopStore.set_active_step(sopStore.stepsArr[index - 1]);
      const desc = sopStore.steps.find((item) => item.id == sopStore.stepsArr[index - 1]);
      if (desc) {
        sopStore.setSopDescription(desc['desc']);
      }
    }
  };
  const path = window.location.pathname;

  const sopNextClickHandler = () => {
    if(sopStore.activeStep === 'Step 1'){
      sopProfileSelectionStore.setClearSopStep(true);
    }
    const index = sopStore.stepsArr.indexOf(sopStore.activeStep);
    if (index + 1 <= 7) {
      sopStore.set_active_step(sopStore.stepsArr[index + 1]);
      const desc = sopStore.steps.find((item) => item.id == sopStore.stepsArr[index + 1]);
      if (desc) {
        sopStore.setSopDescription(desc['desc']);
      }
    }
  };

  const saveandnextclickhandler = async () => {
    const stepName = sopStore.activeStep;
    sopStore.markCompleted(stepName);
    if(sopStore.activeStep === 'Step 1'){
      sopProfileSelectionStore.setClearSopStep(true);
    }

    if (
      (!sopStore.isFileCreated &&
        !path.includes('/sop/') &&
        newContentStore.libraryClick === false && sopProfileSelectionStore.userSelectionClick === false)
    ) {
      await sopStore.save_sop_file(
        sopProfileSelectionStore.sopProfileSelection?.folder_id,
        sopProfileSelectionStore.current_sop_name,
        newContentStore.activeFolder
      );
      sopStore.isFileCreated = true;
    } else {
      if (
        newContentStore.libraryClick === false && sopProfileSelectionStore.userSelectionClick === false
      ) {
        await sopStore.update_sop_workspace(sopStore.folderid, sopStore.sopId);
      }
      if (
        newContentStore.libraryClick === true
      ) {
        await sopStore.update_sop_workspace(sopStore.parentId, sopStore.documentId);
      }
      if (sopProfileSelectionStore.userSelectionClick === true && sopProfileSelectionStore.clearSopStep === true) {
        await sopStore.update_sop_workspace(sopProfileSelectionStore.sopSelectionValues[0].parentid, sopStore.documentId);
      }
    }
    const index = sopStore.stepsArr.indexOf(sopStore.activeStep);
    if (index + 1 <= 7) {
      sopStore.set_active_step(sopStore.stepsArr[index + 1]);
      const desc = sopStore.steps.find((item) => item.id == sopStore.activeStep);
      if (desc) {
        sopStore.setSopDescription(desc['desc']);
      }
    }
  };

  const disableGenerateOP = () => {
    const { sopjsonData, isLor } = sopStore;
    const { university, student_name, gender, course, duration } = sopjsonData;
    const isGenderEmpty = typeof gender === 'object' && Object.keys(gender).length === 0;
    if (isLor && (!university || !student_name || isGenderEmpty || !course || !duration)) {
      return true;
    }
    if(sopStore.saveAndNextButton){
      return true;
    }
    else return false;
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  return (
    <>
      {!sopStore.final_output_screen && <SopSelectionModal/>}
      {sopStore.activeStep !== '' && !sopStore.final_output_screen ? (
        <div className="flex w-full justify-between">
          <div className="flex ">
            {!sopStore.isLor && (
              <div
                className={`uppercase pl-1  border h-10 w-24 rounded-[4px]  cursor-pointer flex items-center justify-center mt-1 mb-1 ${
                  sopStore.activeStep === 'Step 1'
                    ? 'text-[#8F98A9] bg-#F8F9FA border-#8F98A9 '
                    : 'text-purple-600 border-purple-500 bg-purple-50'
                }`}
                onClick={sopBackClickHandler}
              >
                <i className="fa-light fa-angle-left" style={{ fontSize: '22px' }}/>
                <button className="uppercase pl-1" disabled={sopStore.activeStep === 'Step 1'}>
                  Back
                </button>
              </div>
            )}
            {sopStore.activeStep !== `Step ${steps}` && (
              <div
                className={`uppercase ml-2 pl-1 border h-10 w-24 rounded-[4px] cursor-pointer flex items-center justify-centerrr mb-1 mt-1 ${ (sopStore.activeStep ==='Step 1' ?
                  sopStore.selecteduniversitiy &&
                  sopStore.selectedDegree &&
                  sopStore.selectedCourse &&
                  (sopStore.toolId !== 154 ||
                    (canadaSopStore.sopjsonData.full_name !== '' &&
                      canadaSopStore.sopjsonData.nationality !== '' &&
                      canadaSopStore.sopjsonData.motivation_for_studying_in_canada !== '' &&
                      canadaSopStore.sopjsonData.intend_to_pursue !== '' &&
                      canadaSopStore.sopjsonData.university_to_pursue_this_course !== '' &&
                      canadaSopStore.sopjsonData.purpose_of_writing_this_statement_of_purpose !==
                      '')) || (newContentStore.libraryClick === true || sopProfileSelectionStore.userSelectionClick === true) && sopStore.selecteduniversitiy !== '' &&
                  sopStore.selectedDegree !== '' &&
                  sopStore.selectedCourse !== '' &&
                  (sopStore.toolId !== 154 ||
                    (canadaSopStore.sopjsonData.full_name !== '' &&
                      canadaSopStore.sopjsonData.nationality !== '' &&
                      canadaSopStore.sopjsonData.motivation_for_studying_in_canada !== '' &&
                      canadaSopStore.sopjsonData.intend_to_pursue !== '' &&
                      canadaSopStore.sopjsonData.university_to_pursue_this_course !== '' &&
                      canadaSopStore.sopjsonData.purpose_of_writing_this_statement_of_purpose !==
                      '')):(sopStore.activeStep !=='Step 1' && !sopStore.saveAndNextButton ))
                    ? 'text-purple-600 border-purple-500 bg-purple-50'
                    : 'text-white border-gray-500 bg-gray-500 '
                }`}
                onClick={() => {
                  if (
                  sopStore.selecteduniversitiy &&
                  sopStore.selectedDegree &&
                  sopStore.selectedCourse &&
                  (sopStore.toolId !== 154 ||
                  (canadaSopStore.sopjsonData.full_name !== '' &&
                  canadaSopStore.sopjsonData.nationality !== '' &&
                  canadaSopStore.sopjsonData.motivation_for_studying_in_canada !== '' &&
                  canadaSopStore.sopjsonData.intend_to_pursue !== '' &&
                  canadaSopStore.sopjsonData.university_to_pursue_this_course !== '' &&
                  canadaSopStore.sopjsonData.purpose_of_writing_this_statement_of_purpose !==
                  '')) || (newContentStore.libraryClick === true || sopProfileSelectionStore.userSelectionClick === true) && sopStore.selecteduniversitiy !== '' &&
                  sopStore.selectedDegree !== '' &&
                  sopStore.selectedCourse !== '' &&
                  (sopStore.toolId !== 154 ||
                  (canadaSopStore.sopjsonData.full_name !== '' &&
                  canadaSopStore.sopjsonData.nationality !== '' &&
                  canadaSopStore.sopjsonData.motivation_for_studying_in_canada !== '' &&
                  canadaSopStore.sopjsonData.intend_to_pursue !== '' &&
                  canadaSopStore.sopjsonData.university_to_pursue_this_course !== '' &&
                  canadaSopStore.sopjsonData.purpose_of_writing_this_statement_of_purpose !==
                  ''))
                  ) {
                    if (sopStore.availableCredits >= sopStore.requiredCredits  || (sopStore.activeStep !=='Step 1' && sopStore.saveAndNextButton)) {
                      sopNextClickHandler();
                    } else {
                      toast.error('Sorry, You don\'t have enough credits');
                    }
                  }
                }}
              >
                <button className="uppercase pl-1">Next</button>
                <i className="fa-light fa-angle-right ml-2" style={{ fontSize: '22px' }}/>
              </div>
            )}
          </div>
          <div className="flex items-center border border-[#FFBB28] bg-[#FFF6D3] p-3 rounded-md h-10 mb-1 mt-1">
            <img src={coinsIcon} alt="coins.svg"/>
            <p className="pr-3 pl-1 text-[10px] text-[#4E5664]">Remaining Credits</p>{' '}
            <span className="text-[#292D35] text-[14px] font-bold">
              {sopStore.availableCredits}
            </span>
          </div>
          {sopStore.activeStep !== `Step ${steps}` && (
            <div
              className="text-white flex items-center justify-center mt-1 mb-1"
              onClick={() => {
                if (
                sopStore.selecteduniversitiy &&
                sopStore.selectedDegree &&
                sopStore.selectedCourse &&
                (sopStore.toolId !== 154 ||
                (canadaSopStore.sopjsonData.full_name !== '' &&
                canadaSopStore.sopjsonData.nationality !== '' &&
                canadaSopStore.sopjsonData.motivation_for_studying_in_canada !== '' &&
                canadaSopStore.sopjsonData.intend_to_pursue !== '' &&
                canadaSopStore.sopjsonData.university_to_pursue_this_course !== '' &&
                canadaSopStore.sopjsonData.purpose_of_writing_this_statement_of_purpose !==
                '')) || (newContentStore.libraryClick === true || sopProfileSelectionStore.userSelectionClick === true) && sopStore.selecteduniversitiy !== '' &&
                sopStore.selectedDegree !== '' &&
                sopStore.selectedCourse !== '' &&
                (sopStore.toolId !== 154 ||
                (canadaSopStore.sopjsonData.full_name !== '' &&
                canadaSopStore.sopjsonData.nationality !== '' &&
                canadaSopStore.sopjsonData.motivation_for_studying_in_canada !== '' &&
                canadaSopStore.sopjsonData.intend_to_pursue !== '' &&
                canadaSopStore.sopjsonData.university_to_pursue_this_course !== '' &&
                canadaSopStore.sopjsonData.purpose_of_writing_this_statement_of_purpose !==
                ''))
                ) {
                  if (
                    sopStore.availableCredits >= sopStore.requiredCredits || (sopStore.activeStep !=='Step 1' && sopStore.saveAndNextButton)) {
                    saveandnextclickhandler();
                  } else {
                    toast.error('Sorry, You don\'t have enough credits');
                  }
                }
              }}
            >
              <button
                className={`border-2 h-10 w-48 rounded-md ${
                  (sopStore.activeStep ==='Step 1' ?  sopStore.selecteduniversitiy &&
                    sopStore.selectedDegree &&
                    sopStore.selectedCourse &&
                    (sopStore.toolId !== 154 ||
                      (canadaSopStore.sopjsonData.full_name !== '' &&
                        canadaSopStore.sopjsonData.nationality !== '' &&
                        canadaSopStore.sopjsonData.motivation_for_studying_in_canada !== '' &&
                        canadaSopStore.sopjsonData.intend_to_pursue !== '' &&
                        canadaSopStore.sopjsonData.university_to_pursue_this_course !== '' &&
                        canadaSopStore.sopjsonData.purpose_of_writing_this_statement_of_purpose !==
                        '')) || (newContentStore.libraryClick === true || sopProfileSelectionStore.userSelectionClick === true) && sopStore.selecteduniversitiy !== '' &&
                    sopStore.selectedDegree !== '' &&
                    sopStore.selectedCourse !== '' &&
                    (sopStore.toolId !== 154 ||
                      (canadaSopStore.sopjsonData.full_name !== '' &&
                        canadaSopStore.sopjsonData.nationality !== '' &&
                        canadaSopStore.sopjsonData.motivation_for_studying_in_canada !== '' &&
                        canadaSopStore.sopjsonData.intend_to_pursue !== '' &&
                        canadaSopStore.sopjsonData.university_to_pursue_this_course !== '' &&
                        canadaSopStore.sopjsonData.purpose_of_writing_this_statement_of_purpose !==
                        ''))  : sopStore.activeStep !=='Step 1' && !sopStore.saveAndNextButton )
                    ? 'text-white border-purple-500 bg-purple-500'
                    : 'border-gray-500 bg-gray-500'
                } uppercase pl-1`}
                disabled={
                  sopStore.selecteduniversitiy &&
                  sopStore.selectedDegree &&
                  sopStore.selectedCourse &&
                  sopStore.loadingbutton || (sopStore.activeStep !=='Step 1' && sopStore.saveAndNextButton)
                }
              >
                {sopStore.activeStep !=='Step 1' && !sopStore.saveAndNextButton ? 'Save & Next': sopStore.activeStep ==='Step 1' ? 'Save & Next':'Loading'}
              </button>

              <i className="fa-light fa-angle-right ml-[-2.5rem]" style={{ fontSize: '22px' }}/>
            </div>
          )}
          {sopStore.activeStep === `Step ${steps}` && (
            <button
              disabled={disableGenerateOP()}
              className={`border-2 h-12 w-54 rounded-md text-white px-2 ${
                (sopStore.toolId !== 154 && !sopStore?.isLor) ||
                canadaSopStore.sopjsonData
                  .explain_the_factors_that_make_canada_you_preferred_destination_for_studying !==
                '' ||
                (sopStore?.isLor && !disableGenerateOP())
                  ? ' border-purple-500 bg-purple-500'
                  : ' border-gray-500 bg-gray-500'
              } flex items-center justify-center`}
              onClick={() => {
                if(sopStore?.isLor&&!disableGenerateOP){
                  if (sopStore.availableCredits >= sopStore.requiredCredits) {
                    sopStore.setIsFromCreateSop(true);
                    sopStore.setFinalOutputScreen(true);
                    const timeout = setTimeout(() => {
                      toast.error(
                        'Please wait,The output is taking more time than usual for generating.'
                      );
                    }, 90 * 1000);
                    generatecontent().then(() => {
                      clearTimeout(timeout); // Clear the timeout since generatecontent completed
                    });
                  } else {
                    toast.error('Sorry, You don\'t have enough credits');
                  }
                }
                else {
                if (
                  sopStore.toolId !== 154 ||
                  canadaSopStore.sopjsonData
                    .explain_the_factors_that_make_canada_you_preferred_destination_for_studying !==
                  ''
                ) {
                  if (sopStore.availableCredits >= sopStore.requiredCredits) {
                    sopStore.setToolsClick(false);
                    sopStore.setIsFromCreateSop(true);
                    sopStore.setFinalOutputScreen(true);
                    const timeout = setTimeout(() => {
                      toast.error(
                        'Please wait,The output is taking more time than usual for generating.'
                      );
                    }, 90 * 1000);
                    generatecontent().then(() => {
                      clearTimeout(timeout); // Clear the timeout since generatecontent completed
                    });
                  } else {
                    toast.error('Sorry, You don\'t have enough credits');
                  }
                }

              }}}
            >
              Generate Output
            </button>
          )}
        </div>
      ) : (
        sopStore.final_output_screen && (
          <div className="flex justify-between items-center">
            <div
              className={` ${!sopStore.generatedOutput} border font-bold h-[40px] w-54 rounded-md ${
                sopStore.plagiarismRequestLoading
                  ? 'cursor-not-allowed bg-[#737E93]  text-white'
                  : ' text-[#A033FF] border-purple-500 bg-[#FCF3FF] cursor-pointer'
              } flex items-center justify-center`}
              onClick={() => {
                if (!sopStore.plagiarismRequestLoading) {
                  // Show the confirmation dialog when the button is clicked
                  openDialog();
                }
              }}
            >
              <button className="uppercase px-4 py-5">Regenerate Output</button>
            </div>
            <Transition.Root show={dialogOpen} as={React.Fragment}>
              <Dialog
                as="div"
                className="fixed inset-0 z-50 overflow-y-auto"
                onClose={() => {
                  // closeDialog();
                }}
              >
                <div className="flex items-center justify-center min-h-screen p-6 ">
                  <Dialog.Overlay className="fixed inset-0 bg-black opacity-30"/>
                  <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <div className="relative bg-white rounded-lg shadow-lg max-w-md w-full mr-[-14rem]">
                      <div className="p-4">
                        <h3 className="text-[30px] font-semibold mb-4">Confirmation</h3>
                        <p className="text-[25px]">
                          Are you sure you want to regenerate the output?
                        </p>
                        <div className="mt-4 flex justify-end">
                          <button
                            onClick={() => {
                              closeDialog();
                              sopStore.isFileCreated = true;
                              generatecontent();
                              sopStore.resetPlagiarsim();
                              sopStore.deletePlagarism(sopStore.sopId);
                            }}
                            className="px-4 py-2 bg-[#A033FF] text-white rounded hover:bg-purple-700 text-[25px]"
                          >
                            Yes
                          </button>
                          <button
                            onClick={closeDialog}
                            className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 ml-2 text-[25px]"
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>

            <div
              className={`${
                !sopStore.generatedOutput && 'mx-auto'
              } flex items-center border border-[#FFBB28] bg-[#FFF6D3] p-3 rounded-md`}
            >
              <img src={coinsIcon} alt="coins.svg"/>
              <p className="pr-3 pl-1 text-[10px] text-[#4E5664]">Remaining Credits</p>{' '}
              <span className="text-[#292D35] text-[14px] font-bold">
                {sopStore.availableCredits}
              </span>
            </div>

            <div className={`${!sopStore.generatedOutput} flex items-center`}>
              {!sopStore.final_output_screen && (
                <i
                  onClick={() => {
                    navigator.clipboard.writeText(sopStore.generatedOutput);
                    toast.success('Copied');
                  }}
                  className="fa-regular fa-copy text-[#4E5664] text-[25px] mx-8 cursor-pointer"
                />
              )}

            </div>
          </div>
        )
      )}
    </>
  );
});
export default SopFooter;
