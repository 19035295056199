import { observer } from 'mobx-react-lite';
import { DotLoading } from '../../../../../components/DotLoading';
import PlagiarsimCard from './plagiarsim-card';
import { useStore } from '../../../../../hooks/use-store';
import { safe_get } from '../../../../utils';
import { useEffect, useState } from 'react';

interface payload {
  user_id: number;
  file_id: number;
}

const Plagiarism = observer((props: any) => {
  const { editorStore, sopStore, uKSopStore, canadaSopStore } = useStore();
  // const { sopStore } = props;
  const [isCorrectSop, setIsCorectSop] = useState(false);

  useEffect(() => {
    if (
      window.location.pathname.includes('/sop') ||
      window.location.pathname.includes('/australiasop')
    ) {
      let plargiarismDeveloperLoad: payload =
        sopStore.plagiarism_result?.plagiarism_result?.developerPayload;
      if (plargiarismDeveloperLoad?.file_id.toString() === sopStore.sopId.toString()) {
        setIsCorectSop(true);
      } else {
        setIsCorectSop(false);
      }
    } else if (window.location.pathname.includes('/uksop')) {
      let plargiarismDeveloperLoad: payload =
        uKSopStore.plagiarism_result?.plagiarism_result?.developerPayload;
      if (plargiarismDeveloperLoad?.file_id.toString() === uKSopStore.sopId.toString()) {
        setIsCorectSop(true);
      } else {
        setIsCorectSop(false);
      }
    } else if (window.location.pathname.includes('/canadasop')) {
      let plargiarismDeveloperLoad: payload =
        canadaSopStore.plagiarism_result?.plagiarism_result?.developerPayload;
      if (plargiarismDeveloperLoad?.file_id.toString() === canadaSopStore.sopId.toString()) {
        setIsCorectSop(true);
      } else {
        setIsCorectSop(false);
      }
    } else if (
      window.location.pathname.includes('/lor') ||
      window.location.pathname.includes('/germanysop')
    ) {
      let plargiarismDeveloperLoad: payload =
        editorStore.plagiarism_result?.plagiarism_result?.developerPayload
      if (plargiarismDeveloperLoad?.file_id.toString() === editorStore.documentId.toString()) {
        setIsCorectSop(true);
      } else {
        setIsCorectSop(false);
      }
    }
    console.log(sopStore.plagiarism_result);
  }, [
    sopStore.plagiarism_result,
    editorStore.plagiarism_result,
    uKSopStore.plagiarism_result,
    canadaSopStore.plagiarism_result,
  ]);

  const checkPlagiarism = async () => {
    console.log(sopStore.generatedOutput, 'GENERATEDOUTPUT');
    editorStore.stepper_update_originality();
    sopStore.resetPlagiarsim();
    if (
      window.location.pathname.includes('/sop') ||
      window.location.pathname.includes('/australiasop')
    ) {
      await sopStore.sendPlagiarismRequest();
    } else if (window.location.pathname.includes('/uksop')) {
      await uKSopStore.sendPlagiarismRequest();
    } else if (window.location.pathname.includes('/canadasop')) {
      await canadaSopStore.sendPlagiarismRequest();
    } else if (
      window.location.pathname.includes('/lor') ||
      window.location.pathname.includes('/germanysop')
    ) {
      await editorStore.sendPlagiarismRequest();
    }
  };
  return window.location.pathname.includes('/sop') ||
    window.location.pathname.includes('/australiasop') ? (
    <>
      {' '}
      {sopStore.plagiarismRequestLoading ? <DotLoading /> : ''}
      <div
        className="uppercase border mx-5 py-2 mt-[3rem] rounded-md cursor-pointer flex shadow-md items-center justify-center text-purple-600 border-purple-500 bg-purple-50"
        onClick={() => {
          checkPlagiarism();
        }}
      >
        <button className="uppercase">CHECK PLAGIARISM OF OUTPUT</button>
      </div>
      <div>
        {safe_get(
          sopStore.plagiarism_result?.plagiarism_result?.results?.score,
          'aggregatedScore',
          999
        ) !== 999 &&
        !sopStore.plagiarismRequestLoading &&
        isCorrectSop ? (
          <>
            <PlagiarsimCard
              content={'Plagiarsim'}
              percentage={safe_get(
                sopStore.plagiarism_result.plagiarism_result.results.score,
                'aggregatedScore',
                999
              )}
              bgColor={'#FFE4DA'}
              borderColor={'#FF7678'}
            />
            <PlagiarsimCard
              content={'Unqiue'}
              percentage={
                100 -
                safe_get(
                  sopStore.plagiarism_result.plagiarism_result.results.score,
                  'aggregatedScore',
                  999
                )
              }
              bgColor={'#EFFBD2'}
              borderColor={'#59A51A'}
            />
          </>
        ) : (
          ''
        )}
      </div>
      {sopStore.plagiarismErrorOccured ? (
        <p className="self-center text-[14px] text-center font-bold text-red-500 mx-5 py-2 mt-[3rem]">
          {' '}
          Error Occurred, Please try again
        </p>
      ) : (
        ''
      )}
    </>
  ) : window.location.pathname.includes('/uksop') ? (
    <>
      {' '}
      {uKSopStore.plagiarismRequestLoading ? <DotLoading /> : ''}
      <div
        className="uppercase border mx-5 py-2 mt-[3rem] rounded-md cursor-pointer flex shadow-md items-center justify-center text-purple-600 border-purple-500 bg-purple-50"
        onClick={() => {
          checkPlagiarism();
        }}
      >
        <button className="uppercase">CHECK PLAGIARISM OF OUTPUT</button>
      </div>
      <div>
        {safe_get(
          uKSopStore.plagiarism_result?.plagiarism_result?.results?.score,
          'aggregatedScore',
          999
        ) !== 999 &&
        !uKSopStore.plagiarismRequestLoading &&
        isCorrectSop ? (
          <>
            <PlagiarsimCard
              content={'Plagiarsim'}
              percentage={safe_get(
                uKSopStore.plagiarism_result.plagiarism_result.results.score,
                'aggregatedScore',
                999
              )}
              bgColor={'#FFE4DA'}
              borderColor={'#FF7678'}
            />
            <PlagiarsimCard
              content={'Unqiue'}
              percentage={
                100 -
                safe_get(
                  uKSopStore.plagiarism_result.plagiarism_result.results.score,
                  'aggregatedScore',
                  999
                )
              }
              bgColor={'#EFFBD2'}
              borderColor={'#59A51A'}
            />
          </>
        ) : (
          ''
        )}
      </div>
      {uKSopStore.plagiarismErrorOccured ? (
        <p className="self-center text-[14px] text-center font-bold text-red-500 mx-5 py-2 mt-[3rem]">
          {' '}
          Error Occurred, Please try again
        </p>
      ) : (
        ''
      )}
    </>
  ) : window.location.pathname.includes('/canadasop') ? (
    <>
      {' '}
      {canadaSopStore.plagiarismRequestLoading ? <DotLoading /> : ''}
      <div
        className="uppercase border mx-5 py-2 mt-[3rem] rounded-md cursor-pointer flex shadow-md items-center justify-center text-purple-600 border-purple-500 bg-purple-50"
        onClick={() => {
          checkPlagiarism();
        }}
      >
        <button className="uppercase">CHECK PLAGIARISM OF OUTPUT</button>
      </div>
      <div>
        {safe_get(
          canadaSopStore.plagiarism_result?.plagiarism_result?.results?.score,
          'aggregatedScore',
          999
        ) !== 999 &&
        !canadaSopStore.plagiarismRequestLoading &&
        isCorrectSop ? (
          <>
            <PlagiarsimCard
              content={'Plagiarsim'}
              percentage={safe_get(
                canadaSopStore.plagiarism_result.plagiarism_result.results.score,
                'aggregatedScore',
                999
              )}
              bgColor={'#FFE4DA'}
              borderColor={'#FF7678'}
            />
            <PlagiarsimCard
              content={'Unqiue'}
              percentage={
                100 -
                safe_get(
                  canadaSopStore.plagiarism_result.plagiarism_result.results.score,
                  'aggregatedScore',
                  999
                )
              }
              bgColor={'#EFFBD2'}
              borderColor={'#59A51A'}
            />
          </>
        ) : (
          ''
        )}
      </div>
      {canadaSopStore.plagiarismErrorOccured ? (
        <p className="self-center text-[14px] text-center font-bold text-red-500 mx-5 py-2 mt-[3rem]">
          {' '}
          Error Occurred, Please try again
        </p>
      ) : (
        ''
      )}
    </>
  ) : window.location.pathname.includes('/lor') ||
    window.location.pathname.includes('/germanysop') ? (
    <>
      {' '}
      {editorStore.plagiarismRequestLoading ? <DotLoading /> : ''}
      <div
        className="uppercase border mx-5 py-2 mt-[3rem] rounded-md cursor-pointer flex shadow-md items-center justify-center text-purple-600 border-purple-500 bg-purple-50"
        onClick={() => {
          checkPlagiarism();
        }}
      >
        <button className="uppercase">CHECK PLAGIARISM OF OUTPUT</button>
      </div>
      <div>
        {safe_get(
          editorStore.plagiarism_result.plagiarism_result.results.score,
          'aggregatedScore',
          999
        ) !== 999 &&
        !editorStore.plagiarismRequestLoading &&
        isCorrectSop ? (
          <>
            <PlagiarsimCard
              content={'Plagiarsim'}
              percentage={safe_get(
                editorStore.plagiarism_result.plagiarism_result.results.score,
                'aggregatedScore',
                999
              )}
              bgColor={'#FFE4DA'}
              borderColor={'#FF7678'}
            />
            <PlagiarsimCard
              content={'Unqiue'}
              percentage={
                100 -
                safe_get(
                  editorStore.plagiarism_result.plagiarism_result.results.score,
                  'aggregatedScore',
                  999
                )
              }
              bgColor={'#EFFBD2'}
              borderColor={'#59A51A'}
            />
          </>
        ) : (
          ''
        )}
      </div>
      {editorStore.plagiarismErrorOccured ? (
        <p className="self-center text-[14px] text-center font-bold text-red-500 mx-5 py-2 mt-[3rem]">
          {' '}
          Error Occurred, Please try again
        </p>
      ) : (
        ''
      )}
    </>
  ) : (
    <>
      {' '}
      {editorStore.plagiarismRequestLoading ? <DotLoading /> : ''}
      <div
        className="uppercase border mx-5 py-2 mt-[3rem] rounded-md cursor-pointer flex shadow-md items-center justify-center text-purple-600 border-purple-500 bg-purple-50"
        onClick={() => {
          checkPlagiarism();
        }}
      >
        <button className="uppercase">CHECK PLAGIARISM OF OUTPUT</button>
      </div>
      <div>
        {safe_get(
          editorStore.plagiarism_result.plagiarism_result.results.score,
          'aggregatedScore',
          999
        ) !== 999 &&
        !editorStore.plagiarismRequestLoading &&
        isCorrectSop ? (
          <>
            <PlagiarsimCard
              content={'Plagiarsim'}
              percentage={safe_get(
                editorStore.plagiarism_result.plagiarism_result.results.score,
                'aggregatedScore',
                999
              )}
              bgColor={'#FFE4DA'}
              borderColor={'#FF7678'}
            />
            <PlagiarsimCard
              content={'Unqiue'}
              percentage={
                100 -
                safe_get(
                  editorStore.plagiarism_result.plagiarism_result.results.score,
                  'aggregatedScore',
                  999
                )
              }
              bgColor={'#EFFBD2'}
              borderColor={'#59A51A'}
            />
          </>
        ) : (
          ''
        )}
      </div>
      {editorStore.plagiarismErrorOccured ? (
        <p className="self-center text-[14px] text-center font-bold text-red-500 mx-5 py-2 mt-[3rem]">
          {' '}
          Error Occurred, Please try again
        </p>
      ) : (
        ''
      )}
    </>
  );
});
export default Plagiarism;
