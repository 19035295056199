import {
  AutoLinkPlugin,
  createLinkMatcherWithRegExp
} from '@lexical/react/LexicalAutoLinkPlugin';
import * as React from 'react';

const URL_REGEX =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

  const EMAIL_REGEX = /(?:<[^<>[\]\\.,;:\s@"]+(?:\.[^<>[\]\\.,;:\s@"]+)*>|"[^"]+")@(?:\[(?:[0-9]{1,2}\.){3}[0-9]{1,2}\]|(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})/;
const MATCHERS = [
  createLinkMatcherWithRegExp(URL_REGEX, (text) => {
    return text.startsWith('http') ? text : `https://${text}`;
  }),
  createLinkMatcherWithRegExp(EMAIL_REGEX, (text) => {
    return `mailto:${text}`;
  })
];

export default function LexicalAutoLinkPlugin(): JSX.Element {
  return <AutoLinkPlugin matchers={MATCHERS}/>;
}
