import React from "react";
import { DotLoader } from "react-spinners";


export const DotLoading = () => {
  return (

    <div className=" w-full h-screen fixed top-0 right-0 overflow-hidden max-w-md   bg-white opacity-75 " style={{zIndex: 99999}}>
      <span className="text-[#A033FF] opacity-75 top-1/2] right-8 my-0 mx-auto block relative w-0 h-0"
            style={{top: '48%'}}>
        <DotLoader
          color={"#A033FF"}
          loading={true}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </span>
    </div>
  )
}
