import { useState, useEffect } from 'react';
import Select from 'react-select';
import { observer } from 'mobx-react-lite';

const ListSelector = observer((props: any) => {
  const { sopStore,setSelectedOption,selectedOption } = props;
  const [options, setOptions] = useState<{ label: string; value: string }[]>([]);

  const degreeOptions = [
    { label: 'Associates Degree', value: 'AssociatesDegree' },
    { label: 'Bachelors Degree', value: 'BachelorsDegree' },
    { label: 'Doctors Degree', value: 'DoctorsDegree' },
    { label: 'Masters Degree', value: 'MastersDegree' },
  ];

  const genderOptions = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: 'Not want to disclose', value: 'not want to disclose' },
  ];

  const textareaHandler = (obj) => {
    if(!sopStore.final_output_screen){
      sopStore.updateSopJsonData(props.slot, obj);
      sopStore.updateSelectedDegree(obj);
    }
    if(props.lor && !sopStore.final_output_screen){
      sopStore.updateSopJsonData(props.slot, obj);
    }
    setSelectedOption(obj); 
  };

  useEffect(() => {
    if (props.slot === 'degree' && !sopStore.isLor) {
      setOptions(degreeOptions);
      setSelectedOption(props.val)
    } else if(props.slot === 'gender' && sopStore.finalOutputScreen){
      setOptions(genderOptions);
      setSelectedOption({value:props.val,label:props.val})
    }else {
      setOptions(genderOptions);
      setSelectedOption(props.val)
    }
  }, []);

  const customStyles = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#8F98A9',
        fontSize: '14px',
      };
    },
    control: (provided, state) => ({
      ...provided,
      borderColor: '#C7CBD4',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#C7CBD4',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '' : 'white',
      fontWeight: state.isSelected ? 'normal' : 'inherit',
      color: state.isSelected ? 'normal' : 'black',
      '&:hover': {
        backgroundColor: '#FCF3FF',
        color: '#3C424C',
        cursor: 'pointer',
        //fontWeight: 'bold',
      },
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: '#1F2937', fontSize: '14px' }),
  };

  return (
    <div className="py-4 flex-1">
      <div className="flex items-center">
        <label className="text-[#4E5664] font-bold">{props.label}</label>  {props.required && <span className='text-red-500 text-sm'>*</span>}
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="#737E93"
          className="w-[15px] h-[15px] ml-[13px]"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
          />
        </svg> */}
      </div>
      <Select
        className="mt-[0.6rem]"
        value={selectedOption && selectedOption.value ? selectedOption : null}
        styles={customStyles}
        options={options}
        onChange={textareaHandler}
        // isClearable={true}
        placeholder={props.placeholder}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
      />
    </div>
  );
});

export default ListSelector;
