import React from 'react';

const PlagiarsimCard = ({ content, percentage, bgColor, borderColor }) => {

  // const displayPecentage = (percentageParameter) => {
  //   let number = parseFloat(percentageParameter);
  //   let percentage = number * 100;

  //   // Check if there are any decimal places
  //   if (Number.isInteger(percentage)) {
  //     return percentage.toFixed(0); // Convert to integer if no decimal places
  //   } else {
  //     return percentage.toFixed(2); // Round to 2 decimal places if there are decimal places
  //   }
  // };
  return (
    <div className={`bg-[${bgColor}] border border-[${borderColor}] rounded-lg shadow-lg p-6 flex flex-col justify-center items-center my-7 mx-5`}>
      <p className="text-xl mb-2">{content}</p>
      <p className={`text-[${borderColor}] text-2xl font-bold `}>{`${Number.isInteger(percentage)? parseInt(percentage) :parseFloat(percentage).toFixed(2)}%`}</p>
    </div>
  );
};

export default PlagiarsimCard;