import { observer } from 'mobx-react-lite';
import { Loading } from '../../components/Loading';
import { Dialog } from '@headlessui/react';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useStore } from '../../hooks/use-store';
const RenameNewDocument = observer((props: any) => {
  const { documentStore, newContentStore,sopStore,sopProfileSelectionStore } = useStore();
  const [loading, setLoading] = useState(false);
  const [newName, setNewName] = useState(props.activeDoc['name'] );
  const renameHandler = async () => {
    setLoading(true);
    if(newContentStore.isFromTopBar){
      sopStore.breadCrumbs[sopStore.breadCrumbs.length-1].name=newName;
      sopProfileSelectionStore.set_current_sop_name(newName);
      sopStore.updateSopName(newName);
      props.setShowRenameDocModal(false);
      toast.success('Rename successful');
      newContentStore.setIsFromTopBar(false);
    }
    else{
    let resp;
    
    if (props.activeDoc['isfile']) {
      resp = await documentStore.rename_file(props.activeDoc['id'], newName);
    } 
    else  {
      resp = await documentStore.rename_folder( props.activeDoc['id'], newName);
    }
    if (resp.status === 200) {
    props.setShowRenameDocModal(false);
      toast.success('Rename successful');
      await newContentStore.get_all_contents();
      await sopStore.get_sop_workspace(sopStore.sopId,newContentStore.activeFolder)
    } 
    else {
      
      toast.error(resp.data);
    }
    }
    
    setLoading(false);
  };

  const cancelRename = () => {
    props.setShowRenameDocModal(false);
  };

  const onEnter = (event) => {
    if (event.keyCode === 13) {
      renameHandler();
    }
  };

  return (
    <>
      {loading && <Loading />}
      <>
        <div>
            <Dialog.Title className="mb-4 text-[24px]  font-medium leading-6 text-[#4E5664] px-4  ">
            {props.activeDoc['isfile'] ? 'Rename File' : 'Rename Folder'}
          </Dialog.Title>
          <div
                    className= 'fixed right-[25px] top-[16px] text-[24px] text-[#4E5664] bg-[#F8F9FA] cursor-pointer'
                    onClick={() => {
                      cancelRename();
                  
                    }}
                  >
                    <i className="fa-solid fa-xmark" />
                  </div>
          <div className="border border-[#E3E5E9]"></div>
          <div className='text-[#4E5664] text-[16px] font-Avenir font-medium mt-[1.0rem] px-4  '>Name</div>
          <div className="relative mt-2 rounded-md shadow-sm px-4">
            <input
              type="text"
              name="docname"
              id="docname"
              className="block mt-2 bg-white w-full p-4 border rounded-md border-gray-300 pl-4 focus:border-transparent focus:ring-indigo-500 sm:text-sm text-black"
              placeholder="New Name"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              onKeyUp={onEnter}
            />
          </div>
        </div>
      </>
      <div className="border border-[#E3E5E9] mt-[1.5rem]"></div>
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 ml-[14.5rem] mr-4">
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-[#F8F9FA] px-4 py-2 text-base font-medium text-[#8F98A9] shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm uppercase"
          onClick={cancelRename}
        >
          Cancel
        </button>
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md border border-transparent bg-[#A033FF] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm uppercase" 
          onClick={renameHandler}
        >
       {props.activeDoc['isfile'] ? 'Update' : 'Save'}

        </button>
        </div>
    </>
  );
});
export default RenameNewDocument;
