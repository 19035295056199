export class OpenApiStatus {
    async fetchOpenAIStatus(): Promise<any> {
      try {
        const response = await fetch('https://status.openai.com/api/v2/status.json');
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const data = await response.json();
        if (data.status.indicator !== 'none') {
            localStorage.setItem('openApiStatus', 'true');
          } else {
            localStorage.setItem('openApiStatus', 'false');
          }
      } catch (error) {
        console.error('An error occurred:', error);
        throw error; // Rethrow the error
      }
    }
  }