import { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../hooks/use-store';

const Autocomplete = observer((props: any) => {
  const { sopStore, setSuggestion, setValue, value } = props;
  const { userProfileStore , sopProfileSelectionStore , userStore , lorStore } = useStore();
  const [show, setShow] = useState(false);
  const [options, setOptions] = useState<{ label: string; value: string }[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<{ label: string; value: string }[]>([]);
  // const [suggestion, setSuggestion] = useState({});
  // const [value, setValue] = useState(props.value);
  const containerRef = useRef<HTMLDivElement>(null);

  // To Fetch universities
  const fetchUniversities = async () => {
    const resp = await sopStore.get_university_details();
        if (resp.status === 200) {
      sopStore.setUniversityDataLor(resp.data);
      setOptions(resp.data);
    }
     else {
      setOptions([]);
    }
  };

const universitiesData= ()=>{
  setOptions(sopStore.universityDataLor);
}


  const fetchStudentNames = async () => {
    await userProfileStore.get_user_profile_name_id_list();
    if (userProfileStore.userprofile?.length > 0) {
      const updatedData = userProfileStore.userprofile.map((item: any) => ({
        label: item.user_name,
        value: item.profile_id,
      }));
      setOptions(sopProfileSelectionStore.sopProfileSelectionLorDropdownValues = updatedData);
    } else {
      setOptions([]);
    }
  };

  // To Fetch Courses
  const fetchCourses = async (payload) => {
    const resp = await sopStore.get_course_details(payload);
    if (resp.status === 200) {
      let degree = findDegereValue();
      if(sopStore.isLor){
        const response = resp.data.courses;
        const courses = Object.values(response).reduce((acc: string[], curr: unknown) => {
          if (Array.isArray(curr)) {
            return acc.concat(curr);
          } else {
            return acc;
          }
        }, []);
  
        delete response.DoctorsDegree;
        delete response.MastersDegree;
        delete response.BachelorsDegree;
        delete response.AssociatesDegree;
        response.courses = courses;
        if (response?.courses && props?.lor) {
          let courses = response?.courses?.map((course, index) => {
            return { value: course + index, label: course };
          });
          setOptions(courses);
        }
      }
      else if (resp.data && resp.data.courses && degree && !props.lor) {
        let courses = resp?.data?.courses[degree]?.map((course, index) => {
          return { value: course + index, label: course };
        });
        setOptions(courses);
      }
    }
  
    else {
      setOptions([]);
    }  
  };
  const fetchCoursesUK = async () => {
    const resp = await sopStore.get_course_detailsALL();
    if (resp.status === 200) {
      if (resp.data && resp.data.length > 0 ) {
        let courses = resp.data.map((course, index) => {
          return { value: course.name + index, label: course.name };
        });
        setOptions(courses);
      }
    } else {
      setOptions([]);
    }
  }
  

  //Find Degree stored

  const findDegereValue = () => {
    return sopStore.selectedDegree ? sopStore.selectedDegree['value'] : null;
  };

  //Find  university stored

  const findKidentifier = () => {
    return sopStore.selecteduniversitiy ? sopStore.selecteduniversitiy['value'] : null;
  };

  //set initial options
  useEffect(() => {
    setValue(props.val);
    if (props.slot === 'university') {
      fetchUniversities();
    }
    if(window.location.pathname.includes('/sop')||window.location.pathname.includes('/lor') ){
    if (props.slot === 'course') {
      const kid = findKidentifier();

      if (kid) {
        fetchCourses({ kid: kid });
      }
    }
  }
  else{
    if (props.slot === 'course') {
    fetchCoursesUK();
    }
  }
    setFilteredOptions(options);
  }, []);

  useEffect(() => {
    if (props.slot === 'student_name') {
      fetchStudentNames();
    }
    setFilteredOptions(options);
  }, [userProfileStore,sopProfileSelectionStore]);

  //update courses auto complete based on state change

  useEffect(() => {
    if(window.location.pathname.includes('/sop')||window.location.pathname.includes('/lor') ){
    if (props.slot === 'course') {
      const kid = findKidentifier();
      let degree = findDegereValue();
      if (kid && degree || kid && props.lor) {
        fetchCourses({ kid: kid });
      } else {
        setOptions([]);
      }
    }
    else{
      if (props.slot === 'course') {
      fetchCoursesUK();
      }
      else{
        setOptions([]);
      }
    }
      setFilteredOptions(options);
    }
  }, [sopStore.selecteduniversitiy, sopStore.selectedDegree]);

  // update states according to suggestion selected

  const setSuggestionsHandler = (sug) => {
    setSuggestion(sug);
    setValue(sug.label);
    if (!sopStore.final_output_screen) {
      if (props.slot === 'university') {
        sopStore.updateSelectedUniversity(sug);
      }
      if (props.slot === 'course') {
        sopStore.updateSelectedCourse(sug);
      }
      if(props.lor){
        sopStore.updateSopJsonData(props.slot, sug.label);
      }else {
        sopStore.updateSopJsonData(props.slot, sug);
      }
    }
    setShow(false);
  };

  // update change values

  const onChangeHandler = (onchangeValue: any) => {
    let changeValue = onchangeValue.target.value;
    setValue(changeValue);
    setSuggestion({});
    if (!sopStore.final_output_screen) {
      if (props.slot === 'university') {
        sopStore.updateSelectedUniversity(changeValue);
      }
      if (props.slot === 'course') {
        sopStore.updateSelectedCourse(changeValue);
      }
      if (props.slot === 'student_name') {
        sopStore.updateSelectedCourse(changeValue);
      }
      if(props.lor){
        sopStore.updateSopJsonData(props.slot, changeValue);
      }else {
        sopStore.updateSopJsonData(props.slot, { label: changeValue, value: changeValue });
      }
      let filteredOptions = options?.filter(
        ({ label }) => label?.toLowerCase()?.indexOf(changeValue.toLowerCase()) > -1
      );
      setFilteredOptions(filteredOptions);
    }
  };

  //hide auto suggestions

  const handleClickOutside = (event: MouseEvent) => {
    const { current: wrapper } = containerRef;
    if (wrapper && !wrapper.contains(event.target as Node)) {
      setShow(false);
    }
    if(props.slot === 'university'){
    universitiesData();
    }
  };

  // on click of input show auto suggestions

  const onClickHandler = () => {
    let filteredOptions = options?.filter(
      ({ label }) => label?.toLowerCase().indexOf(value?.toLowerCase()) > -1
    );
    setFilteredOptions(filteredOptions);
    setShow(true);
  };

  // add event listener and remove event listener for click outside

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const path = window.location.pathname;

//   const hideShow = () => {
//     setShow(false);
//   };

  return (
    <div className="relative py-4 flex-1">
      <div className="flex items-center">
        <label className="text-[#4E5664] font-bold">{props.label}</label>
        {props.required && <span className="text-red-500 text-sm">*</span>}
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="#737E93"
          className="w-[15px] h-[15px] ml-[13px]"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
          />
        </svg> */}
      </div>
      <div ref={containerRef} className="min-h-0 mt-[0.6rem]">
        <input
          className="w-full text-[#1F2937] py-2 px-4 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none  sm:text-sm "
          onClick={() => onClickHandler()}
          placeholder={props.placeholder}
          value={props.slot==='student_name' && userStore.isenterprise &&  path.includes('/lor') ?
          lorStore.sopjsonData.student_name = sopProfileSelectionStore.userName : props.slot==='student_name' && !userStore.isenterprise  &&  path.includes('/lor') ?
            lorStore.sopjsonData.student_name = 
          userProfileStore.userDetails[0]?.user_name :  value}
          onChange={onChangeHandler}
        ></input>

        {props.slot!=='student_name' && show && filteredOptions.length > 0 && (
          <ul
            className={`absolute z-10 mt-1 w-full rounded-md bg-white overflow-auto shadow-lg max-h-56 focus:outline-none sm:text-sm`}
          >
            {filteredOptions.map((option, index) => {
              return (
                <li
                  className="cursor-pointer text-black hover:bg-[#FCF3FF] hover:text-[#3C424C] py-2 px-4"
                  key={option.value}
                  onClick={() => {
                    setSuggestionsHandler(option);
                  }}
                  tabIndex={0}
                >
                  {option.label}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
});

export default Autocomplete;
