import { useState, useEffect } from 'react';
import AddButton from '../../../../components/addButton/add_button';
import { useStore } from '../../../../hooks/use-store';
import { observer } from 'mobx-react-lite';
import { v4 as uuidv4 } from 'uuid';
import { safe_get } from '../../../utils';
import { clone } from 'lodash';
import { emitObject } from '../../../../pages/tools/interfaces/interfaces';
import ContentEditableTextArea from '../../../../pages/tools/editable-textarea/editable-textarea';
import { SideOverlay } from '../../../../side-overlay';
import FetchExamples from '../../../sop-components/fetch_examples/fetch_examples';
import EditPopover from '../../final-output-screen-components/edit_popover';
import FetchTemplates from '../../../sop-components/fetch_templates/fetch_templates';
import { useParams } from 'react-router';

const Contribution = observer(() => {
  const { sopStore, newContentStore } = useStore();
  const [contributionData, setContributionData] = useState(
    safe_get(sopStore.sopjsonData, 'contributions', [])
  );
  const [contributions, setContributions] = useState({});
  const [infoIconClicked, setInfoIconClicked] = useState(false);

  const regex = /enter your text/;
  const template_close_handler = () => {
    sopStore.toggle_show_template();
  };

  useEffect(() => {
    setContributionData(safe_get(sopStore.sopjsonData, 'contributions', []));
  }, [sopStore.sopjsonData.contributions]);

  const {id} = useParams()

  useEffect(() => {
    if(id=='155'){
      sopStore.getTemplates({
        tool_id: 155,
        screen: 'S5',
        fileid:sopStore.sopId!=155 ? sopStore.sopId : 0
      });
      sopStore.getExamples({
        tool_id: 155,
        screen: 'S5',
      });
    }
    else{
      sopStore.get_details_of_university();
      sopStore.getTemplates({
        tool_id: 152,
        screen: 'S5',
        fileid:sopStore.sopId!=152 ? sopStore.sopId : 0
      });
      sopStore.getExamples({
        tool_id: 152,
        screen: 'S5',
      });
    }
  }, []);

  const handleDelete = (id) => {
    const temp = clone(sopStore.sopjsonData);
    const contArr = [...safe_get(temp, 'contributions', [])];
    const newArr = contArr.filter((key) => key['id'] !== id);
    setContributionData(newArr);
    if (temp['contributions']) {
      // @ts-ignore
      temp['contributions'] = newArr;
    }
    sopStore.update_sop_json(temp);
  };

  const onEditClicked = (slot, id = '') => {
    const temp = sopStore.sopjsonData[slot];
    if (slot == 'contributions') {
      sopStore.getTemplates({
        tool_id: 152,
        screen: 'S5',
        fileid:sopStore.sopId!=152?sopStore.sopId:0
      });
      sopStore.getExamples({
        tool_id: 152,
        screen: 'S5',
      });
      const item = temp.find((item) => item.id === id);
      setContributions({ ...contributions, [slot]: item?.['value'] });
    } else {
      setContributions({ ...contributions, [slot]: temp });
    }
    sopStore.setEditClicked(true);
    sopStore.setSlot(slot);
    sopStore.setSlotId(id);
  };

  const onBlurTextArea = (textAreaEvent: emitObject) => {
    if (sopStore.final_output_screen) {
      setContributions({ ...contributions, [textAreaEvent.key]: textAreaEvent.value });
    } else if (!sopStore.final_output_screen && textAreaEvent.key !== 'contributions') {
      sopStore.updateSopJsonData(textAreaEvent.key, textAreaEvent.value);
    } else {
      sopStore.updateSopJsonDataArr(textAreaEvent.key, textAreaEvent.id, textAreaEvent.value);
    }
  };

  const onTemplateClicked = (question, slot) => {
    sopStore.setSlot(slot);
    sopStore.set_selected_textarea(question, '', '');
    sopStore.toggle_show_template();
  };

  const handleClickUpdate = (slot, value, id?: string) => {
    if (slot == 'contributions') {
      sopStore.updateSopJsonDataArr(slot, id, value);
    } else {
      sopStore.updateSopJsonData(slot, value);
    }
    sopStore.update_sop_workspace(newContentStore.activeFolder, sopStore.sopId);
  };

  const templatesAndExamplesLength = () => {
    if (infoIconClicked) {
      if (
        sopStore.examplesForScreen &&
        sopStore.examplesForScreen[sopStore.selected_text_area.name].length > 0
      ) {
        return sopStore.examplesForScreen[sopStore.selected_text_area.name].length;
      }
    } else {
      if (
        sopStore.templatesForScreen?.[sopStore.selected_text_area.name] &&
        sopStore.templatesForScreen[sopStore.selected_text_area.name].length > 0
      ) {
        return sopStore.templatesForScreen[sopStore.selected_text_area.name].length;
      } else return 0;
    }
  };

  return (
    <>
      <div className="flex flex-col justify-center relative">
        {sopStore?.questions.map((item: any) => {
          if (item?.screen == 'S5' && item?.slots !== null) {
            return (
              <ContentEditableTextArea
                id={item.id}
                placeholder={sopStore.templateExists(item['question']) ? 'Write your content here or use our template':"Write your content here"}
                onTemplateClicked={() => onTemplateClicked(item.question, item.slots)}
                setInfoIconClicked={setInfoIconClicked}
                onBlur={onBlurTextArea}
                htmlText={sopStore.sopjsonData[item['slots']]}
                heightInRem={sopStore.final_output_screen ? 2 : 8}
                display={item['question']}
                slot={item['slots']}
                regex={regex}
                finalOutputScreen={sopStore.final_output_screen}
                onEditClicked={onEditClicked}
                showpencilIcon={true}
                showTemplateIcon={
                  sopStore.final_output_screen
                    ? false
                    : true && sopStore.templateExists(item['question'])
                }
                showInfoIcon={
                  sopStore.final_output_screen
                    ? false
                    : true && sopStore.exampleExists(item['question'])
                }
              />
            );
          }
        })}

        {contributionData.map((item, index) => {
          return (
            <div key={item.id} className="relative mt-3">
              <ContentEditableTextArea
                id={item.id}
                placeholder={sopStore.templateExists(item['question']) ? 'Write your content here or use our template':"Write your content here"}
                // onTemplateClicked={() => onTemplateClicked(item.question, item.slots)}
                setInfoIconClicked={setInfoIconClicked}
                onBlur={onBlurTextArea}
                htmlText={item.value}
                heightInRem={sopStore.final_output_screen ? 2 : 8}
                display="Tell us about your contributions"
                slot={`contributions`}
                regex={regex}
                finalOutputScreen={sopStore.final_output_screen}
                onEditClicked={onEditClicked}
                showpencilIcon={true}
                showTemplateIcon={false}
                showInfoIcon={false}
                onDelete={handleDelete}
                showDeleteIcon={true}
              />
              {sopStore.slot == 'contributions' && sopStore.slotId == item.id && (
                <EditPopover
                  sopStore={sopStore}
                  handleCancel={() => {}}
                  handleClickUpdate={() =>
                    handleClickUpdate('contributions', contributions['contributions'], item.id)
                  }
                >
                  <ContentEditableTextArea
                    id={item.id}
                    placeholder={'Write your content here or use our template'}
                    // onTemplateClicked={() => onTemplateClicked(item.question, item.slots)}
                    setInfoIconClicked={setInfoIconClicked}
                    onBlur={onBlurTextArea}
                    htmlText={contributions?.['contributions']}
                    heightInRem={sopStore.final_output_screen ? 2 : 8}
                    display="Tell us about your contributions"
                    slot={`contributions`}
                    regex={regex}
                    onEditClicked={onEditClicked}
                    showpencilIcon={true}
                    showTemplateIcon={false}
                    showInfoIcon={false}
                  />
                </EditPopover>
              )}
            </div>
          );
        })}
        <div className="flex justify-center">
          <AddButton
            onClick={() => {
              const obj = {
                id: uuidv4(),
                value: '',
              };
              setContributionData([...contributionData, obj]);
              sopStore.updateSopJsonData('contributions', [
                ...safe_get(sopStore.sopjsonData, 'contributions', []),
                obj,
              ]);
            }}
            onBoard={false}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 mr-1"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
            {contributionData.length !== 0 ? 'Add another contribution' : 'Add another input'}
          </AddButton>
        </div>

        {sopStore.slot == 'curricular_activities' && (
          <EditPopover
            sopStore={sopStore}
            handleCancel={() => {}}
            handleClickUpdate={() =>
              handleClickUpdate('curricular_activities', contributions['curricular_activities'])
            }
          >
            <ContentEditableTextArea
              placeholder={'Write your content here or use our template'}
              onTemplateClicked={() =>
                onTemplateClicked(
                  'What are the extra-curricular activities that you were a part of?',
                  'curricular_activities'
                )
              }
              htmlText={contributions['curricular_activities']}
              onBlur={onBlurTextArea}
              heightInRem={sopStore.final_output_screen ? 2 : 8}
              display="What are the extra-curricular activities that you were a part of?"
              slot="curricular_activities"
              regex={regex}
              // finalOutputScreen={sopStore.final_output_screen}
              onEditClicked={onEditClicked}
              showTemplateIcon={false}
            />
          </EditPopover>
        )}

        {sopStore.slot == 'obstacles_or_hardships' && (
          <EditPopover
            sopStore={sopStore}
            handleCancel={() => {}}
            handleClickUpdate={() =>
              handleClickUpdate('obstacles_or_hardships', contributions['obstacles_or_hardships'])
            }
          >
            <ContentEditableTextArea
              placeholder={'Write your content here or use our template'}
              onTemplateClicked={() =>
                onTemplateClicked(
                  'Any unusual obstacles or hardships that you may have had to overcome in your life?',
                  'obstacles_or_hardships'
                )
              }
              htmlText={contributions['obstacles_or_hardships']}
              onBlur={onBlurTextArea}
              heightInRem={sopStore.final_output_screen ? 2 : 8}
              display="Any unusual obstacles or hardships that you may have had to overcome in your life?"
              slot="obstacles_or_hardships"
              regex={regex}
              // finalOutputScreen={sopStore.final_output_screen}
              onEditClicked={onEditClicked}
              showTemplateIcon={false}
            />
          </EditPopover>
        )}

        {sopStore.slot == 'skills' && (
          <EditPopover
            sopStore={sopStore}
            handleCancel={() => {}}
            handleClickUpdate={() => handleClickUpdate('skills', contributions['skills'])}
          >
            <ContentEditableTextArea
              placeholder={'Write your content here or use our template'}
              onTemplateClicked={() =>
                onTemplateClicked(
                  'What skills (leadership, communicative, analytical, organizational skills, etc.) do you possess?',
                  'skills'
                )
              }
              htmlText={contributions['skills']}
              onBlur={onBlurTextArea}
              heightInRem={sopStore.final_output_screen ? 2 : 8}
              display="What skills (leadership, communicative, analytical, organizational skills, etc.) do you possess?"
              slot="skills"
              regex={regex}
              // finalOutputScreen={sopStore.final_output_screen}
              onEditClicked={onEditClicked}
              showTemplateIcon={false}
            />
          </EditPopover>
        )}

        {sopStore.slot == 'reason_stronger_candidate' && (
          <EditPopover
            sopStore={sopStore}
            handleCancel={() => {}}
            handleClickUpdate={() =>
              handleClickUpdate(
                'reason_stronger_candidate',
                contributions['reason_stronger_candidate']
              )
            }
          >
            <ContentEditableTextArea
              placeholder={'Write your content here or use our template'}
              onTemplateClicked={() =>
                onTemplateClicked(
                  'Why are you a stronger candidate for graduate school and your chosen profession compared to others?',
                  'reason_stronger_candidate'
                )
              }
              htmlText={contributions['reason_stronger_candidate']}
              onBlur={onBlurTextArea}
              heightInRem={sopStore.final_output_screen ? 2 : 8}
              display="Why are you a stronger candidate for graduate school and your chosen profession compared to others?"
              slot="reason_stronger_candidate"
              regex={regex}
              // finalOutputScreen={sopStore.final_output_screen}
              onEditClicked={onEditClicked}
              showTemplateIcon={false}
            />
          </EditPopover>
        )}

        {sopStore.slot == 'reasons_for_admissions_committee' && (
          <EditPopover
            sopStore={sopStore}
            handleCancel={() => {}}
            handleClickUpdate={() =>
              handleClickUpdate(
                'reasons_for_admissions_committee',
                contributions['reasons_for_admissions_committee']
              )
            }
          >
            <ContentEditableTextArea
              placeholder={'Write your content here or use our template'}
              onTemplateClicked={() =>
                onTemplateClicked(
                  'What are the most compelling reasons you can give for the admissions committee to be interested in you?',
                  'reasons_for_admissions_committee'
                )
              }
              htmlText={contributions['reasons_for_admissions_committee']}
              onBlur={onBlurTextArea}
              heightInRem={sopStore.final_output_screen ? 2 : 8}
              display="What are the most compelling reasons you can give for the admissions committee to be interested in you?"
              slot="reasons_for_admissions_committee"
              regex={regex}
              // finalOutputScreen={sopStore.final_output_screen}
              onEditClicked={onEditClicked}
              showTemplateIcon={false}
            />
          </EditPopover>
        )}
      </div>
      <SideOverlay
        children={
          <div>
            {infoIconClicked ? (
              <FetchExamples infoIconClicked={infoIconClicked} sopStore={sopStore} />
            ) : (
              <FetchTemplates setState={setContributions} state={contributions} sopStore={sopStore}
              />
            )}
          </div>
        }
        onClose={template_close_handler}
        show={sopStore.showTemplate}
        title={infoIconClicked ? 'Examples' : 'Templates'}
        length={templatesAndExamplesLength()}
      />
    </>
  );
});

export default Contribution;
