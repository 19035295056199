import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../hooks/use-store';

const PDFLIB= observer((props:any) => {
  const { sopStore} = props;
  const {editorStore} = useStore()

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: 'white',
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
  });
  console.log(editorStore.sopText,"TEXTSOP")
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text>{editorStore.sopText}</Text>
        </View>
      </Page>
    </Document>
  );
});
export default PDFLIB;
