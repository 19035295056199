import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useStore } from '../../../hooks/use-store';
import { observer } from 'mobx-react-lite';

const InputCalender = observer((props: any) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const { userProfileStore } = useStore();

  useEffect(() => {
    if (props.value) {
      const convertDateFormat = (dateString: string): Date | null => {
        const parts = dateString.split('/');
        const year = parseInt(parts[2], 10);
        const month = parseInt(parts[1], 10) - 1; // months are 0-indexed in JavaScript Date
        const day = parseInt(parts[0], 10);
        const convertedDate = new Date(year, month, day);
        return !isNaN(convertedDate.getTime()) ? convertedDate : null;
      };

      const convertedDate = convertDateFormat(props.value);

      if (convertedDate !== null) {
        setSelectedDate(convertedDate);
      }
    }
  }, [props.value]);

  const handleDateChange = (date: Date | null) => {
    if (date && isDateValid(date)) {
      // Format the date to `dd-MM-yyyy`
      const formattedDate = formatDate(date);

      setSelectedDate(date);

      if (props.slot === 'location') {
        userProfileStore.updateUserProfileJsonDataObj(props.slot, props.keyValue, formattedDate);
      } else if (props.slot === 'education' || props.slot === 'work_experience') {
        userProfileStore.updateUserJsonDataArr(
          props.slot,
          props.itemId,
          props.keyValue,
          formattedDate
        );
      }
    }
  };

  const formatDate = (date: Date): string => {
    const day = (`0${date.getDate()}`).slice(-2);
    const month = (`0${date.getMonth() + 1}`).slice(-2); // months are 0-indexed in JavaScript Date
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const isDateValid = (date: Date | null): boolean => {
    return date !== null && !isNaN(date.getTime());
  };
  return (
    <div className="date-picker-container pb-5 flex flex-col">
      <label className="mb-2 left-0 -top-3.5 text-[16px] leading-[22px] text-[#737E93] peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
        {props.label}
      </label>
      <DatePicker
        readOnly={props.disabled}
        selected={selectedDate}
        onChange={handleDateChange}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dateFormat="dd/MM/yyyy"
        dropdownMode="select"
        className="my-date-picker bg-white"
        calendarClassName="my-date-picker-calendar"
        placeholderText={props.placeholder}
      />
    </div>
  );
});

export default InputCalender;
