import React, {Fragment} from "react";
import {observer} from "mobx-react-lite";
import {classNames} from "../../utils";
import {ChevronDownIcon} from "@heroicons/react/24/solid";
import {Transition , Menu} from "@headlessui/react";
import {useStore} from "../../hooks/use-store";
import {useNavigate} from "react-router-dom";
import { Link } from "react-router-dom";


const UserAvatar = observer((props:any) => {


  const {userStore,resumeStore,userProfileStore,dashboardStore,newContentStore,uKSopStore,sopStore,canadaSopStore,b2bStore,lorStore} = useStore();
  const navigate = useNavigate()


  const goBack = () => {
    sopStore.setIsFromCreateSop(false);
    uKSopStore.setIsFromCreateSop(false);
    canadaSopStore.setIsFromCreateSop(false);
    lorStore.setIsFromCreateSop(false);
    dashboardStore.setScreenName('Dashboard');
    navigate("/dashboard");

  };

  const logoutHandler = () => {
    navigate("/login")
    userStore.clearStoredDate();
    userProfileStore.resetUserData();
    newContentStore.reset_newContentStore();
  }

  const profileHandler = () => {
    dashboardStore.setScreenName("Your Profile")
  }

  return(
    <>{dashboardStore.screenName ==='Cambri Editor' ? <i className="fa-solid fa-circle-xmark text-[#A033FF] text-[25px] ml-8 cursor-pointer" onClick={goBack}></i>:

      <Menu as="div" className="relative ml-3 mr-9">
        <div>
          <Menu.Button className="flex max-w-xs items-center rounded-full shadow bg-white text-sm focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 lg:rounded-md lg:p-2 lg:hover:bg-gray-50">
            <span className="ml-3 hidden text-sm font-medium text-gray-700 lg:block">
                        <span className="sr-only">Open user menu for </span>{userStore.email}
                      </span>
            <ChevronDownIcon
              className="ml-1 hidden h-5 w-5 flex-shrink-0 text-gray-400 lg:block"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute z-100 right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/profile"
                  className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                  onClick={()=>{profileHandler();
                  dashboardStore.setShowDashboardSideBar(false);}}
                >
                  Your Profile
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                  onClick={()=>{navigate('/dashboard');
                  dashboardStore.setShowDashboardSideBar(false);
                  dashboardStore.setScreenName("Tools")
                   } }
                >
                  Dashboard
                </a>
              )}
            </Menu.Item>
            {/* <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                >
                  Settings
                </a>
              )}
            </Menu.Item> */}
            <Menu.Item>
              {({ active }) => (
                <button
                  className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 text-left w-full')}
                  onClick={e => {
                    logoutHandler()
                    resumeStore.hide_resume_creation_modal();
                    dashboardStore.updateCurrentStatusForDashboardNav('Tools');
                    localStorage.setItem('userFirstLogin', JSON.stringify(true));
                    b2bStore.setB2bSwitch(false);
                  }}
                >
                  Logout
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
}
     </>
  )
})

export default UserAvatar;
