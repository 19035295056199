import { action, makeAutoObservable, runInAction, toJS } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { UKSopApi } from '../api/uk-sop-api';
import { SopApi } from '../api/sop-api';
import { safe_get } from '../pages/utils';
import _, { clone, cloneDeep } from 'lodash';
import { makeid_alpa_numeric } from '../utils';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import sanitizeHtml from 'sanitize-html';
export const sanitizeConf = {
  allowedTags: [],
  allowedClasses: {
    p: [],
  },
  allowedAttributes: {
    p: [],
  },
};

interface PlagiarismResult {
  plagiarism_result: {
    status: number;
    results: {
      batch: any[];
      score: {
        identicalWords: number;
        aggregatedScore: number;
        minorChangedWords: number;
        relatedMeaningWords: number;
      };
      database: any[];
      internet: {
        id: string;
        url: string;
        tags: any[];
        title: string;
        metadata: {
          filename: string;
        };
        totalWords: number;
        introduction: string;
        matchedWords: number;
        similarWords: number;
        identicalWords: number;
        paraphrasedWords: number;
      }[];
      repositories: any[];
    };
    notifications: {
      alerts: any[];
    };
    scannedDocument: {
      scanId: string;
      credits: number;
      enabled: {
        aiDetection: boolean;
        plagiarismDetection: boolean;
      };
      metadata: {
        filename: string;
      };
      totalWords: number;
      creationTime: string;
      totalExcluded: number;
      expectedCredits: number;
    };
    developerPayload: {file_id:number,user_id:number};
  };
  availableCredits: number;
}

const initialPlagiarismObject: PlagiarismResult = {
  plagiarism_result: {
    status: 0,
    results: {
      batch: [],
      score: {
        identicalWords: 0,
        aggregatedScore: 999,
        minorChangedWords: 0,
        relatedMeaningWords: 0,
      },
      database: [],
      internet: [
        {
          id: 'dummy-id',
          url: 'http://example.com/',
          tags: [],
          title: 'Dummy Internet Source',
          metadata: {
            filename: 'dummy-source',
          },
          totalWords: 0,
          introduction: 'This is a dummy internet source.',
          matchedWords: 0,
          similarWords: 0,
          identicalWords: 0,
          paraphrasedWords: 0,
        },
      ],
      repositories: [],
    },
    notifications: {
      alerts: [],
    },
    scannedDocument: {
      scanId: 'dummy-scan-id',
      credits: 0,
      enabled: {
        aiDetection: false,
        plagiarismDetection: true,
      },
      metadata: {
        filename: 'dummy-file.txt',
      },
      totalWords: 0,
      creationTime: '2023-08-05T11:43:58.683606Z',
      totalExcluded: 0,
      expectedCredits: 0,
    },
    developerPayload: {'user_id': 0, 'file_id': 0},
  },
  availableCredits: 0,
};
export class UKSopStore {
  activeStep = 'Step 1';
  isFileCreated = false;
  selecteduniversitiy = null;
  selectedDegree = null;
  selectedCourse = null;
  jsonDataLoaded = false;
  selectedCard = -1;
  cardSelected = false;
  selectedCardDescription = '';
  newComposeObject = {};
  generatedOutput = '';
  loading = false;
  showTemplate = false;
  editor_data = { blocks: [], entityMap: {} };
  editor_id: string = makeid_alpa_numeric(6);
  sopId: any;
  sopName: '';
  isMovingSopDocDialogOpen: false;
  breadCrumbs:{name:string,isfile:false} [];
  availableCredits: number;
  requiredCredits:number;
  college_summary = '';
  folderid = null;
  sop_description =
    'Choose the course and university you wish to apply to. Let us know your preferences, including specific programs or departments. This information will help us tailor your SOP to highlight your alignment with their offerings.';
  final_output_screen = false;
  edit_clicked = false;
  slot = '';
  slotId = '';
  questions = [];
  templatesForScreen = {};
  premium:boolean=false;
  questionsForScreen = {};
  selected_text_area = {
    name: '',
    template_id: '',
    template_data: '',
  };
  toolId: number;
  is_premium_user:boolean
  isFromCreateSop=false;
  current_folder_id: number;
  stepsArr = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5'];
  templateIndex:number;
  templateIds:any = []
  showPlagiarism = false;
  plagiarismTotalRequestDuration = 200000;
  plagiarismIntervalDuration = 30000;
  plagiarismRequestInProgress = false;
  plagiarism_result: PlagiarismResult = initialPlagiarismObject;
  plagiarismRequestLoading = false;
  plagiarismErrorOccured = false;
  libraryClick : boolean = false;
  documentId:any;
  parentId:any;
  loaderEditor:boolean=false;
  universityDataLor:any;
loader:boolean=false;
saveAndNextButton:boolean=false;
toolsClick:boolean=false;

@action 
setToolsClick(value){
  this.toolsClick = value
}

  @action
  setUniversityDataLor(value){
    this.universityDataLor = value
  }

  @action
  setLibraryClick(value){
    this.libraryClick = value;
  }


  @action
  toggle_show_plagiarism() {
    this.showPlagiarism = !this.showPlagiarism;
  }

  @action setTemplateIndex(index) {
    this.templateIndex = index;
  }
  
  @action setToolId(id) {
    this.toolId = id;
  }

  @action setSlot(slot) {
    this.slot = slot;
  }
  @action
  setFolderId(value){
this.folderid = value;
  };
  @action setIsFromCreateSop(bool){
    this.isFromCreateSop=bool;
  }

  @action setEditClicked(bool) {
    this.edit_clicked = bool;
  }

  @action setFinalOutputScreen(bool) {
    this.final_output_screen = bool;
  }
  @action setSlotId(id) {
    this.slotId = id;
  }

  @action
  set_current_folder_id(id) {
    this.current_folder_id = id;
  }

  steps = [
    {
      id: 'Step 1',
      name: 'General Details',
      href: '#',
      current: true,
      completed: false,
      desc: 'Choose the course and university you wish to apply to. Let us know your preferences, including specific programs or departments. This information will help us tailor your SOP to highlight your alignment with their offerings.',
      selectedTemplate:0
    },
    {
      id: 'Step 2',
      name: 'Professional Experience',
      href: '#',
      current: false,
      completed: false,
      desc: 'Share your motivation and inspiration for pursuing this particular course of study. Express your passion, personal experiences, and aspirations that drive your academic goals. Paint a clear picture of why this field resonates with you.',
      selectedTemplate:0
    },
    {
      id: 'Step 3',
      name: 'Achievements',
      href: '#',
      current: false,
      completed: false,
      desc: 'Outline your specific objectives and goals you aim to achieve through this course. Whether its career advancement, research opportunities, or personal development, articulate your ambitions clearly. ',
      selectedTemplate:0
    },
    {
      id: 'Step 4',
      name: 'Motivation',
      href: '#',
      current: false,
      completed: false,
      desc: 'Highlight your qualifications, skills, and academic background that make you a strong candidate for the chosen course. Showcase your unique strengths, experiences, and achievements that set you apart from other applicants.',
      selectedTemplate:0
    },
    {
      id: 'Step 5',
      name: 'Goals',
      href: '#',
      current: false,
      completed: false,
      desc: 'Go above and beyond by sharing how you can contribute to the college or university community beyond your course of study. Emphasize your leadership abilities, community involvement, or any other relevant skills that make you a valuable asset.',
      selectedTemplate:0
    },
  ];

  defaultSopJsonData = {
    course: {},
    university: {},
    degree: {},
    word_count: '1000',
    under_graduate_project_details: '',
    internship_details: [
      {
        id: uuidv4(),
        Job_Title: '',
        Company_Name: '',
        Time_Frame: '',
        Job_Description: '',
      },
    ],
    work_experience: [
      {
        id: uuidv4(),
        Job_Title: '',
        Company_Name: '',
        Time_Frame: '',
        Job_Description: '',
      },
    ],
    academic_qualifications: '',
    work_details: '',
    dream_career: '',
    accomplishments_in_education_journey: '',
    professional_career_achivements: '',
    biggest_strengths: '',
    Reasons_why_the_admissions_committee_should_accept_you: '',
    why_do_you_want_to_study_this_particular_subject: '',
    why_do_you_want_to_study_at_this_particular_university: '',
    short_term_goals: '',
    long_term_goals: '',
    why_you_want_to_study_this_particular_course_in_the_UK: '',
    defaultQualifications: [],
    profile_id:null,
    templates: [] as number[],
    lastActiveStep:this.activeStep,
     };

  constructor(private ukSopApi: UKSopApi, private sopApi:SopApi) {
    makeAutoObservable(this);
    makePersistable(this, { name: 'UKSopStore', properties: [], storage: window.localStorage });
  }

  sopjsonData = { ...this.defaultSopJsonData };

  @action
  setSopDescription(content) {
    this.sop_description = content;
  }

  @action
  updateSopJsonData(key, value) {
    this.sopjsonData = {
      ...this.sopjsonData,
      [key]: value,
    };
  }

  @action
  templateExists(question) {
    return (
      (this.templatesForScreen[question] && this.templatesForScreen[question].length > 0) || false
    );
  }

  @action
  setIsSopDocMovingDialogOpen(payload) {
    this.isMovingSopDocDialogOpen = payload;
  }

  @action
  updateSopJsonDataArr(slot, key, value) {
    const temp = clone(this.sopjsonData);
    safe_get(temp, slot, []).map((item) => {
      if (item.id === key) {
        item.value = value;
      }
      this.sopjsonData = temp;
    });
  }

  @action updateProfessionalExpSopJson(value, key, index, id,slot) {
    const temp = clone(this.sopjsonData);
    temp[slot][index][key] = value;
    this.sopjsonData = temp;
  }

  @action
  updateSopJsonObj(slot, value) {
    const temp = clone(this.sopjsonData);
    temp[slot] = {};
    if (slot == 'motivation') {
      temp[slot] = {
        key: this.selectedCard,
        value: value,
        cardTitle: this.selectedCardDescription,
      };
    } else {
      temp[slot] = {
        key: this.selectedCard,
        value: value,
      };
    }
    this.sopjsonData = temp;
  }

  @action
  updateCurrentStatusForStep(stepName) {
    this.steps.map((step) => {
      if (step.id === stepName) {
        step.current = true;
      } else {
        step.current = false;
      }
    });
  }

  @action
  updateSelectedTextarea(textareaName) {
    console.log(textareaName, 'textAreaname');
  }

  @action
  set_selected_textarea(textareaName, id, content) {
    this.selected_text_area.name = textareaName;
    this.selected_text_area.template_id = id;
    this.selected_text_area.template_data = content;
    this.updateSelectedTextarea(textareaName);
  }

  @action
  set_active_step(stepName) {
    this.activeStep = stepName;
    this.sopjsonData.lastActiveStep = stepName;
    this.updateCurrentStatusForStep(stepName);
  }

  @action
  get_university_details() {
    const resp = this.ukSopApi.get_university_details();
    return resp;
  }

  // @action
  // get_course_details(payload) {
  //   const resp = this.ukSopApi.get_course_details(payload);
  //   console.log(toJS(resp),"UKSOPRESPONSE")
  //   return resp;
  // }
  @action
  get_course_detailsALL() {
    const resp = this.ukSopApi.get_course_detailsALL();
    return resp;
  }

  @action
  updateSelectedUniversity(value) {
    this.selecteduniversitiy = value;
  }

  @action
  updateSelectedDegree(value) {
    this.selectedDegree = value;
  }

  @action
  updateSelectedCourse(value) {
    this.selectedCourse = value;
  }

  @action
  clear_newCompose_object() {
    this.newComposeObject = {};
  }

  @action
  updateNewComposeObject(key, value) {
    this.newComposeObject[key] = value;
  }

  @action
  set_new_composition_object(data: object) {
    this.newComposeObject = data;
  }

  @action
  update_sop_json(json) {
    this.sopjsonData = json;
  }

  @action 
  updateSelectedTemplate(stepName,id) {
     this.steps.forEach((step) => {
      if(step.id == stepName){
          step.selectedTemplate = id
      }
     })
  }

  getTemplateIds = () => {
    const ids:number[] = []
       this.steps.forEach((step) => {
        if(step.selectedTemplate !== 0){
          ids.push(step.selectedTemplate) 
        }
      })
      this.sopjsonData.templates = ids
    }

  @action
  async update_sop_workspace(folderId, fileId) {
    this.saveAndNextButton = true
    this.set_selected_textarea(
      this.selected_text_area.name,
      this.selected_text_area.template_id,
      ''
          );
    this.getTemplateIds()
    const cloneSopJsonData = cloneDeep(toJS(this.sopjsonData));
    this.sopjsonData = cloneSopJsonData;

    const payloadData = {
      gentool: '153',
      folderid: folderId ? folderId : 0,
      documentid: fileId ? fileId : 0,
      payload: this.sopjsonData,
      issubmitted: false,
      profile_id:this.sopjsonData.profile_id
    };
    const resp = await this.ukSopApi.update_workspace(payloadData);
    if (resp.status == 200) {
      this.saveAndNextButton = false
      this.sopId = resp.data.documentid;
      this.edit_clicked = false;
      this.availableCredits=resp.data.availableCredits;
    }
    return resp;
  }

  @action
  async save_sop_file(activeFolder, fileName,parentId) {
    const payloadData = {
      parent: activeFolder,
      name: fileName ? fileName : '',
      tool_id: this.toolId,
    };
    const resp = await this.ukSopApi.create_file(payloadData);
    runInAction(() => {
      if (resp.status === 200) {
        this.update_sop_workspace(resp.data.folder_id, resp.data.file_id);
        this.sopId = resp.data.file_id;
        this.folderid = resp.data.folder_id;
      }
    });
  }
  @action
    setProfileId(value)
    {
      this.sopjsonData.profile_id=value
    }
  @action
  async getTemplates(payload) {
    const resp = await this.ukSopApi.get_templates(payload);
    runInAction(() => {
      if (resp.status === 200) {
        this.templatesForScreen = resp.data;
      }
    });
  }

  @action
  async getExamples(payload) {
    const resp = await this.ukSopApi.get_templates(payload);
    runInAction(() => {
      if (resp.status === 200) {
        this.questionsForScreen = resp.data;
      }
    });
  }

  @action
  async get_details_of_university() {
    const payloadData = {
      kid: safe_get(this.sopjsonData, 'university.value', ''),
    };
    const resp = await this.ukSopApi.get_details_of_university(payloadData);
    runInAction(() => {
      if (resp.status === 200) {
        if (resp.data && resp.data.length > 0 && resp.data[0]['summary']) {
          this.college_summary = resp.data[0]['summary'];
        }
      }
    });
  }

  @action
  toggle_show_template() {
    this.showTemplate = !this.showTemplate;
  }

  @action
  set_sop_id(id) {
    this.sopId = id;
  }

  @action async get_generated_sop(sopId, folderId) {
    this.loading = true;
    const resp = await this.ukSopApi.get_generated_sop(sopId, folderId);
    this.generatedOutput = resp.data?.output;
    this.append_paragraph_to_editor_json(resp.data.output);
    this.is_premium_user = resp.data.premium_user
  }

  @action async setPremiumUser(bool){
    this.is_premium_user=bool;
  }

  @action async get_questions(toolId) {
    this.loading = true;
    const resp = await this.ukSopApi.get_questions(toolId);
    this.questions = resp.data.questions;
    this.is_premium_user = resp.data.premium_user;
    this.availableCredits= resp.data.availableCredits;
  }

  @action
  async get_sop_workspace(sopId, folderId) {
    this.loading = true;
    const payloadData = {
      toolid: '153',
      documentid: sopId ? sopId : 0,
      folderid: folderId ? folderId : 0,
      payload: {},
      issubmitted: false,
    };
    const resp = await this.ukSopApi.get_workspace(payloadData);
    runInAction(() => {
      if (resp.status === 200) {
        this.loading = false;
        if (resp.data && resp.data.state) {
          let sopJsonObj = { ...this.sopjsonData };
          this.sopjsonData = { ...sopJsonObj, ...safe_get(resp.data, 'state', this.sopjsonData) };
          this.sopName = resp.data.file_name;
          this.breadCrumbs = resp.data.bread_crums;
          this.folderid = resp.data.folderid;
          this.stepsArr.map((step) => {
            this.markCompleted(step);
          });
        }
        this.jsonDataLoaded = true;
        this.availableCredits = resp.data.availableCredits;
        this.requiredCredits = resp.data.requiredCredits;
        this.premium= resp.data.premium_user
      }
    });
  }

  @action
  updateCardSelected(value) {
    this.cardSelected = value;
  }

  @action
  updateCardDescription(value) {
    this.selectedCardDescription = value;
  }

  @action
  update_selected_card(id) {
    this.selectedCard = id;
  }

  @action
  async generatecontent(profile_id: string) {
    this.loading = true;

    const payload = {
      toolid: 153,
      fileid: this.sopId,
      folderid: this.folderid,
      profileid: profile_id,
      questions:{
      course: safe_get(this.sopjsonData, 'course.label', ''),
      university: safe_get(this.sopjsonData, 'university.label', ''),
      summary: this.college_summary,
      degree: safe_get(this.sopjsonData, 'degree.label', ''),
      word_count: '1000',
      short_term_goals: safe_get(this.sopjsonData, 'short_term_goals', ''),
      long_term_goals: safe_get(this.sopjsonData, 'long_term_goals', ''),
      academic_qualifications: safe_get(this.sopjsonData, 'Academic_qualifications', ''),
      work_details: safe_get(this.sopjsonData, 'Work_details', ''),
      dream_career: safe_get(this.sopjsonData, 'Dream_career', ''),
      accomplishments_in_education_journey: safe_get(
        this.sopjsonData,
        'accomplishments_in_education_journey',
        ''
      ),
      professional_career_achivements: safe_get(
        this.sopjsonData,
        'professional_career_achivements',
        ''
      ),
      biggest_strengths: safe_get(this.sopjsonData, 'biggest_strengths', ''),
      reasons_why_the_admissions_committee_should_accept_you: safe_get(
        this.sopjsonData,
        'Reasons_why_the_admissions_committee_should_accept_you',
        ''
      ),
      why_do_you_want_to_study_this_particular_subject: safe_get(
        this.sopjsonData,
        'Why_do_you_want_to_study_this_particular_subject',
        ''
      ),
      why_do_you_want_to_study_at_this_particular_university: safe_get(
        this.sopjsonData,
        'Why_do_you_want_to_study_at_this_particular_university',
        ''
      ),
      why_you_want_to_study_this_particular_course_in_the_UK: safe_get(
        this.sopjsonData,
        'why_you_want_to_study_this_particular_course_in_the_UK',
        ''
      ),
      under_graduate_project_details: safe_get(
        this.sopjsonData,
        'Under_graduate_project_details',
        ''
      ),
      internship_details: safe_get(this.sopjsonData, 'Internship_details', ''),
      work_experience: safe_get(this.sopjsonData, 'work_experience', ''),
    }
    };

    const resp = await this.ukSopApi.generate_uk_content(payload);
    console.log("loading1");
    this.loading = true
    if (resp.status === 200) {
      console.log(this.loaderEditor,"DOCUMENTID11");
      this.loaderEditor = true;
      console.log(this.loaderEditor,"DOCUMENTID12");
      console.log("loading2");
    this.loading = false
      this.loader=true
      this.append_paragraph_to_editor_json(resp.data.response);
      this.generatedOutput = resp.data.response;
      this.availableCredits = resp.data.availableCredits;
      //this.is_premium_user=resp.data.is_premium_user
    } else {
      this.loading = false;
      toast.error(resp.data, { position: 'top-center' });
    }
    console.log("loading3");
    this.loading = false;
  }

  @action
  get_initial_json_for_editor() {
    return this.editor_data;
  }

  @action
  update_editor_data(data) {
    this.editor_data = data;
  }

  @action
  clear_sop_state() {
    this.sopjsonData = { ...this.defaultSopJsonData };
    this.generatedOutput = '';
    this.jsonDataLoaded = false;
    this.set_active_step('Step 1');
    this.editor_data = { blocks: [], entityMap: {} };
  }
  @action
  library_sop_state() {
    this.sopjsonData = { ...this.sopjsonData };
    this.generatedOutput = '';
    this.jsonDataLoaded = false;
    this.editor_data = { blocks: [], entityMap: {} };
  }
  @action
  refresh_editor_id() {
    this.editor_id = makeid_alpa_numeric(6);
  }

  @action
  append_paragraph_to_editor_json(text) {
    const block = {
      data: {},
      depth: 0,
      entityRanges: [],
      inlineStyleRanges: [],
      key: makeid_alpa_numeric(5),
      text: text.replace(/<\/br>/g, ''),
      type: 'paragraph',
    };
    const data = this.editor_data;
    // @ts-ignore
    data['blocks'] = [block];
    this.update_editor_data(data);
    this.refresh_editor_id();
  }

  @action
  updateCompletedStatusForStep(stepName, isCompleted) {
    this.steps.map((step) => {
      if (step.id === stepName) {
        if (isCompleted) {
          step.completed = true;
        } else {
          step.completed = false;
        }
      }
    });
  }

  @action
  markCompleted(stepName) {
    switch (stepName) {
      case 'Step 1':
        if (
          !_.isEmpty(safe_get(this.sopjsonData, 'course', {})) &&
          !_.isEmpty(safe_get(this.sopjsonData, 'university', {})) &&
          !_.isEmpty(safe_get(this.sopjsonData, 'degree', {})) &&
          safe_get(this.sopjsonData, 'academic_qualifications', '') !== '' &&
          safe_get(this.sopjsonData, 'work_details', '') !== '' &&
          safe_get(this.sopjsonData, 'dream_career', '') !== ''
        ) {
          this.updateCompletedStatusForStep(stepName, true);
        } else {
          this.updateCompletedStatusForStep(stepName, false);
        }
        return;
      case 'Step 2':
        let hasObjectWithValues = false;

        this.sopjsonData.internship_details.map(item => {
          if(item.Company_Name && item.Job_Description && item.Job_Title && item.Time_Frame) {
            hasObjectWithValues=true
          }
        })

        this.sopjsonData.work_experience.map(item => {
          if(item.Company_Name && item.Job_Description && item.Job_Title && item.Time_Frame) {
            hasObjectWithValues=true
          }
        })

        if (hasObjectWithValues) {
          this.updateCompletedStatusForStep(stepName, true);
        } else {
          this.updateCompletedStatusForStep(stepName, false);
        }
        return;
      case 'Step 3':
        if (
          safe_get(this.sopjsonData, 'under_graduate_project_details', '') !== '' &&
          safe_get(this.sopjsonData, 'accomplishments_in_education_journey', '') !== '' &&
          safe_get(this.sopjsonData, 'professional_career_achivements', '') !== '' &&
          safe_get(this.sopjsonData, 'biggest_strengths', '') !== '' &&
          safe_get(
            this.sopjsonData,
            'reasons_why_the_admissions_committee_should_accept_you',
            ''
          ) !== ''
        ) {
          this.updateCompletedStatusForStep(stepName, true);
        } else {
          this.updateCompletedStatusForStep(stepName, false);
        }
        return;
      case 'Step 4':
        if (
          safe_get(
            this.sopjsonData,
            'why_do_you_want_to_study_at_this_particular_university',
            ''
          ) ||
          safe_get(this.sopjsonData, 'why_do_you_want_to_study_this_particular_subject', '') ||
          safe_get(this.sopjsonData, 'why_you_want_to_study_this_particular_course_in_the_uk', '')
        ) {
          this.updateCompletedStatusForStep(stepName, true);
        } else {
          this.updateCompletedStatusForStep(stepName, false);
        }
        return;
      case 'Step 5':
        if (
          safe_get(this.sopjsonData, 'short_term_goals', '') !== '' &&
          safe_get(this.sopjsonData, 'long_term_goals', '') !== ''
        ) {
          this.updateCompletedStatusForStep(stepName, true);
        } else {
          this.updateCompletedStatusForStep(stepName, false);
        }
        return;
    }
  }

  @action
  async single_palgarism_request() {
    if (this.plagiarismRequestInProgress) {
      return {};
    }

    this.plagiarismRequestInProgress = true;
    const response = await this.sopApi.get_palgarism_details(this.sopId);
    if (response.status === 200) {
      this.plagiarismRequestInProgress = false;
      return response.data;
    } else {
      this.plagiarismRequestInProgress = false;
      toast.error(response.data, { position: 'top-center' });
      return {};
    }
  }

  @action
  async get_palgarism_details() {
    const requestStartTime = Date.now();
    const requestEndTime = requestStartTime + this.plagiarismTotalRequestDuration;

    while (Date.now() < requestEndTime) {
      const response = await this.single_palgarism_request();
      if (response && response.plagiarism_result) {
        this.plagiarism_result = response;
        this.availableCredits=response.availableCredits;
        break;
      }

      await new Promise((resolve) => setTimeout(resolve, this.plagiarismIntervalDuration));
    }
  }

  @action
  async sendPlagiarismRequest() {
    this.plagiarismRequestLoading = true;
    this.resetPlagiarsim();
    console.log(this.generatedOutput,"PLAGARISMUK");
    let payload = { input_text: this.generatedOutput, file_id: this.sopId };
    //Remove for testing let payload = { input_text: p, file_id: this.sopId };
    const resp = await this.sopApi.submit_plagiarsim_scan(payload);
    if (resp.status === 200) {
      await this.get_palgarism_details();
      if (
        safe_get(this.plagiarism_result.plagiarism_result.results.score, 'aggregatedScore', 999) ===
        999
      ) {
        this.plagiarismErrorOccured = true;
      }
    } else {
      this.plagiarismRequestLoading = false;
      toast.error(resp.data, { position: 'top-center' });
      this.plagiarism_result =initialPlagiarismObject;
    }
    this.plagiarismRequestLoading = false;
  }

  @action 
  async deletePlagarism(file_id){
    return await this.sopApi.delete_plagarism_result(file_id);
  }
  @action 
  setGeneratedOutput(value){
    this.generatedOutput = value
  }

  @action
  resetPlagiarsim(){
    this.plagiarismRequestInProgress = false;
    this.plagiarismErrorOccured = false;
    this.plagiarism_result = initialPlagiarismObject;
  }



}
