import { Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useStore } from '../../hooks/use-store';
import { observer } from 'mobx-react-lite';
import SopFolders from './sop-folders';
import { SOPNameStepperComponent, SopProfileSelectionStepperComponent } from './sop-profile-selection';
import { useNavigate } from 'react-router';
import SopName from './sop-filename';
import { classNames, safe_get } from '../utils';
import { toJS } from 'mobx';

export const SopSelectionModal = observer((props) => {
    const {
      sopProfileSelectionStore, sopStore, lorStore, userStore, dashboardStore, userProfileStore, newToolsStore, newContentStore,
      documentStore,resumeStore,
      uKSopStore,
      canadaSopStore,
      germanyStore,
    } = useStore();


    const { pre, post } = sopProfileSelectionStore.sop_selection_progress_stepper_status();

    const navigate = useNavigate();

    const initflow = async () => {

      if (userStore.isenterprise) {
        sopProfileSelectionStore.getSopProfileSelectionDropdownValues();
      } else {
        await sopProfileSelectionStore.getSopProfileSelectionDropdownValues();
        if(sopProfileSelectionStore.sopProfileSelectionValues.length > 0) {
          const default_profile_id = sopProfileSelectionStore.sopProfileSelectionValues[0]['profile_id']
          await sopProfileSelectionStore.select_student(default_profile_id)
        }
      }
    };

    useEffect(() => {
      initflow();
    }, []);

    useEffect(() => {
      if (!sopProfileSelectionStore.sopSelectionEdit) {
        sopProfileSelectionStore.getSopProfileSelectionDropdownValues();
      }
      sopProfileSelectionStore.set_active_tool_id(newToolsStore.activate_tool_id);
    }, [sopProfileSelectionStore.sopSelectionEdit]);

    function isStepperDisabled() {
      const isDisabled = sopProfileSelectionStore.isStepperDisabled(userStore.isenterprise);
      //console.log('isDisabled', isDisabled);
      return isDisabled;
    }

    const mimick_library_item_click = async (item) => {
      console.log(toJS(item),"ITEMS")
      newContentStore.setLibraryClick(false);
      sopProfileSelectionStore.setUserSelectionClick(true);
      sopStore.documentId = item.id;
      sopStore.parentId = item.parentid;
      uKSopStore.documentId = item.id;
      uKSopStore.parentId = item.parentid;
      canadaSopStore.documentId = item.id;
      canadaSopStore.parentId = item.parentid;
      lorStore.documentId = item.id;
      lorStore.parentId = item.parent_id;
      germanyStore.documentId= item.id;
      germanyStore.parentId = item.parentid;

      const isfile = safe_get(item, 'isfile', false);
      if (!isfile) {
        // nothing
      }
      if (item['tool_id'] === 152 && item['lastActiveStep'] === 'Step 1') {
        navigate('/sop');
        sopStore.set_active_step('Step 1');
        sopStore.setSopDescription(
          'Choose the course and university you wish to apply to. Let us know your preferences, including specific programs or departments. This information will help us tailor your SOP to highlight your alignment with their offerings.'
        );
      }
      if (item['tool_id'] === 152 && item['lastActiveStep'] === 'Step 2') {
        navigate('/sop');
        sopStore.set_active_step('Step 2');
        sopStore.setSopDescription(
          'Share your motivation and inspiration for pursuing this particular course of study. Express your passion, personal experiences, and aspirations that drive your academic goals. Paint a clear picture of why this field resonates with you.'
        );
      }
      if (item['tool_id'] === 152 && item['lastActiveStep'] === 'Step 3') {
        navigate('/sop');
        sopStore.set_active_step('Step 3');
        sopStore.setSopDescription(
          'Outline your specific objectives and goals you aim to achieve through this course. Whether its career advancement, research opportunities, or personal development, articulate your ambitions clearly.'
        );
      }
      if (item['tool_id'] === 152 && item['lastActiveStep'] === 'Step 4') {
        navigate('/sop');
        sopStore.set_active_step('Step 4');
        sopStore.setSopDescription(
          'Highlight your qualifications, skills, and academic background that make you a strong candidate for the chosen course. Showcase your unique strengths, experiences, and achievements that set you apart from other applicants.'
        );
      }
      if (item['tool_id'] === 153 && item['lastActiveStep'] === 'Step 1') {
        navigate(`/uksop/${item['id']}`);
        uKSopStore.set_active_step('Step 1');
        uKSopStore.setSopDescription(
          'Choose the course and university you wish to apply to. Let us know your preferences, including specific programs or departments. This information will help us tailor your SOP to highlight your alignment with their offerings.'
        );
      }
      if (item['tool_id'] === 153 && item['lastActiveStep'] === 'Step 2') {
        navigate(`/uksop/${item['id']}`);
        uKSopStore.set_active_step('Step 2');
        uKSopStore.setSopDescription(
          'Share your motivation and inspiration for pursuing this particular course of study. Express your passion, personal experiences, and aspirations that drive your academic goals. Paint a clear picture of why this field resonates with you.'
        );
      }
      if (item['tool_id'] === 153 && item['lastActiveStep'] === 'Step 3') {
        navigate(`/uksop/${item['id']}`);
        uKSopStore.set_active_step('Step 3');
        uKSopStore.setSopDescription(
          'Outline your specific objectives and goals you aim to achieve through this course. Whether its career advancement, research opportunities, or personal development, articulate your ambitions clearly.'
        );
      }
      if (item['tool_id'] === 153 && item['lastActiveStep'] === 'Step 4') {
        navigate(`/uksop/${item['id']}`);
        uKSopStore.set_active_step('Step 4');
        uKSopStore.setSopDescription(
          'Highlight your qualifications, skills, and academic background that make you a strong candidate for the chosen course. Showcase your unique strengths, experiences, and achievements that set you apart from other applicants.'
        );
      }
      if (item['tool_id'] === 154 && item['lastActiveStep'] === 'Step 1') {
        navigate(`/canadasop/${item['id']}`);
        canadaSopStore.set_active_step('Step 1');
        canadaSopStore.setSopDescription(
          'Choose the course and university you wish to apply to. Let us know your preferences, including specific programs or departments. This information will help us tailor your SOP to highlight your alignment with their offerings.'
        );
      }
      if (item['tool_id'] === 154 && item['lastActiveStep'] === 'Step 2') {
        navigate(`/canadasop/${item['id']}`);
        canadaSopStore.set_active_step('Step 2');
        canadaSopStore.setSopDescription(
          'Share your motivation and inspiration for pursuing this particular course of study. Express your passion, personal experiences, and aspirations that drive your academic goals. Paint a clear picture of why this field resonates with you.'
        );
      }
      if (item['tool_id'] === 154 && item['lastActiveStep'] === 'Step 3') {
        navigate(`/canadasop/${item['id']}`);
        canadaSopStore.set_active_step('Step 3');
        canadaSopStore.setSopDescription(
          'Outline your specific objectives and goals you aim to achieve through this course. Whether its career advancement, research opportunities, or personal development, articulate your ambitions clearly. '
        );
      }
      if (item['tool_id'] === 154 && item['lastActiveStep'] === 'Step 4') {
        navigate(`/canadasop/${item['id']}`);
        canadaSopStore.set_active_step('Step 4');
        canadaSopStore.setSopDescription(
          'Highlight your qualifications, skills, and academic background that make you a strong candidate for the chosen course. Showcase your unique strengths, experiences, and achievements that set you apart from other applicants.'
        );
      }
      if (item['tool_id'] === 154 && item['lastActiveStep'] === 'Step 5') {
        navigate(`/canadasop/${item['id']}`);
        canadaSopStore.set_active_step('Step 5');
        canadaSopStore.setSopDescription(
          'Go above and beyond by sharing how you can contribute to the college or university community beyond your course of study. Emphasize your leadership abilities, community involvement, or any other relevant skills that make you a valuable asset.'
        );
      }
      if (item['tool_id'] === 154 && item['lastActiveStep'] === 'Step 6') {
        navigate(`/canadasop/${item['id']}`);
        canadaSopStore.set_active_step('Step 6');
        canadaSopStore.setSopDescription(
          'Go above and beyond by sharing how you can contribute to the college or university community beyond your course of study. Emphasize your leadership abilities, community involvement, or any other relevant skills that make you a valuable asset.'
        );
      }
      if (item['tool_id'] === 155 && item['lastActiveStep'] === 'Step 1') {
        navigate(`/australiasop/${item['id']}`);
        sopStore.set_active_step('Step 1');
        sopStore.setSopDescription(
          'Highlight your qualifications, skills, and academic background that make you a strong candidate for the chosen course. Showcase your unique strengths, experiences, and achievements that set you apart from other applicants.'
        );
      }
      if (item['tool_id'] === 155 && item['lastActiveStep'] === 'Step 2') {
        navigate(`/australiasop/${item['id']}`);
        sopStore.set_active_step('Step 2');
        sopStore.setSopDescription(
          'Highlight your qualifications, skills, and academic background that make you a strong candidate for the chosen course. Showcase your unique strengths, experiences, and achievements that set you apart from other applicants.'
        );
      }
      if (item['tool_id'] === 155 && item['lastActiveStep'] === 'Step 3') {
        navigate(`/australiasop/${item['id']}`);
        sopStore.set_active_step('Step 3');
        sopStore.setSopDescription(
          'Highlight your qualifications, skills, and academic background that make you a strong candidate for the chosen course. Showcase your unique strengths, experiences, and achievements that set you apart from other applicants.'
        );
      }
      if (item['tool_id'] === 155 && item['lastActiveStep'] === 'Step 4') {
        navigate(`/australiasop/${item['id']}`);
        sopStore.set_active_step('Step 4');
        sopStore.setSopDescription(
          'Highlight your qualifications, skills, and academic background that make you a strong candidate for the chosen course. Showcase your unique strengths, experiences, and achievements that set you apart from other applicants.'
        );
      }
      if (item['tool_id'] === 156 && item['lastActiveStep'] === 'Step 1') {
        navigate(`/germanysop/${item['id']}`);
        germanyStore.set_active_step('Step 1');
        germanyStore.setSopDescription(
          'Tell us your top university and course choices. Your details will help us shape an SOP that showcases your fit perfectly.'
        );
      }
      if (item['tool_id'] === 156 && item['lastActiveStep'] === 'Step 2') {
        navigate(`/germanysop/${item['id']}`);
        germanyStore.set_active_step('Step 2');
        germanyStore.setSopDescription(
          ' What keeps you going? Describe the reasons behind your pursuit of a Masters degree, including academic, personal, and professional motivations.'
        );
      }
      if (item['tool_id'] === 156 && item['lastActiveStep'] === 'Step 3') {
        navigate(`/germanysop/${item['id']}`);
        germanyStore.set_active_step('Step 3');
        germanyStore.setSopDescription(
          'Help us understand your vision and ambitions that guide your pursuit of your chosen program, detailing both the short-term and long-term goals that shape your journey ahead.'
        );
      }
      if (item['tool_id'] === 156 && item['lastActiveStep'] === 'Step 4') {
        navigate(`/germanysop/${item['id']}`);
        germanyStore.set_active_step('Step 4');
        germanyStore.setSopDescription(
          'Talk about your educational experiences so far and how they have helped you pursue your career goals. '
        );
      }
        if (item['tool_id'] === 156 && item['lastActiveStep'] === 'Step 5') {
          navigate(`/germanysop/${item['id']}`);
          germanyStore.set_active_step('Step 5');
          germanyStore.setSopDescription(
            ' Reflect on your professional life, showcasing roles, key achievements, and skills that have shaped your career path and align with your future goals.'
          );
              }
      else {
        console.log(newContentStore.activeFolder,"ACTIVEFOLDER");
        if (
          (item['tool_id'] === 152 && item['isEmpty'] === false) ||
          item['lastActiveStep'] === null ||
          item['lastActiveStep'] === 'Step 5'
        ) {
          console.log("USASOP");
          navigate(`/sop/${item['id']}/${sopStore.parentId}`);
        }
        else if (item['tool_id'] === 152 && item['isEmpty'] === true && item['lastActiveStep'] === 'Step 5') {
          console.log("USASOP1");
          navigate(`/sop/${item['id']}/${sopStore.parentId}`);
        } else if (
          (item['tool_id'] === 153 && item['isEmpty'] === false) ||
          item['lastActiveStep'] === null ||
          item['lastActiveStep'] === 'Step 5'
        ) {
          navigate(`/uksop/${item['id']}/${uKSopStore.parentId}`);
        }
      else  if (item['tool_id'] === 153 && item['isEmpty'] === true && item['lastActiveStep'] === 'Step 5') {
          navigate(`/uksop/${item['id']}/${uKSopStore.parentId}`);
        } else if (
          (item['tool_id'] === 154 && item['isEmpty'] === false) ||
          item['lastActiveStep'] === null ||
          item['lastActiveStep'] === 'Step 7'
        ) {
          navigate(`/canadasop/${item['id']}/${canadaSopStore.parentId}`);
        }
        else if (item['tool_id'] === 154 && item['isEmpty'] === true && item['lastActiveStep'] === 'Step 7') {
          navigate(`/canadasop/${item['id']}/${canadaSopStore.parentId}`);
        } else if (item['tool_id'] === 151) {
          navigate(`/lor/${item['id']}/${lorStore.parentId}`);
        } else if (
          (item['tool_id'] === 155 && item['isEmpty'] === false) ||
          item['lastActiveStep'] === null ||
          item['lastActiveStep'] === 'Step 5'
        ) {
          navigate(`/australiasop/${item['id']}/${sopStore.parentId}`);
        }
        else if (item['tool_id'] === 155 && item['isEmpty'] === true && item['lastActiveStep'] === 'Step 5') {
          navigate(`/australiasop/${item['id']}/${sopStore.parentId}`);
        }   
       else if(item['tool_id'] === 156 && item['isEmpty'] === true && item['lastActiveStep'] === 'Step 6' ){
          navigate(`/germanysop/${item['id']}/${germanyStore.parentId}`);
          await germanyStore.get_sop_workspace(germanyStore.documentId, germanyStore.parentId);
        }
        else if (
          (item['tool_id'] === 156 && item['isEmpty'] === false) ||
          item['lastActiveStep'] === null ||
          item['lastActiveStep'] === 'Step 6'
        ) {
          navigate(`/germanysop/${item['id']}/${germanyStore.parentId}`);
          await germanyStore.get_sop_workspace(germanyStore.documentId, germanyStore.parentId);
        }
         else {
          return 'Not Found';
        }
      }
    };


    const getUrl = (id) => {
      if (id === 152) {
        return '/sop';
      } else if (id === 153) {
        return '/uksop';
      } else if (id === 154) {
        return '/canadasop';
      } else if (id === 155) {
        return '/australiasop';
      } else if (id === 151) {
        return '/lor';
      }
      else if (id === 156) {
        return '/germanysop';
      } 
    };
console.log(
  !userStore.isenterprise &&
       toJS(sopProfileSelectionStore.sopProfileSelectionValues),"CURRENTSTATE");
// @ts-ignore
    return (
      <>
        {userStore.isenterprise ? (
          <Transition.Root
            show={sopProfileSelectionStore.showSopSelectionCreationModal}
            as={Fragment}
          >
            <Dialog as="div" className="relative z-50" onClose={() => {
            }}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel
                      className="flex relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-sm "
                      style={{
                        minWidth: 800,
                        maxHeight: 470,
                        maxWidth: 800,
                        width: 800,
                        height: 470
                      }}
                    >
                      <div className="resume-modal flex flex-col grow">
                        <div className="modal-header flex flex-row w-full items-center justify-between py-5 px-6">
                          <div
                            className={''}
                            style={{
                              fontWeight: 500,
                              fontSize: '24px',
                              lineHeight: '32px',
                              color: '#3C424C'
                            }}
                          >
                            {lorStore.isLor
                              ? 'Select user for writing their LOR'
                              : sopProfileSelectionStore.current_sop_selection_step['name']}
                          </div>

                          <span className="flex-end">
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              sopProfileSelectionStore.resetState();
                              navigate('/dashboard');
                              dashboardStore.setScreenName('Tools');
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 100 100"
                            >
                              <line
                                x1="10"
                                y1="10"
                                x2="90"
                                y2="90"
                                stroke="black"
                                strokeWidth="5"
                              />
                              <line
                                x1="10"
                                y1="90"
                                x2="90"
                                y2="10"
                                stroke="black"
                                strokeWidth="5"
                              />
                            </svg>
                          </div>
                        </span>
                        </div>
                        <div className="modal-stepper h-0">
                          <nav aria-label="Progress">
                            <ol role="list" className="space-y-4 md:flex md:space-x-1 md:space-y-0">
                              {Array.from(Array(pre), (e, i) => {
                                return (
                                  <li className="md:flex-1">
                                    <a className="group flex flex-col border-l-4 border-[#5EAEFF] py-2 pl-4 hover:border-indigo-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"/>
                                  </li>
                                );
                              })}

                              <li className="md:flex-1">
                                <a
                                  className="flex flex-col border-l-4 border-[#5EAEFF] py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                                  aria-current="step"
                                />
                              </li>

                              {Array.from(Array(post), (e, i) => {
                                return (
                                  <li className="md:flex-1">
                                    <a className="group flex flex-col border-l-4 border-gray-500 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"/>
                                  </li>
                                );
                              })}
                            </ol>
                          </nav>
                        </div>
                        <div
                          className={`flex flex-col modal-body grow`}
                          style={{ minHeight: 330, maxHeight: 330, width: 800, height: 330 }}
                        >
                          {sopProfileSelectionStore.current_sop_selection_step_num == 0 ? (
                            <SopProfileSelectionStepperComponent/>
                          ) : null}
                          {sopProfileSelectionStore.current_sop_selection_step_num == 1 ? (
                            <SOPNameStepperComponent/>
                          ) : null}
                        </div>
                        <div
                          className={` justify-end  modal-footer  flex flex-row items-center py-3 border-t-2 border-gray-200 pr-10`}
                        >
                        <span>
                          <div className="flex ">
                            {(userStore.isenterprise && sopProfileSelectionStore.current_sop_selection_step_num > 0) ? (
                              <>
                                <button
                                  className={classNames([
                                    'border b-gray-500 uppercase rounded-2xl mr-4 h-10 w-30 text-[14px] px-8 font-bold flex items-center justify-center',
                                    ' bg-[#A033FF] text-white cursor-pointer'
                                  ])}
                                  onClick={() => {
                                    sopProfileSelectionStore.sop_selection_progress_nav_previous();
                                  }}
                                >Previous
                                </button>
                              </>
                            ) : null}

                            {
                              <button
                                className={classNames([
                                  'border b-gray-500 uppercase rounded-2xl h-10 w-30 text-[14px] px-8 font-bold flex items-center justify-center',
                                  isStepperDisabled() ?
                                    ' bg-gray-400 text-white cursor-not-allowed disabled'
                                    :
                                    ' bg-[#A033FF] text-white cursor-pointer'
                                ])}
                                disabled={isStepperDisabled()}
                                onClick={async () => {
                                  console.log('save1');
                                  switch (sopProfileSelectionStore.current_sop_selection_step_num) {

                                    case 0:
                                      sopProfileSelectionStore.sop_selection_progress_nav_next();
                                      break;

                                    case 1:
                                      sopProfileSelectionStore.setSopSelectionContinueClicked(true);
                                      if (sopProfileSelectionStore.current_sop_name !== '') {
                                        sopProfileSelectionStore.checkUser();
                                        sopProfileSelectionStore.hide_sop_selection_creation_modal();
                                        sopProfileSelectionStore.resetState();
                                      } 
                                      else if (sopProfileSelectionStore.sop_selected !== null) {
                                        const id = sopProfileSelectionStore.sop_selected.id;
                                        const sop = sopProfileSelectionStore.get_selected_sop_obj_by_id(id);
                                        console.log(sop,"SOPSOFSOP")
                                        if (sop.isEmpty) {
                                          if (sopProfileSelectionStore.activate_tool_id == 152) {
                                            console.log("ITEMSOFSOP");
                                            sopStore.library_sop_state();
                                            await sopStore.get_sop_workspace(sop.id, sop.parentid);
                                            mimick_library_item_click(sop);
                                          }

                                          if (sopProfileSelectionStore.activate_tool_id == 153) {
                                            //LOR
                                            uKSopStore.library_sop_state();
                                            uKSopStore.get_sop_workspace(sop.id, sop.parentid);
                                            mimick_library_item_click(sop);
                                          }
                                          if (sopProfileSelectionStore.activate_tool_id == 154) {
                                            //LOR
                                            canadaSopStore.library_sop_state();
                                            canadaSopStore.get_sop_workspace(sop.id, sop.parentid);
                                            mimick_library_item_click(sop);
                                          }
                                          if (sopProfileSelectionStore.activate_tool_id == 155) {
                                            //LOR
                                            sopStore.library_sop_state();
                                            sopStore.get_sop_workspace(sop.id, sop.parentid);
                                            mimick_library_item_click(sop);
                                          }
                                          if (sopProfileSelectionStore.activate_tool_id == 156) {
                                            //LOR
                                            germanyStore.library_sop_state();
                                            germanyStore.get_sop_workspace(sop.id, sop.parentid);
                                            mimick_library_item_click(sop);
                                          }
                                          /* else {
                                            //SOP
                                            sopStore.get_sop_workspace(sop.id, sop.parent_id);
                                          }
                                          */


                                          sopProfileSelectionStore.checkUser();
                                          sopProfileSelectionStore.resetState();
                                          sopProfileSelectionStore.hide_sop_selection_creation_modal();
                                        }
                                        else if (!sop.isEmpty&& sop.lastActiveStep === "Step 5") {
                                          if (sopProfileSelectionStore.activate_tool_id == 152) {
                                            sopStore.library_sop_state();
                                            await sopStore.get_sop_workspace(sop.id, sop.parentid);
                                            mimick_library_item_click(sop);
                                          }
    
                                          if (sopProfileSelectionStore.activate_tool_id == 153) {
                                            //LOR
                                            uKSopStore.library_sop_state();
                                            uKSopStore.get_sop_workspace(sop.id, sop.parentid);
                                            mimick_library_item_click(sop);
                                          }
                                          if (sopProfileSelectionStore.activate_tool_id == 154) {
                                            //LOR
                                            canadaSopStore.library_sop_state();
                                            canadaSopStore.get_sop_workspace(sop.id, sop.parentid);
                                            mimick_library_item_click(sop);
                                          }
                                          if (sopProfileSelectionStore.activate_tool_id == 155) {
                                            //LOR
                                            sopStore.library_sop_state();
                                            sopStore.get_sop_workspace(sop.id, sop.parentid);
                                            mimick_library_item_click(sop);
                                          }
                                          if (sopProfileSelectionStore.activate_tool_id == 156) {
                                            //LOR
                                            germanyStore.library_sop_state();
                                            germanyStore.get_sop_workspace(sop.id, sop.parentid);
                                            mimick_library_item_click(sop);
                                          }
                                          /* else {
                                            //SOP
                                            sopStore.get_sop_workspace(sop.id, sop.parent_id);
                                          }
                                          */
    
    
                                          sopProfileSelectionStore.checkUser();
                                          sopProfileSelectionStore.resetState();
                                          sopProfileSelectionStore.hide_sop_selection_creation_modal();
                                        } 
                                        else {
                                          //SOP has already been generated, directly go to the URL of generated SOP
                                          console.log(sop,"SOPSOFSOP")
                                          const url = getUrl(sopProfileSelectionStore.activate_tool_id);
                                          console.log(url);
                                          const parentid = sopProfileSelectionStore.sop_selected.parentid;
                                          console.log(parentid);
                                          navigate(`${url}/${id}/${parentid}`);
                                        }
                                      }
                                      break;

                                    default:
                                      break;
                                  }


                                  // if (
                                  //   sopProfileSelectionStore.current_sop_selection_step_num ===
                                  //     0
                                  // ) {
                                  //   sopStore.breadCrumbs.push({
                                  //     name: sopProfileSelectionStore.current_sop_name,
                                  //     isfile: true,
                                  //   });
                                  //   sopStore.updateSopName(
                                  //     sopProfileSelectionStore.current_sop_name
                                  //   );
                                  // }
                                  // if (
                                  //   (sopProfileSelectionStore.current_sop_selection_step_num ===
                                  //     0 &&
                                  //     ((sopProfileSelectionStore.sopProfileSelection &&
                                  //       sopProfileSelectionStore.sopProfileSelection.value !==
                                  //         undefined) ||
                                  //       sopProfileSelectionStore.sopProfileSelection != null)) ||
                                  //   (sopProfileSelectionStore.current_sop_selection_step_num ===
                                  //     0 &&
                                  //     sopProfileSelectionStore.current_sop_name !== '')
                                  // ) {
                                  //   sopProfileSelectionStore.setSopSelectioNavigate(true);
                                  // }

                                  /*
                                  // To set state of continue button
                                  sopProfileSelectionStore.setSopSelectionContinueClicked(true);
                                  if (sopProfileSelectionStore.current_sop_name !== '') {
                                    sopProfileSelectionStore.checkUser();
                                    sopProfileSelectionStore.hide_sop_selection_creation_modal();
                                    sopProfileSelectionStore.resetState();
                                  }*/

                                  // To call next nav when we click the continue button of when we selected the upload resume card in that after uploading the resume
                                  // else if (sopProfileSelectionStore.sopSelectionNavigate) {
                                  //   sopProfileSelectionStore.sop_selection_progress_nav_next();
                                  // }
                                }}
                              >
                                {/* To set the continue button for add resume screen and save next button for cambri resume steps screens  */}
                                {sopProfileSelectionStore.current_sop_selection_step_num == 0
                                  ? 'CONTINUE'
                                  : 'SAVE'}
                              </button>
                            }
                          </div>
                        </span>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        ) : 
        !userStore.isenterprise &&
        sopProfileSelectionStore.sopProfileSelectionValues.length <= 0 ? (
          <Transition.Root
            show={sopProfileSelectionStore.showSopSelectionCreationModal}
            as={Fragment}
          >
            <Dialog as="div" className="relative z-50" onClose={() => {
            }}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
              </Transition.Child>
              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel
                      className="flex relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-sm "
                      style={{
                        minWidth: 800,
                        maxHeight: 525,
                        maxWidth: 800,
                        width: 800,
                        height: 525
                      }}
                    >
                      <div className="resume-modal flex flex-col grow">
                        <div className="modal-header flex flex-row w-full items-center py-5 pr-[6rem] pl-[4.6rem] ">
                          <div
                            className=''
                            style={{
                              fontWeight: 500,
                              fontSize: '24px',
                              lineHeight: '32px',
                              color: '#3C424C'
                            }}
                          >
                            {lorStore.isLor
                              ? 'Select user for writing their LOR'
                              : sopProfileSelectionStore.current_sop_selection_step['name']}
                          </div>

                          <span className="pl-[16.5rem]">
                          <div className="cursor-pointer" onClick={() => {
                            navigate('/dashboard');
                            dashboardStore.setScreenName('Tools');
                          }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 100 100"
                            >
                              <line
                                x1="10"
                                y1="10"
                                x2="90"
                                y2="90"
                                stroke="black"
                                strokeWidth="5"
                              />
                              <line
                                x1="10"
                                y1="90"
                                x2="90"
                                y2="10"
                                stroke="black"
                                strokeWidth="5"
                              />
                            </svg>
                          </div>
                        </span>
                        </div>
                        <div className="modal-stepper h-0">
                          <nav aria-label="Progress">
                            <ol role="list" className="space-y-4 md:flex md:space-x-1 md:space-y-0">
                              {Array.from(Array(pre), (e, i) => {
                                return (
                                  <li className="md:flex-1">
                                    <a className="group flex flex-col border-l-4 border-[#5EAEFF] py-2 pl-4 hover:border-indigo-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"/>
                                  </li>
                                );
                              })}
                              <li className="md:flex-1">
                                <a
                                  className="flex flex-col border-l-4 border-[#5EAEFF] py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                                  aria-current="step"
                                />
                              </li>
                              {Array.from(Array(post), (e, i) => {
                                return (
                                  <li className="md:flex-1">
                                    <a className="group flex flex-col border-l-4 border-gray-500 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"/>
                                  </li>
                                );
                              })}
                            </ol>
                          </nav>
                        </div>
                        <div
                          className={`flex flex-col justify-center items-center modal-body grow`}
                          style={{ minHeight: 385, maxHeight: 385, width: 800, height: 385 }}
                        >
                          {sopProfileSelectionStore.current_sop_selection_step_num == 0 ? (
                            <SopProfileSelectionStepperComponent/>
                          ) : null}
                          {sopProfileSelectionStore.current_sop_selection_step_num == 1 ? (
                            <SopName/>
                          ) : null}
                        </div>
                        <div
                          className={` justify-around  modal-footer  flex flex-row items-center py-3 border-t-2 border-gray-200 `}
                        >
                          {/* <span className="mr-0">
                          <div>
                            <button
                              className={` uppercase  rounded-lg px-8 py-3 text-center text-[14px] leading-[18px]  border border-solid border-[#737E93] rounded-md p-4 m-[-4px] w-[150px]  ${
                                sopProfileSelectionStore.current_sop_selection_step_num === 0
                                  ? 'bg-[#F8F9FA] cursor-not-allowed text-[#737E93] h-10 w-24 rounded-[4px]  flex items-center justify-center'
                                  : sopProfileSelectionStore.current_sop_selection_step_num === 1
                                  ? 'border  h-10 w-30 rounded-[4px] text-purple-600 border-purple-500 bg-purple-50 cursor-pointer flex items-center justify-center'
                                  : 'border  h-10 w-30 rounded-[4px] text-purple-600 border-purple-500 bg-purple-50 cursor-pointer flex items-center justify-center'
                              }`}
                              disabled={
                                sopProfileSelectionStore.current_sop_selection_step_num === 0
                              }
                              onClick={() => {
                                if (sopProfileSelectionStore.current_sop_selection_step_num === 1) {
                                  sopProfileSelectionStore.sop_selection_progress_nav_previous();
                                  sopProfileSelectionStore.setSopSelectionContinueClicked(false);
                                  sopProfileSelectionStore.setSopSelectionContinueButton(false);
                                }
                              }}
                            >
                              Back
                            </button>
                          </div>
                        </span> */}
                          <span className="ml-[36rem]">
                          <div>
                            {userStore.isenterprise &&
                            <button
                              className={`uppercase text-white rounded-lg px-8 py-3 text-center text-[14px] leading-[18px] m-[-4px] w-[150px]   ${
                                (sopProfileSelectionStore.current_sop_selection_step_num === 0 &&
                                  (sopProfileSelectionStore.sopProfileSelection == null ||
                                    sopProfileSelectionStore.sopProfileSelection.profile_id ===
                                    undefined)) ||
                                (sopProfileSelectionStore.current_sop_selection_step_num === 1 &&
                                  sopProfileSelectionStore.sopNameInput === '')
                                  ? ' border h-10 w-30 rounded-[4px]  flex items-center justify-center bg-[#737E93] cursor-not-allowed'
                                  : 'border h-10 rounded-[4px]  flex items-center justify-center bg-[#A033FF] text-white '
                              }`}
                              disabled={
                                sopProfileSelectionStore.current_sop_selection_step_num === 0 &&
                                sopProfileSelectionStore.sopNameInput === ''
                              }
                              onClick={async () => {
                                // To set state of continue button
                                sopProfileSelectionStore.setSopSelectionContinueClicked(true);
                                if (
                                  sopProfileSelectionStore.sop_selection_progress_is_last_step()
                                ) {
                                  sopStore.breadCrumbs.push({
                                    name: sopProfileSelectionStore.current_sop_name,
                                    isfile: true
                                  });
                                  sopStore.updateSopName(
                                    sopProfileSelectionStore.current_sop_name
                                  );
                                }

                                if (sopProfileSelectionStore.current_sop_name !== '') {
                                  sopProfileSelectionStore.checkUser();
                                  sopProfileSelectionStore.hide_sop_selection_creation_modal();
                                  sopProfileSelectionStore.resetState();
                                }
                                // To call next nav when we click the continue button of when we selected the upload resume card in that after uploading the resume
                                else if (sopProfileSelectionStore.sopSelectionNavigate) {
                                  sopProfileSelectionStore.sop_selection_progress_nav_next();
                                }
                              }}
                            >
                              {/* To set the continue button for add resume screen and save next button for cambri resume steps screens  */}
                              {sopProfileSelectionStore.current_sop_selection_step_num == 0
                                ? 'CONTINUE'
                                : 'SAVE'}
                            </button>
                            }
                          </div>
                        </span>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        ) :
         (!userStore.isenterprise  ) && (
          <Transition.Root
            show={sopProfileSelectionStore.showSopSelectionCreationModal}
            as={Fragment}
          >
            <Dialog as="div" className="relative z-50" onClose={() => {
            }}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel
                      className="flex relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-sm "
                      style={{
                        minWidth: 800,
                        maxHeight: 525,
                        maxWidth: 800,
                        width: 800,
                        height: 525
                      }}
                    >
                      <div className="resume-modal flex flex-col grow">
                        <div className="modal-header flex flex-row w-full items-center py-5 ">
                          <div
                            className={`${lorStore.isLor ? 'pl-[9.5rem]' : 'pl-[15.5rem]'}`}
                            style={{
                              fontWeight: 500,
                              fontSize: '24px',
                              lineHeight: '32px',
                              color: '#3C424C'
                            }}
                          >
                            {lorStore.isLor
                              ? 'Select user for writing their LOR'
                              : userStore.isenterprise ? sopProfileSelectionStore.current_sop_selection_step['name'] : 'Name of SOP/LOR'}
                          </div>

                          <span className="pl-[16.5rem]">
                          <div className="cursor-pointer" onClick={() => {
                            navigate('/dashboard');
                            dashboardStore.setScreenName('Tools');
                          }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 100 100"
                            >
                              <line
                                x1="10"
                                y1="10"
                                x2="90"
                                y2="90"
                                stroke="black"
                                strokeWidth="5"
                              />
                              <line
                                x1="10"
                                y1="90"
                                x2="90"
                                y2="10"
                                stroke="black"
                                strokeWidth="5"
                              />
                            </svg>
                          </div>
                        </span>
                        </div>
                        <div className="modal-stepper h-0">
                          <nav aria-label="Progress">
                            <ol role="list" className="space-y-4 md:flex md:space-x-1 md:space-y-0">
                              {Array.from(Array(pre), (e, i) => {
                                return (
                                  <li className="md:flex-1">
                                    <a className="group flex flex-col border-l-4 border-[#5EAEFF] py-2 pl-4 hover:border-indigo-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"/>
                                  </li>
                                );
                              })}

                              <li className="md:flex-1">
                                <a
                                  className="flex flex-col border-l-4 border-[#5EAEFF] py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                                  aria-current="step"
                                />
                              </li>
                              {Array.from(Array(post), (e, i) => {
                                return (
                                  <li className="md:flex-1">
                                    <a className="group flex flex-col border-l-4 border-gray-500 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"/>
                                  </li>
                                );
                              })}
                            </ol>
                          </nav>
                        </div>
                        <div
                          className={`flex flex-col justify-center items-center modal-body grow`}
                          style={{ minHeight: 385, maxHeight: 385, width: 800, height: 385 }}
                        >
                          {sopProfileSelectionStore.current_sop_selection_step_num == 0 ? (
                            <SOPNameStepperComponent/>
                          ) : null}
                        </div>
                        <div
                          className={` justify-around  modal-footer  flex flex-row items-center py-3 border-t-2 border-gray-200 `}
                        >
                          <span className="ml-[36rem]">
                          <div>
                            {
                              <button
                                className={classNames([
                                  'border b-gray-500 uppercase rounded-2xl h-10 w-30 text-[14px] px-8 font-bold flex items-center justify-center',
                                  isStepperDisabled() ?
                                    ' bg-gray-400 text-white cursor-not-allowed disabled'
                                    :
                                    ' bg-[#A033FF] text-white cursor-pointer'
                                ])}
                                disabled={isStepperDisabled()}
                                onClick={async () => {
                                  /* NEW CODE  - NO NEED TO DUPLICATE THIS. CAN REFACTOR BETTER IN FUTURE */

                                  if (sopProfileSelectionStore.current_sop_name !== '') {
                                    sopProfileSelectionStore.checkUser();
                                    sopProfileSelectionStore.hide_sop_selection_creation_modal();
                                    sopProfileSelectionStore.resetState();
                                  } else if (sopProfileSelectionStore.sop_selected !== null) {
                                    console.log("USANAME");
                                    const id = sopProfileSelectionStore.sop_selected.id;
                                    const sop = sopProfileSelectionStore.get_selected_sop_obj_by_id(id);
                                    console.log(toJS(sop),"SOPOFSOP")
                                    if (sop.isEmpty) {
                                      if (sopProfileSelectionStore.activate_tool_id == 152) {
                                        sopStore.library_sop_state();
                                        await sopStore.get_sop_workspace(sop.id, sop.parentid);
                                        mimick_library_item_click(sop);
                                      }

                                      if (sopProfileSelectionStore.activate_tool_id == 153) {
                                        //LOR
                                        uKSopStore.library_sop_state();
                                        uKSopStore.get_sop_workspace(sop.id, sop.parentid);
                                        mimick_library_item_click(sop);
                                      }
                                      if (sopProfileSelectionStore.activate_tool_id == 154) {
                                        //LOR
                                        canadaSopStore.library_sop_state();
                                        canadaSopStore.get_sop_workspace(sop.id, sop.parentid);
                                        mimick_library_item_click(sop);
                                      }
                                      if (sopProfileSelectionStore.activate_tool_id == 155) {
                                        //LOR
                                        sopStore.library_sop_state();
                                        sopStore.get_sop_workspace(sop.id, sop.parentid);
                                        mimick_library_item_click(sop);
                                      }
                                      if (sopProfileSelectionStore.activate_tool_id == 156) {
                                        //LOR
                                        germanyStore.library_sop_state();
                                        germanyStore.get_sop_workspace(sop.id, sop.parentid);
                                        mimick_library_item_click(sop);
                                      }
                                       sopProfileSelectionStore.checkUser();
                                      sopProfileSelectionStore.resetState();
                                      sopProfileSelectionStore.hide_sop_selection_creation_modal();
                                    } 
                                    else if (!sop.isEmpty&& sop.lastActiveStep === "Step 5") {
                                      if (sopProfileSelectionStore.activate_tool_id == 152) {
                                        sopStore.library_sop_state();
                                        await sopStore.get_sop_workspace(sop.id, sop.parentid);
                                        mimick_library_item_click(sop);
                                      }

                                      if (sopProfileSelectionStore.activate_tool_id == 153) {
                                        //LOR
                                        uKSopStore.library_sop_state();
                                        uKSopStore.get_sop_workspace(sop.id, sop.parentid);
                                        mimick_library_item_click(sop);
                                      }
                                      if (sopProfileSelectionStore.activate_tool_id == 154) {
                                        //LOR
                                        canadaSopStore.library_sop_state();
                                        canadaSopStore.get_sop_workspace(sop.id, sop.parentid);
                                        mimick_library_item_click(sop);
                                      }
                                      if (sopProfileSelectionStore.activate_tool_id == 155) {
                                        //LOR
                                        sopStore.library_sop_state();
                                        sopStore.get_sop_workspace(sop.id, sop.parentid);
                                        mimick_library_item_click(sop);
                                      }
                                      if (sopProfileSelectionStore.activate_tool_id == 156) {
                                        //LOR
                                        germanyStore.library_sop_state();
                                        germanyStore.get_sop_workspace(sop.id, sop.parentid);
                                        mimick_library_item_click(sop);
                                      }

                                      sopProfileSelectionStore.checkUser();
                                      sopProfileSelectionStore.resetState();
                                      sopProfileSelectionStore.hide_sop_selection_creation_modal();
                                    } 
                                    else {
                                      //SOP has already been generated, directly go to the URL of generated SOP
                                      console.log("NAVIGATION");
                                      const url = getUrl(sopProfileSelectionStore.activate_tool_id);
                                      console.log(url);
                                      const parentid = sopProfileSelectionStore.sop_selected.parentid;
                                      console.log(parentid);
                                      navigate(`${url}/${id}/${parentid}`);
                                    }
                                  }
                                }}
                              >
                                {/* To set the continue button for add resume screen and save next button for cambri resume steps screens  */}
                                {sopProfileSelectionStore.current_sop_selection_step_num == 0
                                  ? 'CONTINUE'
                                  : 'SAVE'}
                              </button>
                            }
                          </div>
                        </span>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        )}
      </>
    );
  })
;
