import { observer } from 'mobx-react-lite'
import './index.css'
const Loader = observer((props:any) => {
  return (
    <div className="spinner">
    </div>
  )
})

export default Loader
