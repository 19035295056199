import { useEffect, useState } from 'react';
import Cards from '../../../../components/card/cards';
import { useStore } from '../../../../hooks/use-store';
import { observer } from 'mobx-react-lite';
import ContentEditableTextArea from '../../../tools/editable-textarea/editable-textarea';
import { emitObject } from '../../../../pages/tools/interfaces/interfaces';
import { SideOverlay } from '../../../../side-overlay';
import FetchExamples from '../../../sop-components/fetch_examples/fetch_examples';
import EditPopover from '../../final-output-screen-components/edit_popover';
import FetchTemplates from '../../../sop-components/fetch_templates/fetch_templates';
import { useParams } from 'react-router';

const Motivation = observer((props: any) => {
  const { sopStore, newContentStore } = useStore();
  const [motivation, setMotivation] = useState({});
  const [infoIconClicked, setInfoIconClicked] = useState(false);
  const regex = /enter your text/;
  const {id} = useParams()

  useEffect(() => {
    if(id=='155'){
      sopStore.getTemplates({
        tool_id: 155,
        screen: 'S2',
        fileid:sopStore.sopId!=155 ? sopStore.sopId : 0
      });
      sopStore.getExamples({
        tool_id: 155,
        screen: 'S2',
      });
    }
    else{
      sopStore.getTemplates({
        tool_id: 152,
        screen: 'S2',
        fileid:sopStore.sopId!=152 ? sopStore.sopId : 0
      });
      sopStore.getExamples({
        tool_id: 152,
        screen: 'S2',
      });
    }
  }, []);

  const onTemplateClicked = (question, slot) => {
    sopStore.setSlot(slot);
    sopStore.set_selected_textarea(question, '', '');
    sopStore.toggle_show_template();
  };

  const onBlurTextArea = (textAreaEvent: emitObject) => {
    if (sopStore.final_output_screen) {
      setMotivation({ ...motivation, [textAreaEvent.key]: { value: textAreaEvent.value } });
    } else {
      sopStore.updateSopJsonObj([textAreaEvent.key], textAreaEvent.value);
    }
  };

  const template_close_handler = () => {
    sopStore.toggle_show_template();
  };

  const onEditClicked = (slot) => {
    sopStore.getTemplates({
      tool_id: 152,
      screen: 'S2',
      fileid:sopStore.sopId!=152?sopStore.sopId:0
    });
    sopStore.getExamples({
      tool_id: 152,
      screen: 'S2',
    });
    const temp = sopStore.sopjsonData[slot];
    setMotivation({ ...motivation, [slot]: temp });
    sopStore.setSlot(slot);
    sopStore.setEditClicked(true);
  };

  const handleClickUpdate = (slot, value) => {
    sopStore.updateSopJsonData(slot, value);
    sopStore.update_sop_workspace(newContentStore.activeFolder, sopStore.sopId);
  };

  const templatesAndExamplesLength = () => {
    if (infoIconClicked) {
      if (
        sopStore.examplesForScreen &&
        sopStore.examplesForScreen[sopStore.selected_text_area.name].length > 0
      ) {
        return sopStore.examplesForScreen[sopStore.selected_text_area.name].length;
      }
    } else {
      if (
        sopStore.templatesForScreen?.[sopStore.selected_text_area.name] &&
        sopStore.templatesForScreen[sopStore.selected_text_area.name].length > 0
      ) {
        return sopStore.templatesForScreen[sopStore.selected_text_area.name].length;
      } else return 0;
    }
  };

  useEffect(() => {
    return () => {
      // Clear the selected card state when the component unmounts
      sopStore.updateCardSelected(false);
      sopStore.update_selected_card(-1);
      sopStore.updateCardDescription('');
    };
  }, []);
  

  return (
    <div className="py-2"  >
      {sopStore.cardSelected || sopStore.final_output_screen ? (
        <>
          <div className="flex flex-col justify-center relative">
            <ContentEditableTextArea
              id={sopStore.selectedCard}
              placeholder={sopStore.templateExists(sopStore.selectedCardDescription) ? 'Write your content here or use our template':"Write your content here"}
              onTemplateClicked={() =>
                onTemplateClicked(sopStore.selectedCardDescription, 'motivation')
              }
              setInfoIconClicked={setInfoIconClicked}
              onBlur={onBlurTextArea}
              htmlText={
                sopStore.sopjsonData?.['motivation']?.['key'] &&
                localStorage.getItem('selectedCard') == sopStore.sopjsonData?.['motivation']?.['key']
                  ? sopStore.sopjsonData?.['motivation']?.['value']
                  : ''
              }
              heightInRem={sopStore.final_output_screen ? 2 : 8}
              display={
                sopStore.selectedCardDescription
                  ? sopStore.selectedCardDescription
                  : sopStore.sopjsonData['motivation']['cardTitle']
              }
              slot={'motivation'}
              regex={regex}
              finalOutputScreen={sopStore.final_output_screen}
              onEditClicked={onEditClicked}
              showpencilIcon={true}
              showTemplateIcon={
                sopStore.final_output_screen
                  ? false
                  : true && sopStore.templateExists(sopStore.selectedCardDescription)
              }
              showInfoIcon={
                sopStore.final_output_screen
                  ? false
                  : true && sopStore.exampleExists(sopStore.selectedCardDescription)
              }
              cardSelected={() => sopStore.updateCardSelected(false)}
              editClicked={false}
            />

            {sopStore.slot == 'motivation' && (
              <EditPopover
                sopStore={sopStore}
                handleCancel={() => {}}
                handleClickUpdate={() =>
                  handleClickUpdate('motivation', motivation?.['motivation']?.['value'])
                }
              >
                <ContentEditableTextArea
                  id={sopStore.selectedCard}
                  placeholder={'Write your content here or use our template'}
                  onTemplateClicked={() =>
                    onTemplateClicked(
                      sopStore.selectedCardDescription
                        ? sopStore.selectedCardDescription
                        : sopStore.sopjsonData?.motivation?.['cardTitle'],
                      'motivation'
                    )
                  }
                  finalOutputScreen={sopStore.final_output_screen}
                  setInfoIconClicked={setInfoIconClicked}
                  onBlur={onBlurTextArea}
                  htmlText={motivation?.['motivation']?.['value']}
                  heightInRem={sopStore.final_output_screen ? 2 : 8}
                  display={
                    sopStore.selectedCardDescription
                      ? sopStore.selectedCardDescription
                      : sopStore.sopjsonData['motivation']['cardTitle']
                  }
                  slot={'motivation'}
                  regex={regex}
                  onEditClicked={onEditClicked}
                  showTemplateIcon={sopStore.templateExists(
                    sopStore.selectedCardDescription
                      ? sopStore.selectedCardDescription
                      : sopStore.sopjsonData['motivation']['cardTitle']
                  )}
                  showInfoIcon={
                    sopStore.final_output_screen
                      ? false
                      : true && sopStore.exampleExists(sopStore.selectedCardDescription)
                  }
                  // showInfoIcon={true}
                  showpencilIcon={false}
                  editClicked={true}
                />
              </EditPopover>
            )}
          </div>
          <SideOverlay
            children={
              <div>
                {infoIconClicked ? (
                  <FetchExamples infoIconClicked={infoIconClicked} sopStore={sopStore} />
                ) : (
                  <FetchTemplates state={motivation} setState={setMotivation} sopStore={sopStore}
                  />
                )}
              </div>
            }
            onClose={template_close_handler}
            show={sopStore.showTemplate}
            title={infoIconClicked ? 'Examples' : 'Templates'}
            length={templatesAndExamplesLength()}
          />
        </>
      ) : (
        <Cards />
      )}
    </div>
  );
});

export default Motivation;
