import { ContentApi } from '../api/content-api';
import { action, makeAutoObservable } from 'mobx';
import { safe_get } from '../pages/utils';
import { UserApi } from '../api/user-api';

const b2b_selection_progress_stepper_states = [
  {
    num: 0,
    key: 'userType',
    name: 'Please specify the user type',
  },
  {
    num: 1,
    key: 'finalise',
    name: 'Confirm the user type',
  },
];

export class B2bStore {
  current_b2b_selection_step_num: number = b2b_selection_progress_stepper_states[0]['num'];
  current_b2b_selection_step: any = b2b_selection_progress_stepper_states[0];

  showB2bSelectionCreationModal: boolean = false;
  b2bSelectionContinueClicked: boolean = false;
  b2bSelectionContinueButton: boolean = true;
  b2bSelectionBackButton: boolean = true;
  b2bSelectionNavigate: boolean = false;

  isB2bUser: boolean = false;
  selectedCard: string = '';
  b2bSwitch: boolean = false;
  constructor(private UserApi: UserApi) {
    makeAutoObservable(this);
  }
  @action
  setB2b(bool) {
    this.isB2bUser = bool;
  }
  @action setSelectedCard(cardName) {
    this.selectedCard = cardName;
  }

  @action setB2bSwitch(bool) {
    this.b2bSwitch = bool;
  }
  @action set_current_step(value, name) {
    this.current_b2b_selection_step_num = value;
    this.current_b2b_selection_step['num'] = value;
    this.current_b2b_selection_step['name'] = name;
  }

  @action
  setB2bSelectioNavigate(value) {
    this.b2bSelectionNavigate = value;
  }

  @action
  setB2bSelectionContinueClicked(value) {
    this.b2bSelectionContinueClicked = value;
  }
  @action
  setB2bSelectionContinueButton(value) {
    this.b2bSelectionContinueButton = value;
  }
  @action
  setB2bSelectionBackButton(value) {
    this.b2bSelectionBackButton = value;
  }

  @action
  show_b2b_selection_creation_modal() {
    this.showB2bSelectionCreationModal = true;
  }

  @action
  hide_b2b_selection_creation_modal() {
    this.showB2bSelectionCreationModal = false;
  }

  @action
  b2b_selection_progress_nav_next() {
    const new_idx = this.current_b2b_selection_step_num + 1;
    if (new_idx >= b2b_selection_progress_stepper_states.length) return;
    this.current_b2b_selection_step_num = new_idx;
    this.current_b2b_selection_step = b2b_selection_progress_stepper_states[new_idx];
  }

  @action
  b2b_selection_progress_nav_previous() {
    const new_idx = this.current_b2b_selection_step_num - 1;
    if (new_idx < 0) return;
    this.current_b2b_selection_step_num = new_idx;
    this.current_b2b_selection_step = b2b_selection_progress_stepper_states[new_idx];
  }

  @action
  b2b_selection_progress_is_last_step() {
    return this.current_b2b_selection_step_num === b2b_selection_progress_stepper_states.length - 1;
  }

  @action
  b2b_selection_progress_get_current_state() {
    return `${this.current_b2b_selection_step_num + 1}/${
      b2b_selection_progress_stepper_states.length
    }`;
  }

  @action
  b2b_selection_progress_get_previous_state() {
    return `${this.current_b2b_selection_step_num - 1}/${
      b2b_selection_progress_stepper_states.length
    }`;
  }

  @action
  b2b_selection_progress_stepper_status() {
    const pre = this.current_b2b_selection_step_num;
    const post =
      b2b_selection_progress_stepper_states.length - this.current_b2b_selection_step_num - 1;
    return { pre, post };
  }

  @action
  resetState() {
    this.showB2bSelectionCreationModal = false;
    this.current_b2b_selection_step_num = b2b_selection_progress_stepper_states[0]['num'];
    this.current_b2b_selection_step = b2b_selection_progress_stepper_states[0];
    this.b2bSelectionContinueClicked = false;
    this.b2bSelectionNavigate = false;
    this.b2bSelectionContinueButton = true;
    this.b2bSelectionBackButton = true;
    this.selectedCard = '';
  }
}
