import apiSauce from "./api-sauce";

export class ReportsApi {

    async getReports(): Promise<any> {
        return await apiSauce.apisauce.get("admin/reports")
    }
    async userReports(payload): Promise<any>{
        return await apiSauce.apisauce.post("reports/user_usage",payload)
    }
  

}
