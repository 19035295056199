import React, { useEffect, useRef } from 'react';
import { useStore } from '../../hooks/use-store';
import { observer } from 'mobx-react-lite';
import Select, { OptionProps } from 'react-select';
import makeAnimated from 'react-select/animated';
import dayjs from 'dayjs';

const SopProfileSelectionStepperComponent = observer(() => {
  const { sopProfileSelectionStore, resumeStore, userProfileStore, userStore } = useStore();
  const animatedComponents = makeAnimated();

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menuList: (base) => ({
      ...base,
      color: 'black',
      'scrollbar-width': 'thin',
      'scrollbar-color': '#a473ce #e0bcff',
      '::-webkit-scrollbar': {
        width: '7px',
        height: '0px',
      },
      '::-webkit-scrollbar-track': {
        background: '#e0bcff',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#a473ce',
        height: '70px',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#a473ce' : provided.backgroundColor,
      color: state.isSelected ? 'white' : provided.color,
      '&:hover': {
        backgroundColor: '#e0bcff',
        color: 'black',
      },
    }),
    singleValue: (base) => ({
      ...base,
      color: 'black',
      padding: '5px',
      borderRadius: '5px',
    }),
  };

  const path = window.location.pathname;
  // @ts-ignore
  // @ts-ignore
  return (
    <>
      {userStore.isenterprise ? (
        <>
          <div className="text-[#A033FF] font-medium text-xl my-2 justify-left w-full px-8">
            Who is this for?
          </div>
          <div className="flex flex-row w-full px-8">
            <div className="flex w-[45%] justify-left">
              <div className="flex flex-col w-full">
                <div className="font-medium my-2">Select Existing Student</div>
                <div className="w-full">
                  <Select
                    className="basic-single w-[80%]"
                    classNamePrefix="select"
                    isClearable={true}
                    components={animatedComponents}
                    isSearchable={true}
                    options={sopProfileSelectionStore.getSelectValues()}
                    placeholder={'Pick student...'}
                    menuPortalTarget={document.body}
                    defaultValue={sopProfileSelectionStore.getSelectedUserProfileValueForSelectDropDownState()}
                    value={sopProfileSelectionStore.getSelectedUserProfileValueForSelectDropDownState()}
                    styles={customStyles}
                    onChange={(newval, meta) => {
                      if (newval === null) {
                        // Clear Scenario
                        sopProfileSelectionStore.select_student(-1);
                      } else {
                        // @ts-ignore
                        sopProfileSelectionStore.select_student(newval['value']);
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="flex w-[10%] justify-center items-center mt-8">
              <div className="font-bold">OR</div>
            </div>

            <div className="flex w-[45%] justify-end items-center mt-8">
              <div
                className="bg-[#A033FF] h-10 text-lg items-center flex justify-center px-4 font-bold text-white rounded-lg cursor-pointer"
                onClick={() => {
                  userProfileStore.userProfilejsonData =
                    userProfileStore.defaultUserProfileJsonData;
                  userProfileStore.profile_id = 0;
                  userProfileStore.profileScreen = false;
                  userProfileStore.setUserData = true;
                  resumeStore.show_resume_creation_modal();
                }}
              >
                Add New Student
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex w-[45%] justify-center items-center mt-8">
          <div
            className="bg-[#A033FF] h-10 text-lg items-center flex justify-center px-4 font-bold text-white rounded-lg cursor-pointer"
            onClick={() => {
              userProfileStore.userProfilejsonData = userProfileStore.defaultUserProfileJsonData;
              userProfileStore.profile_id = 0;
              userProfileStore.profileScreen = false;
              userProfileStore.setUserData = true;
              resumeStore.show_resume_creation_modal();
            }}
          >
            TELL US ABOUT YOU
          </div>
        </div>
      )}

    </>
  );
});

const SOPNameStepperComponent = observer(() => {
  const sop_selection_select_box = useRef(null);

  const { sopProfileSelectionStore, userProfileStore, userStore } = useStore();
  let name = sopProfileSelectionStore.sopProfileSelection
    ? sopProfileSelectionStore.sopProfileSelection.label
    : '';
  name = name.charAt(0).toUpperCase() + name.slice(1);

  useEffect(() => {
    sopProfileSelectionStore.reset_selected_sop();
    sopProfileSelectionStore.default_sop_name_generator();
  }, []);

  const Option = (props: OptionProps) => {
    const { children, innerRef, innerProps } = props;

    const sop = sopProfileSelectionStore.get_selected_sop_obj_by_name(children);

    return (
      <div
        ref={innerRef}
        {...innerProps}
        className="my-[2px] mx-[6px] flex flex-row cursor-pointer items-center hover:bg-yellow"
      >
        <div>
          {!sop.isEmpty ? (
            <div className="group relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#009400"
                className="w-6 h-6 group-hover:opacity-40"
              >
                <path
                  fillRule="evenodd"
                  d="M9 1.5H5.625c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5zm6.61 10.936a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 14.47a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                  clipRule="evenodd"
                />
                <path d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z" />
              </svg>
              <div className="opacity-0 group-hover:opacity-100 absolute bg-green-500 font-semibold text-white text-xs py-1 px-2 rounded-md bottom-[-0.5rem] left-[88%] transform -translate-x-1/2 transition duration-300">
                Generation Completed
              </div>
            </div>
          ) : (
            <div className="group relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#ffdd76"
                className="w-6 h-6 group-hover:opacity-40"
              >
                <path
                  fillRule="evenodd"
                  d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                  clipRule="evenodd"
                />
              </svg>
              <div className="opacity-0 group-hover:opacity-100 absolute bg-[#ffd043] font-semibold text-white text-xs py-1 px-2 rounded-md bottom-[-0.5rem] left-[88%] transform -translate-x-1/2 transition duration-300">
                Generation In Progress
              </div>
            </div>
          )}
        </div>

        <div className="ml-[4px] text-sm">{children}</div>
      </div>
    );
  };
  useEffect(() => {
    const date = dayjs().format('DD-MM-YY hh_mm A');
    if (!userStore.isenterprise && userProfileStore.userProfilejsonData.user_name !== '') {
      if (window.location.pathname.includes('/sop')) {
        const name =
          userProfileStore.userProfilejsonData.user_name.charAt(0).toUpperCase() +
          userProfileStore.userProfilejsonData.user_name.slice(1);
        sopProfileSelectionStore.set_current_sop_name(`${name} - ${'SOP'} - ${date}`);
      }
    }
  }, [userStore.isenterprise, userProfileStore.userProfilejsonData.user_name]);
  const date = dayjs().format('DD-MM-YY hh_mm A');

  useEffect(()=>{
    const formatSopName = (name, countryName, date, path) => {
      let pathCountryName = 'SOP';
  
      console.log('Current path:', path); // Debugging statement
  
      if (path.includes('/sop')) {
        pathCountryName = 'SOP-USA';
      } else if (path.includes('/lor/151')) {
        pathCountryName = 'LOR';
      } else if (path.includes('/uksop/153')) {
        pathCountryName = 'SOP-UK';
      } else if (path.includes('/australiasop/155')) {
        pathCountryName = 'SOP-AUSTRALIA';
      } else if (path.includes('/canadasop/154')) {
        pathCountryName = 'SOP-CANADA';
      } else if (path.includes('/germanysop/156')) {
        pathCountryName = 'SOP-GERMANY';
      }
      return `${name}-${pathCountryName}-${date}`;
    };
    const sopName = formatSopName(
      name,
      sopProfileSelectionStore.countryName,
      date,
      window.location.pathname
    );
    if (sopName) {
      sopProfileSelectionStore.set_current_sop_name(sopName);
    }
  },[])
 

  return (
    <>
      <div className="my-2 mb-4 px-8 w-full justify-left font-medium">
        <div className="text-[#A033FF]  text-xl">Student Selected</div>
        <div className="text-gray-800 font-medium text-lg">
          {!userStore.isenterprise && userProfileStore.userProfilejsonData.user_name !== ''
            ? userProfileStore.userProfilejsonData.user_name
            : name}
        </div>
        {userStore.isenterprise ? (
          <div className="text-gray-400 italic">
            <sup>*</sup>Go to previous screen to change student profile
          </div>
        ) : null}
      </div>

      <div className="text-[#A033FF] font-medium text-xl justify-left w-full px-8">
        SOP/LOR Document
      </div>

      <div className="flex flex-row w-full px-8">
        <div className="flex w-[45%] justify-left">
          <div className="flex flex-col w-full">
            <div className="font-medium my-2">Select Existing Document</div>
            <div className="w-full ">
              <Select
                ref={sop_selection_select_box}
                components={{ Option }}
                className="basic-single w-[80%]"
                classNamePrefix="select"
                isClearable={true}
                isSearchable={true}
                options={sopProfileSelectionStore.getSelectedValues()}
                placeholder={'Pick sop (Continue from where you left off)'}
                menuPortalTarget={document.body}
                defaultValue={sopProfileSelectionStore.getSelectedSopForSelectDropDownState()}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menuList: (base) => ({
                    ...base,
                    'scrollbar-width': 'thin',
                    'scrollbar-color': '#a473ce #e0bcff',

                    '::-webkit-scrollbar': {
                      width: '7px',
                      height: '0px',
                    },
                    '::-webkit-scrollbar-track': {
                      background: '#e0bcff',
                    },
                    '::-webkit-scrollbar-thumb': {
                      background: '#a473ce',
                      height: '70px',
                    },

                    // "::-webkit-scrollbar-thumb:hover": {
                    //   background: "#555"
                    // }
                  }),
                }}
                onChange={(newval, meta) => {
                  if (meta.action === 'select-option') {
                    sopProfileSelectionStore.setClearSopStep(true);
                  }

                  if (newval === null) {
                    //Clear Scenario
                    sopProfileSelectionStore.select_sop(-1);
                  } else {
                    // @ts-ignore
                    sopProfileSelectionStore.select_sop(newval['value']);

                    if (sopProfileSelectionStore.sop_selected !== null) {
                      const id = sopProfileSelectionStore.sop_selected.id;
                      const parentid = sopProfileSelectionStore.sop_selected.parentid;
                      //navigate(`/sop/${id}/${parentid}`)
                    }
                  }

                  if (meta.action === 'clear') {
                    sopProfileSelectionStore.reset_selected_sop();
                    sopProfileSelectionStore.default_sop_name_generator();
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex w-[5%] justify-center items-center mt-8">
          <div className="font-bold">OR</div>
        </div>

        <div className="flex flex-col w-[45%] justify-right">
          <div className="font-medium my-2">Create New Document</div>

          <div className="">
            <input
              value={sopProfileSelectionStore.current_sop_name}
              onChange={(evt) => {
                sopProfileSelectionStore.reset_selected_sop();
                // @ts-ignore
                sop_selection_select_box.current?.setValue(null);
                sopProfileSelectionStore.set_current_sop_name(evt.target.value);
              }}
              type="text"
              name="sop_name"
              placeholder="Enter"
              className="border-2 w-full border-gray-300 focus:outline-none  h-10 rounded-xl py-2 px-2 bg-white text-black"
            />
          </div>
        </div>
      </div>
    </>
  );
});

export { SopProfileSelectionStepperComponent, SOPNameStepperComponent };
