import React from 'react';
import ContentEditable from 'react-contenteditable';
import sanitizeHtml from 'sanitize-html';
import './editable-textarea.css';
import { emitObject, emitObjectKey } from '../interfaces/interfaces';

import CalenderIcon from '../../../icons/calender.svg';
import PencilIcon from '../../../icons/pencil.svg';
import deleteIcon from '../../../icons/delete.svg';

import {
  Grammarly,
  GrammarlyEditorPlugin,
  GrammarlyEditorPluginProps,
} from '@grammarly/editor-sdk-react';


interface EditableTextareaProps extends React.PropsWithChildren<{}> {
  regex?: RegExp; // regex which you need to replace with p tag
  htmlText: string; // template html u need to bind
  heightInRem?: number; // to set height of the text area in rems min height is 100px
  display?: string; //  label text
  label?: string;
  placeholder: string; // placeholdet text
  slot: string;
  id?: any; // slot which you sent in html text
  eventEmit?: (event: emitObject) => void; // method that is emited every time you changed
  onBlur: (event: emitObject) => void; // method that is emited every time you blured
  onTemplateClicked?: (event: emitObjectKey) => void;
  setInfoIconClicked?: React.Dispatch<React.SetStateAction<boolean>>; // Define the type for the 'setCount' prop
  onChange?: (event: emitObject, string) => void; // method that is emited every time you blured
  finalOutputScreen?: boolean;
  required?: boolean;
  onEditClicked?: (event, string) => void;
  onDelete?: (event) => void;
  showpencilIcon?: boolean;
  showDeleteIcon?: boolean;
  showTemplateIcon?: boolean;
  showInfoIcon?: boolean;
  cardSelected?: (event) => void;
  editClicked?:boolean;
  onChanged?: (event: emitObject) => void;
}
type ContentEditableState = {
  html: string;
};

//s@observer
class ContentEditableTextArea extends React.Component<EditableTextareaProps, ContentEditableState> {
  //static contextType = StoreContext;
  constructor(props) {
    super(props);
  console.log(this.props,'FINALOUTPUT');

    this.state = {
      html: this.props.htmlText ? this.props.htmlText : ``,   
    };
  }

  sanitizeConf = {
    allowedTags: ['p'],
    allowedClasses: {
      p: ['insideInput'],
    },
    allowedAttributes: {
      p: ['contenteditable'],
    },
  };

  /*  set html text after htmlText get update*/


  componentDidUpdate(prevProps, prevState) {
    if (this.props.htmlText !== prevProps.htmlText) {
      this.setState({ html: this.props.htmlText }, () => {
        this.setUpdateHtml();
      });
    }
  }

  //  handleKeyDown(event) {
  //   // If any key other than the backspace or delete key is pressed
  //   if (event.keyCode !== 8 && event.keyCode !== 46) {
  //     event.preventDefault(); // Prevent default action (inserting new character)
  //   }
  // }

  // construct html if it have anything like  [regex expression]
  clean_part = (txt) => {
    if (txt.startsWith('[') && txt.endsWith(']')) {
      const disp = txt.substring(1, txt.length - 1).trim();
      return disp === '' ? 'Enter your text here' : disp;
    }
    return txt;
  };

  constructHtml = (): string => {
    const spiltedText = this.state?.html?.split(/[^\[\]]*?/);
    const replacedText = spiltedText?.map((part, index) => {
      if (part === '') {
        return null;
      }
      // else if(regex.test(part)){
      else if (part.startsWith('[') && part.endsWith(']')) {
        return `<p class="insideInput"> ${this.clean_part(part)} </p>`;
      } else {
        return part;
      }
    });

    return replacedText?.filter(Boolean).join('');
  };

  //    handleRef(ref) {
  //     if (ref) {
  //       const button = ref.querySelector('p');
  //       if (button) {
  //         button.addEventListener('keydown', this.handleKeyDown);
  //       }
  //     }
  //   }

  setUpdateHtml() {
    let htmlconstructed = this.constructHtml();
    this.setState({ html: sanitizeHtml(htmlconstructed, this.sanitizeConf) });
  }

  // set html text on component mount first time
  componentDidMount() {
    if (this.props.finalOutputScreen) {
      this.setState({
        html: this.formatHtmlWithLineBreaks(this.props.htmlText),
      });
    } else {
      this.setUpdateHtml();
    }
  }
  
  formatHtmlWithLineBreaks(html) {
    const maxCharactersPerLine = 38; // Change this to the desired max characters per line
  
    // Insert line breaks after every `maxCharactersPerLine` characters
    const formattedHtml = html?.replace(new RegExp(`(.{${maxCharactersPerLine}})`, 'g'), '$1\n');
    
    return formattedHtml;
  }
  

  // input chnage method
  handleChange = (evt) => {
 
    if (this.props.onChange) {
      this.props.onChange(evt, evt.target.value);
    }

    // Update the component's state with the new HTML content
    this.setState({ html: evt.target.value }, () => {
      // Trigger the onChanged prop if provided
      if (this.props.onChanged) {
        this.props.onChanged({ key: this.props.slot, value: this.state.html });
      }

      // Trigger the eventEmit prop if provided
      if (this.props.eventEmit) {
        this.props.eventEmit({ key: this.props.slot, value: this.state.html });
      }
    });
  
    if(this.props.finalOutputScreen){
          if (this.props.onChange) {
      this.props.onChange(evt, this.props.slot);
    }
  
    const inputValue = evt.target.value;
    const maxCharactersPerLine = 32;
  
    // Remove existing line breaks and empty lines
    const formattedValue = inputValue.replace(/[\r\n]+/g, '').replace(
      new RegExp(`(.{${maxCharactersPerLine}})`, 'g'),
      '$1\n'
    );
    this.setState({
      html: sanitizeHtml(formattedValue, this.sanitizeConf),
    }, () => {
      if (this.props.eventEmit) {
        this.props.eventEmit({ key: this.props.slot, value: this.state.html });
      }
    });
  }
  else{
    if (this.props.onChange) {
      this.props.onChange(evt, this.props.slot);
      this.setState({ html: sanitizeHtml(evt.target.value, this.sanitizeConf) });
      if (this.props.eventEmit) {
        this.props.eventEmit({ key: this.props.slot, value: this.state.html });
      }
    } else {
      this.setState({ html: sanitizeHtml(evt.target.value, this.sanitizeConf) });
      if (this.props.eventEmit) {
        this.props.eventEmit({ key: this.props.slot, value: this.state.html });
      }
    }
  }
  };
    // method to sanizite html

  sanitize = (event: any) => {
    let html = sanitizeHtml(this.state.html, this.sanitizeConf);
    this.props.onBlur({ key: this.props.slot, value: html, id: this.props.id });
    // this.setState({ html: sanitizeHtml(this.state.html, this.sanitizeConf) });
  };

  render = () => {
        return (
      <>
        <label className={`${ this.props.slot === 'motivation' && !this.props.display ? 'hidden' : 'block'} w-full text-[#4E5664] text-base py-2`}>
          <div className="flex justify-between">
            <div className="flex items-center">
              {this.props.slot === 'motivation' && !this.props.finalOutputScreen  ? (
                <svg
                  onClick={this.props.cardSelected}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  className="mr-3 cursor-pointer"
                >
                  <path
                    d="M21.0001 11.9994C21.0001 12.1984 20.9211 12.3891 20.7804 12.5298C20.6398 12.6704 20.449 12.7494 20.2501 12.7494H5.56041L11.0307 18.2188C11.1004 18.2885 11.1557 18.3712 11.1934 18.4623C11.2311 18.5533 11.2505 18.6509 11.2505 18.7494C11.2505 18.848 11.2311 18.9456 11.1934 19.0366C11.1557 19.1277 11.1004 19.2104 11.0307 19.2801C10.961 19.3497 10.8783 19.405 10.7873 19.4427C10.6962 19.4804 10.5986 19.4999 10.5001 19.4999C10.4016 19.4999 10.304 19.4804 10.2129 19.4427C10.1219 19.405 10.0392 19.3497 9.96948 19.2801L3.21948 12.5301C3.14974 12.4604 3.09443 12.3777 3.05668 12.2866C3.01894 12.1956 2.99951 12.098 2.99951 11.9994C2.99951 11.9009 3.01894 11.8033 3.05668 11.7122C3.09443 11.6212 3.14974 11.5385 3.21948 11.4688L9.96948 4.71882C10.1102 4.57808 10.3011 4.49902 10.5001 4.49902C10.6991 4.49902 10.89 4.57808 11.0307 4.71882C11.1715 4.85955 11.2505 5.05042 11.2505 5.24944C11.2505 5.44846 11.1715 5.63933 11.0307 5.78007L5.56041 11.2494H20.2501C20.449 11.2494 20.6398 11.3285 20.7804 11.4691C20.9211 11.6098 21.0001 11.8005 21.0001 11.9994Z"
                    fill="#4E5664"
                  />
                </svg>
              ) : null}
              <p
                className={` ${this.props.finalOutputScreen && 'text-base'} ${
                  this.props.slot === 'motivation' && 'text-2xl'
                } mr-2 `}
              >
                 {this.props.display}
            </p>
            {this.props.required && (
              <span className='text-red-500 text-sm'>*</span>
            )}
              {this.props.showInfoIcon && (
                <div className="flex group relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#A033FF"
                    className={`${this.props.finalOutputScreen && 'hidden'} w-6 h-6 cursor-pointer`}
                    onClick={() => {
                      this.props.setInfoIconClicked?.(true);
                      this.props.onTemplateClicked?.({ key: this.props.slot });
                    }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                    />
                  </svg>
                  <span
                    className="group-hover:opacity-100 w-32 z-50 bottom-0 px-4 py-2 transition-opacity bg-gray-800 text-sm text-gray-100 rounded-md absolute left-1/2 
-translate-x-1/2 translate-y-full opacity-0 mx-auto"
                  >
                    See Examples
                  </span>
                </div>
              )}
            </div>
            {this.props.showDeleteIcon && (
              <img
                onClick={() => this.props.onDelete?.(this.props.id)}
                src={deleteIcon}
                alt={'remove task'}
                className="cursor-pointer"
              />
            )}
            {this.props.showTemplateIcon && (
              <div
                className="group flex relative"
                onClick={() => {
                  this.props.setInfoIconClicked?.(false);
                  this.props.onTemplateClicked?.({ key: this.props.slot });
                }}
              >
                <img src={CalenderIcon} alt={'remove task'} className="cursor-pointer" />
                <span
                  className="group-hover:opacity-100 w-32 z-50 bottom-0 px-4 py-2 transition-opacity bg-gray-800 text-sm text-gray-100 rounded-md absolute left-1/2 
    -translate-x-1/2 translate-y-full opacity-0 mx-auto"
                >
                  See Templates
                </span>
              </div>
            )}
          </div>
        </label>
        <div
          className={`${
            this.props.slot === 'motivation' && !this.props.display ? 'hidden' : 'block'
          } flex justify-end relative py-2`}
        >
              <ContentEditable
                className={`cambri-contenteditable block ${
                  this.props.heightInRem
                    ? 'min-h-[' +
                      this.props.heightInRem +
                      'rem] max-h[' +
                      this.props.heightInRem +
                      'rem] '
                    : 'min-h-[100px] max-h-[100px]'
                } `}
                tagName="div"
                html={this.state.html? this.state.html: ""} // innerHTML of the editable div
                onChange={this.handleChange} // handle innerHTML change
                onBlur={(event) => {
                  this.sanitize(event);
                }}
                placeholder={this.props.placeholder}
                disabled={this.props.finalOutputScreen && !this.props.editClicked}
              ></ContentEditable>
          {this.props.finalOutputScreen && this.props.showpencilIcon && (
            <img
              className="absolute mr-[1.5rem] cursor-pointer mt-[10px]"
              onClick={() => this.props.onEditClicked?.(this.props.slot, this.props.id)}
              src={PencilIcon}
            />
          )}
        </div>
        {this.props.slot === 'motivation' && !this.props.display && (
            <p className='text-base'>You haven't selected any motivation </p>
          )}
      </>
    );
  };
}

export default ContentEditableTextArea;
