import * as React from 'react';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Stepper } from 'react-form-stepper';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $generateHtmlFromNodes } from '@lexical/html';
import { useStore } from '../../hooks/use-store';

const StepperProgressVisual = observer((props): JSX.Element => {
  const { editorStore, sopStore } = useStore();
  const [editor] = useLexicalComposerContext();
  const OpenApiStatus = localStorage.getItem('openApiStatus')
  useEffect(() => {

  }, [editorStore.stepper_states]);

  if (!editorStore.stepper_show_toggle) {
    return <></>;
  }

  if(!editorStore.stepper_show_toggle){
    return (
      <></>
    );
  }


  return (
    <>
      <div className='bg-white flex flex-row flex-grow w-[100%]' style={{ position: 'fixed', top: OpenApiStatus ==='true' ? '7rem':'5rem', width: '100%', zIndex: 1, borderBottom: '2px solid red' }}>
        <Stepper
          steps={editorStore.stepper_states}
          nonLinear={true}
          styleConfig={{
            'activeBgColor': '#F7DC6F',
            'activeTextColor': '#212F3D',
            'completedBgColor': '#27AE60',
            'completedTextColor': '#F0F3F4',
            'inactiveBgColor': '#e0e0e0',
            'inactiveTextColor': '#ffffff',
            'size': '2em',
            'circleFontSize': '1rem',
            'labelFontSize': '.95rem',
            'borderRadius': '50%',
            'fontWeight': 600
          }}
          style={{ fontFamily: 'Avenir' }}
          className={'w-[70%]'}
          onClick={(evt) => {
            // @ts-ignore
            switch (evt.target.outerText) {
              case '1':
                break;
              case '2':
                break;
              case '3':
                sopStore.toggle_show_plagiarism();
                break;
              case '4':
                const html_nodes = editor
                  .getEditorState()
                  .read(() => $generateHtmlFromNodes(editor, null))
                editorStore.export_as_word(editor, html_nodes)
                editorStore.stepper_update_export()
                break;
              default:
                break;

            }
          }}

        />
      </div>
    </>
  );
});

export default StepperProgressVisual;
