
import { useStore } from '../../hooks/use-store';
import { observer } from 'mobx-react-lite';
export const ConfirmB2b = observer((props: any) => {
  const {  b2bStore } = useStore();

  return (
      <div className="p-10 text-center">
        <h1 className="font-bold text-[40px] pt-1 pb-4">
          {b2bStore.selectedCard == 'business' ? 'Business Account' : 'Regular Account'}
        </h1>
        {b2bStore.selectedCard == 'business' ? (
          <p className="text-[20px] leading-[28px] text-[#4E5664] font-bold mb-2">
            Thank you for opting the business account.This account enables you to add multiple
            profiles and resumes.
          </p>
        ) : (
          <p className="text-[20px] leading-[28px] text-[#4E5664] font-bold mb-2">
            Thank you for opting for the student account. With this account type, you can add only
            one profile and one resume. However, you have the flexibility to change your account
            type from the "Your Profile" section within the application
          </p>
        )}
      </div>
  );
});
