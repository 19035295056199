import React from 'react';
import { observer } from 'mobx-react-lite';

const Card = observer((props: any) => {

  return (
    <div className="max-w-sm w-[283px] rounded-lg overflow-hidden bg-[#FEFEFEFE] border border-[#E3E5E9]">
      <div className="flex justify-between px-6">
        <img className='mt-[27px]' src={props.icon} />
        {props.showInfoIcon &&
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="#A033FF"
          className="w-6 h-6 mt-[22px]"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
          />
        </svg>
}
      </div>
      <div className="px-6 py-4">
        <div className="font-medium text-[#292D35] text-xl mb-2">{props.title}</div>
        <p className="text-[#737E93] text-sm">
         {props.description}
        </p>
      </div>
      <div className="px-6 pt-4 pb-5">
        <button
          onClick={props.onClick}
          type="button"
          className="bg-[#FCF3FF] rounded border border-[#A033FF] text-[#A033FF] font-black text-sm h-10 w-full"
        >
          SELECT
        </button>
      </div>
    </div>
  );
});

export default Card;
