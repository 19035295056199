import { identity } from "lodash";
import apiSauce from "./api-sauce";

export class ContentApi {

  async search_content(payload): Promise<any> {
    return await apiSauce.apisauce.post("library/search_contents", payload)
  }

  async get_content(payload): Promise<any> {
    return await apiSauce.apisauce.post("library/list_contents", payload)
  }

  async get_all_contents(payload): Promise<any> {
    return await apiSauce.apisauce.post("library/all_contents", payload)
  }

  async create_new_folder(payload): Promise<any> {
    return await apiSauce.apisauce.post("library/create_folder", payload)
  }

  async create_new_file(payload): Promise<any> {
    return await apiSauce.apisauce.post("library/create_file", payload)
  }

  async rename_folder(payload): Promise<any> {
    return await apiSauce.apisauce.post("library/rename_folder", payload)
  }

  async rename_file(payload): Promise<any> {
    return await apiSauce.apisauce.post("library/rename_file", payload)
  }

  async delete_folder(id): Promise<any> {
    return await apiSauce.apisauce.post("library/delete_folder", {id})
  }

  async delete_file(id): Promise<any> {
    return await apiSauce.apisauce.post("library/delete_file", {id})
  }

  async duplicate_file(payload): Promise<any> {
    return await apiSauce.apisauce.post("library/create_duplicate_document",payload)
  }

  async fetch_file_by_toolid(parent, tool_id): Promise<any> {
    console.log('fetch_file_by_toolid', {parent, tool_id})
    return await apiSauce.apisauce.post("library/fetch_file_by_toolid", {parent, tool_id})
  }

}
