import Accordion from '../../../components/accordion/accordion';
import { observer } from 'mobx-react-lite';

const SopSidebar = observer((props: any) => {
  const { data } = props;
  return (
    <div>
      {data.map((item, index) => {
        return (
          <Accordion label={item.name} label2={item.step}>
            {item.component}
          </Accordion>
        );
      })}
    </div>
  );
});

export default SopSidebar;
