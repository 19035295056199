import React, { useState, useEffect, useRef } from 'react';
import { useStore } from '../../hooks/use-store';
import folderCloseIcon from '../../icons/folderClose.svg';
import { PDFDownloadLink } from '@react-pdf/renderer';
import htmlDocx from 'html-docx-js/dist/html-docx';
import { saveAs } from 'file-saver';
import PDFLIB from '../sop-components/PDFLIB';
import { safe_get } from '../utils';

const TableBody = ({ item_click, item, renameDoc, deleteDoc, id }) => {
  const { newContentStore,  editorStore } = useStore();

  const [popover, setPopover] = useState(false);
  const containerRef = useRef<HTMLTableCellElement>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [downloadClicked, setDownloadClicked] = useState(true);
  const handleClickOutside = (event: MouseEvent) => {
    const { current: wrapper } = containerRef;
    if (wrapper && !wrapper.contains(event.target as Node)) {
      setPopover(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const path = window.location.pathname;
  return (
    <>
      <tr className="border-b border-[#E3E5E9]">
        <th
          onClick={() => item_click(item)}
          scope="row"
          className="flex items-center px-6 py-4 font-medium text-[#4E5664] whitespace-nowrap cursor-pointer"
        >
          {item.isfile ? (
            <img
              className="cursor-pointer mr-4"
              src={item.tool_id === 151 ? '/lor.svg' : '/sop_file.svg'}
            />
          ) : (
            <img className="cursor-pointer mr-4 w-4 h-4" src={folderCloseIcon} />
          )}{' '}
          <h6>{item.name}</h6>
        </th>
        <td className={'px-6 py-4 cursor-pointer'}>
          {!item.isEmpty ? (
            <div className="group relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#009400"
                className="w-6 h-6 group-hover:opacity-40"
              >
                <path
                  fillRule="evenodd"
                  d="M9 1.5H5.625c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5zm6.61 10.936a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 14.47a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                  clipRule="evenodd"
                />
                <path d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z" />
              </svg>
              <div className="opacity-0 group-hover:opacity-100 absolute bg-green-500 font-semibold text-white text-xs py-1 px-2 rounded-md bottom-[-0.5rem] left-[70%] transform -translate-x-1/2 transition duration-300">
               {item.tool_id===151 ? 'LOR IS COMPLETED':'SOP IS COMPLETED'} 
              </div>
            </div>
          ) : (
            <div className="group relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#ffdd76"
                className="w-6 h-6 group-hover:opacity-40"
              >
                <path
                  fillRule="evenodd"
                  d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                  clipRule="evenodd"
                />
              </svg>
              <div className="opacity-0 group-hover:opacity-100 absolute bg-[#ffd043] font-semibold text-white text-xs py-1 px-2 rounded-md bottom-[-0.5rem] left-[70%] transform -translate-x-1/2 transition duration-300">
              {item.tool_id===151 ? 'LOR IN PROGRESS':'SOP IN PROGRESS'}
              </div>
            </div>
          )}
        </td>
        <td className={'px-6 py-4 cursor-pointer'}>{item.isfile ? 'File' : 'Folder'}</td>
        <td className="px-6 py-4">{item.modified}</td>
        <td
          ref={containerRef}
          data-popover-target="popover-bottom"
          data-popover-placement="bottom"
          className="px-6 py-4 flex flex-col items-center"
        >
          <i
            className="fa-light fa-ellipsis-stroke-vertical text-[20px] cursor-pointer"
            onClick={async () => {
              safe_get(item, 'isfile', false);
              await editorStore.get_editor(item.id);
              setPopover(!popover);
            }}
          ></i>
          {popover && item.id == id && (
            <div
              data-popover
              id="popover-click"
              role="tooltip"
              style={{ boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.15)' }}
              className="absolute w-24 z-[99] mt-7 right-[100px] text-sm text-gray-500 duration-300 bg-[#FEFEFE] rounded-lg pr-32"
            >
              <div className="pl-4 pr-8 py-2 rounded-t-lg">
                <h3
                  className="font-avenir p-1  text-[#4E5664] cursor-pointer "
                  onClick={(event) => {
                    renameDoc(event, item);
                    setPopover(!popover);
                  }}
                >
                  Rename
                </h3>
                {item.isfile ? (
                  <h3
                    className="font-avenir p-1 text-[#4E5664] cursor-pointer"
                    onClick={(event) => {
                      newContentStore.duplicate_file(id);
                      setPopover(!popover);
                    }}
                  >
                    Duplicate
                  </h3>
                ) : (
                  ''
                )}
                {item.isfile ? (
                  <div className="relative inline-block">
                    <button
                      className="pl-[12px] pr-[8px] -ml-[0.5rem] rounded-md  text-[#4E5664]  h-[35px] flex items-center "
                      onClick={() => setShowDropdown(!showDropdown)}
                      // onBlur={()=>setShowDropdown(false)}
                    >
                      Download
                      <i
                        className="fa-regular fa-angle-down ml-[0.5rem] -mt-[0.2rem]"
                        style={{ fontSize: '22px', color: '#4E5664 ' }}
                      />
                    </button>

                    {showDropdown && (
                      <div
                        className="absolute -ml-48 -mt-6 border border-purple-500 bg-[#FCF3FF] rounded-md shadow-md"
                        onClick={() =>
                          setTimeout(async () => {
                            setShowDropdown(false);
                          }, 300)
                        }
                      >
                        <div className="py-1">
                          <div>
                            {downloadClicked && (
                              <PDFDownloadLink
                                document={<PDFLIB sopStore={editorStore} />}
                                fileName={item.tool_id == '151' ? 'LOR.pdf' : 'SOP.pdf'}
                              >
                                {({ loading }) => (
                                  <div className="px-4 py-2 cursor-pointer text-[#A033FF] hover:bg-gray-100 ">
                                    {loading ? 'Loading...' : 'Download as PDF'}
                                  </div>
                                )}
                              </PDFDownloadLink>
                            )}
                          </div>
                          <div
                            className="px-4 py-2 cursor-pointer hover:bg-gray-100 text-[#A033FF]"
                            onClick={() => {
                              const fileBuffer = htmlDocx.asBlob(editorStore.sopText);
                              {
                                item.tool_id == '151'
                                  ? saveAs(fileBuffer, 'LOR.docx')
                                  : saveAs(fileBuffer, 'SOP.docx');
                              }
                              setShowDropdown(false);
                            }}
                          >
                            Download as Word
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  ''
                )}

                <h3
                  className="font-avenir p-1 text-[#FF495C] cursor-pointer "
                  onClick={(event) => {
                    deleteDoc(event, item);
                    setPopover(!popover);
                  }}
                >
                  Delete
                </h3>
              </div>
            </div>
          )}
        </td>
      </tr>
    </>
  );
};

export default TableBody;
