import React, { useEffect } from 'react';
import { useStore } from '../../hooks/use-store';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';

const SopName = observer(() => {
  const date = dayjs().format('DD-MM-YY hh_mm A');
  const { sopProfileSelectionStore, resumeStore, userProfileStore, userStore, lorStore } =
    useStore();
  const handleSopNameChange = (event) => {
    sopProfileSelectionStore.set_current_sop_name(event.target.value);
  };
  useEffect(() => {
    userProfileStore.get_user_profile_name_id_list();
    sopProfileSelectionStore.set_current_sop_name(
      `${userProfileStore.userDetails[0]?.user_name} - ${sopProfileSelectionStore.countryName} - ${date}`
    );
  }, []);
  return (
    <div className="w-[100%] h-[17%] flex items-center justify-center ">
      <div className="w-[100%] h-[100%] flex flex-col ml-[0.5rem]">
        <h1 className="font-avenir text-[#737E93] py-1 px-[9.5rem] text-[16px]">
          Name of SOP/LOR File
        </h1>{' '}
        <div className="w-[100%] flex items-center justify-center flex-row">
          <div className="flex items-center justify-center flex-row w-[60%] h-10 border border-[#C7CBD4]  pr-2 mr-4  rounded-md ">
            <input
              value={sopProfileSelectionStore.current_sop_name}
              onChange={handleSopNameChange}
              type="text"
              name="sop_name"
              placeholder="Enter"
              className="text-ellipsis text-sm whitespace-nowrap block rounded-lg border-none bg-[#FEFEFE] h-6 w-[90%] border border-[#C7CBD4] text-gray-900 focus:outline-none"
            />
            <p className="text-sm">{lorStore.isLor ? '.lor' : '.sop'}</p>
          </div>
        </div>
      </div>
    </div>
  );
});
export default SopName;
