import apiSauce from './api-sauce';

export class UserProfileApi {
  async get_profile(): Promise<any> {
    return await apiSauce.apisauce.post('profile/get_profile');
  }

  async update_profile(payload): Promise<any> {
    return await apiSauce.apisauce.post('profile/update_profile', JSON.stringify(payload));
  }
  async user_list(): Promise<any>{
    return await apiSauce.apisauce.get('profile/get_user_profiles');
  }
  async get_user_list(payload): Promise<any>{
    return await apiSauce.apisauce.post('profile/get_profile_structure',payload);
  }

  async fetch_sop_by_userprofile(profile_id, tool_id): Promise<any> {
    console.log('fetch_sop_by_userprofile', {profile_id, tool_id})
    return await apiSauce.apisauce.post("profile/fetch_sops_by_userprofiles", {profile_id, tool_id})
  }

  async get_user_id(id): Promise<any>{
    return await apiSauce.apisauce.get(`profile/get_userprofile?profile_id=${id}`);
  }
  async get_user_profile_name_id_list(): Promise<any>{
    return await apiSauce.apisauce.get('profile/get_user_profiles_name_and_id');
  }
  async get_delete(id):Promise<any>{
    return await apiSauce.apisauce.put(`profile/delete_userprofile/${id}`);
  }
  async rename_user(payload): Promise<any>{
    return await apiSauce.apisauce.put('profile/rename_profile',payload);
  }
  async check_profile(payload): Promise<any>{
    return await apiSauce.apisauce.post('profile/check_profile',payload)
  }

  async get_profile_by_id(profile_id): Promise<any>{
    return await apiSauce.apisauce.get('profile/get_userprofile', { profile_id })
  }
}
