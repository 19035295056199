import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useState, useEffect } from 'react';
import { useStore } from '../../../hooks/use-store';
import { observer } from 'mobx-react-lite';
import { SideOverlay } from '../../../side-overlay';
import { classNames } from '../../../utils';

const HistorySideOverlay = observer(() => {
  const { canadaSopStore, newContentStore, editorStore } = useStore();

  useEffect(() => {

  }, []);

  const replaceDoc = (txt: string) => {
    editorStore.document_text_replace(txt)
    editorStore.history_pane_hide()
  };
  const insertAtStart = (txt: string) => {
    editorStore.document_text_insert_at_start(txt)
    editorStore.history_pane_hide()
  };
  const insertAtEnd = (txt: string) => {
    editorStore.document_text_insert_at_end(txt)
    editorStore.history_pane_hide()
  };


  const history_item = (x) => {
    const { documentId, logId, sopOutput, toolId } = x;
    return (
      <>
        <div className='p-6 w-full'>
          <div className='flex flex-row justify-between items-center pb-2'>
            <div className='font-bold pb-2'>Version {logId}</div>
            <Menu as="div" style={{ marginTop: -5 }} className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  Apply
                  <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true"/>
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <span
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm cursor-pointer'
                          )}
                          onClick={() => {
                            replaceDoc(sopOutput);
                          }}
                        >
                          Replace document
                        </span>
                      )}
                    </Menu.Item>

                    <Menu.Item>
                      {({ active }) => (
                        <span
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm cursor-pointer'
                          )}
                          onClick={() => {
                            insertAtStart(sopOutput);
                          }}
                        >
                          Insert at the begging of document
                        </span>
                      )}
                    </Menu.Item>

                    <Menu.Item>
                      {({ active }) => (
                        <span
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm cursor-pointer'
                          )}
                          onClick={() => {
                            insertAtEnd(sopOutput);
                          }}
                        >
                          Insert at the end of document
                        </span>
                      )}
                    </Menu.Item>


                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <textarea className='w-full p-2 form-control outline-none focus:ring-none text-sm	text-gray-500	' rows={5} readOnly={true} defaultValue={sopOutput}/>
        </div>

      </>
    );
  };


  const history_pane = (
    <>
      <div className='divide-y-2'>
        {editorStore.history.map(x => {
          return history_item(x);
        })}
      </div>
    </>
  );

  return (
    <>
      <SideOverlay
        children={history_pane}
        onClose={() => editorStore.history_pane_hide()}
        show={editorStore.history_pane_overaly_status}
        title="Previous Versions"
        length={editorStore.history.length}
      />
    </>
  );
});

export default HistorySideOverlay;
