import apiSauce from './api-sauce';

export class EditorApi {

  async get_editor(document_id): Promise<any> {
    return await apiSauce.apisauce.post(`editor/get_editor?document_id=${document_id}`);
  }

  async save_editor(payload): Promise<any> {
    return await apiSauce.apisauce.put(`editor/update_editor`, payload);
  }

  async get_history(document_id):Promise<any>{
    return await apiSauce.apisauce.get(`workspace/get_previous_SOP?fileid=${document_id}`);
  }

}
