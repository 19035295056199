import { $createLinkNode } from '@lexical/link';
import { $createListItemNode, $createListNode } from '@lexical/list';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { $createHeadingNode, $createQuoteNode } from '@lexical/rich-text';
import { $createParagraphNode, $createTextNode, $getRoot } from 'lexical';
import * as React from 'react';
import EditorNodes from './nodes/EditorNodes';
import PlaygroundEditorTheme from './themes/PlaygroundEditorTheme';
import { SharedHistoryContext } from './context/SharedHistoryContext';
import { TableContext } from './plugins/TablePlugin';
import { SharedAutocompleteContext } from './context/SharedAutocompleteContext';
import Editor from './Editor';
import Settings from './Settings';
import DocsPlugin from './plugins/DocsPlugin';
import PasteLogPlugin from './plugins/PasteLogPlugin';
import TestRecorderPlugin from './plugins/TestRecorderPlugin';
import TypingPerfPlugin from './plugins/TypingPerfPlugin';
import './editor-shell.css';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import { useSettings } from './context/SettingsContext';
import useAIAssistInvoker from './hooks/useAIAssistInvoker';
import AIAssistHomeScreen from './aiassist/ai-assist-home-screen';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from '../../hooks/use-store';
import { Loading } from '../../components/Loading';
import { observer } from 'mobx-react-lite';
import { EditorPayload } from '../../store/editor-store';
import StepperProgressVisual from './progress-visual';
import Loader from '../../components/loader';
import { SideOverlayPlagiarsim } from '../../side-overlay-plagiarsim';
import Plagiarism from '../../pages/sops/usa_sop/final_output_screen/Plagiarism/plagiarism';
import HistorySideOverlay from './history/history-side-overlay';


const CambriRichEditor = observer((props) => {

  let { editorStore, dashboardStore, sopStore } = useStore();
  const params = useParams();

  useEffect(() => {
    if (Object.keys(params).includes('documentID')) {
      editorStore.setEditorPayload({
        'documentID': params['documentID'],
        'folderID': params['folderID']
      });
    }
    if (Object.values(props).length > 0) {
      editorStore.setEditorPayload({
        'documentID': Object.values(props)[0],
        'folderID': Object.values(props)[1]
      });
    }

  }, []);

  useEffect(() => {
    console.log(editorStore.payload);
  }, [editorStore.payload]);


  const initialConfig = {
    editorState: null,
    namespace: 'Cambri',
    nodes: [...EditorNodes],
    onError: (error: Error) => {
      throw error;
    },
    theme: PlaygroundEditorTheme
  };
  const OpenApiStatus = localStorage.getItem('openApiStatus')
  useEffect(() => {
    dashboardStore.setScreenName('Cambri Editor');
if(window.location.pathname.includes('/germanysop') ){
  dashboardStore.setScreenName('Cambri Editor');
}
  }, []);


  // const editorStateRef = useRef(initialConfig.editorState)

  const {
    settings: {
      isRichText,
    }
  } = useSettings();

  return (
    <>
    
   { editorStore.loading && 
 <div className="fixed w-[78%] h-full flex items-center right-[-10rem] bg-white opacity-75  justify-center" style={{ zIndex: 99999 }}>
 <Loader/>
 <div className="text-[#4E5664] text-center text-base ml-[-6rem]">
   <p className="mt-[19rem] mb-4">Your output is being generated..</p>
   <p>Please give us a moment</p>
 </div>
</div>
}

   <LexicalComposer initialConfig={initialConfig}>
        <SharedHistoryContext>
          <TableContext>
            <SharedAutocompleteContext>
              <StepperProgressVisual/>

              <div style={{ marginTop: editorStore.stepper_show_toggle  ? '11rem' : editorStore.stepper_show_toggle &&  OpenApiStatus ? '8rem' : '5rem' }}>

                <div className="editor-shell-container">
                  {/* header commented */}
                  {/* <div className="editor-header flex items-center">
            <div className="hamburger cursor-pointer rounded-full flex flex-row items-center justify-center">
              <i className="fa-regular fa-bars" />
            </div>

            <div className="ml-6 flex flex-col">
              <div className="doc-title">New Document</div>
              <div className="flex flex-row items-center mt-2">
                <i className="folder-icon fa-light fa-folder-open" />
                <div className="doc-sub-title mx-2">Birmingham University</div>
                <i className="edit-icon cursor-pointer fa-solid fa-pen" title="Move to folder"></i>
              </div>
            </div>
          </div> */}


                  {/*<header>
                <a href="/" target="_blank" rel="noreferrer">
                  <img src="/cambri-logo-color.png" alt="Cambri"/>
                </a>
              </header>*/}
                  {isRichText && <ToolbarPlugin/>}
                  <div className="editor-shell">
                    <Editor/>
                  </div>
                  {/* OnchangePlugin*/}
                  {/* <OnChangePlugin
                    onChange={(editorState) => {
                      console.log(JSON.stringify(editorState))
                      if (editorStateRef.current !== undefined) {
                        //editorStateRef.current = editorState;
                      }
                    }}
                  />
                  <button
                    onClick={() => {
                      if (editorStateRef.current) {
                        console.log(JSON.stringify(editorStateRef.current));
                      }
                    }}
                  >
                    {' '}
                    Save{' '}
                  </button> */}
                  {/*<Settings/>*/}
                  {/*<DocsPlugin/>*/}
                  {/*<PasteLogPlugin/>*/}
                  {/*<TestRecorderPlugin/>*/}
                  {/*<TypingPerfPlugin/>*/}

                </div>
              </div>

            </SharedAutocompleteContext>
          </TableContext>
        </SharedHistoryContext>
      </LexicalComposer>
      <SideOverlayPlagiarsim
        children={
          <div>
            <Plagiarism sopStore={sopStore}/>
          </div>
        }
        onClose={() => {
          sopStore.toggle_show_plagiarism();
        }}
        show={sopStore.showPlagiarism}
        title="Plagiarism Checker"
      />

      <HistorySideOverlay/>

      {

      }
    </>
  );
});

export default CambriRichEditor;
