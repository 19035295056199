import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import ProfileCustomTextField from '../../components/profile-text-field/profile-text-field';
import { toJS } from 'mobx';
import InputCalender from '../tools/input-calender/input-calender';

const GetDataBasedOnSlot = observer((props: any) => {
  return (
    props.data &&
    props.data.length > 0 &&
    props.data.map((each, index) => {
            return Object.keys(toJS(each)).map((item, i) => {
                if (item !== 'id') {
          return (
            <Fragment key={each['id'] + i}>
              {props.template[item]['type'] === 'text' ? (
                <div className={`${i == 0 && 'flex items-center'}`}>
                  <ProfileCustomTextField
                    placeholder={props.template[item]['placeholder']}
                    onChange={(e) => props.handleChange(e, props.slot, each['id'], item)}
                    name={props.template[item]['name']}
                    type={props.template[item]['type']}
                    label={props.template[item]['label']}
                    slot={props.slot}
                    itemId={each['id']}
                    keyValue={item}
                    value={each[item]}
                  />
                </div>
              ) : null}
              {props.template[item]['type'] === 'date' ? (
                <InputCalender
                 // disabled={props.template[item]['name'] == 'To' && each['isCurrentlyWorking']}
                  placeholder={props.template[item]['placeholder']}
                  name={props.template[item]['name']}
                  label={props.template[item]['label']}
                  slot={props.slot}
                  itemId={each['id']}
                  keyValue={item}
                  value={each[item]}
                />
              ) : null}

              {props.template[item]['type'] === 'radio' ? (
                <div className="mb-4 flex flex-col">
                  <div>
                    <label className="left-0 -top-3.5 mb-[4px] text-[16px] leading-[22px] text-[#737E93] peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                      {props.template[item]['label']}
                    </label>
                  </div>
                  <div
                    onClick={(e) =>
                      props.handleChange(e, props.slot, each['id'], item, each)
                    }
                    className="flex items-center mt-2 "
                  >
                    <input
                      defaultChecked={each['isCurrentlyWorking']}
                      type="radio"
                      name={item['name']}
                    />
                    <p className="ml-2 text-[16px] leading-[22px] text-[#737E93]">
                      I currently work here
                    </p>
                  </div>
                </div>
              ) : null}
            </Fragment>
          );
        }
      });
    })
  );
});
export default GetDataBasedOnSlot;
