import { observer } from 'mobx-react-lite';
import './radioStyles.css'

const RadioButton = observer((props:any) => {
  const {checked}=props;
    return (
      <label className="radio-label p-3">
        <input
          defaultChecked={checked}
          checked={checked}
          className="radio-input"
          type="radio"
          readOnly
        />
        <span className="custom-radio" />
      </label>
    )
  })

  export default RadioButton