import { observer } from 'mobx-react-lite';
import { useStore } from '../../../../hooks/use-store';
import SopFooter from '../../../sop-components/sop-footer';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import Loader from '../../../../components/loader';
import GeneratedOutput from '../../../sop-components/generated-output';
import SopSidebar from '../../final-output-screen-components/sop_sidebar';
import AboutCollege from './about_college/about_college';
import Motivation from '../motivation/motivation';
import Goals from '../goals/goals';
import Academics from '../qualifications/qualifications';
import Contribution from '../contribution/contribution';
import { OpenApiStatus } from '../../../../api/open-api-status';

const NewSopContainer = observer((props: any) => {
  const {
    sopStore,
    dashboardStore,
    newContentStore,
    userStore,
    editorStore,
    sopProfileSelectionStore,
  } = useStore();

  const path = window.location.pathname;
  let { id, folderid } = useParams();

  useEffect(() => {
    if (!newContentStore.libraryClick) {
      editorStore.clearEditorState();
    }
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      await sopStore.get_questions(152);

      if (path.includes('/sop')) {
        sopStore.set_sop_id(id);
        dashboardStore.setScreenName('Statement of Purpose');
        userStore.credits = sopStore.availableCredits;
      }
      if (path.includes('/australiasop')) {
        sopStore.set_sop_id(id);
        dashboardStore.setScreenName('Statement of Purpose');
        userStore.credits = sopStore.availableCredits;
      }

      if (id && !sopStore.isFromCreateSop) {
        await fetchDataForSOP();
      }
      if (path.includes(`/australiasop/${id}/${folderid}`)) {
        sopStore.setFinalOutputScreen(true);
      }
      if (path.includes(`/sop/${id}/${folderid}`)) {
        sopStore.setFinalOutputScreen(true);
      }
      sopStore.resetPlagiarsim();
    };

    fetchData();

    return () => {
      sopStore.set_selected_textarea('', '', '');
    };
  }, []);

  const fetchDataForSOP = async () => {
    sopStore.clear_sop_state();
    await sopStore.get_sop_workspace(id, newContentStore.activeFolder);
  };

  useEffect(() => {
    if (folderid && id && !sopStore.isFromCreateSop) {
      sopStore.setFinalOutputScreen(true);
      sopStore.get_generated_sop(id, folderid);
    }
  }, [id]);

  const data = [
    {
      step: 'step 1',
      name: 'About College',
      component: <AboutCollege />,
    },
    {
      step: 'step 2',
      name: 'Motivation',
      component: <Motivation />,
    },
    {
      step: 'step 3',
      name: 'Goals / Aspirations',
      component: <Goals />,
    },
    {
      step: 'step 4',
      name: 'Qualification',
      component: <Academics />,
    },
    {
      step: 'step 5',
      name: 'Your Contribution',
      component: <Contribution />,
    },
  ];

  async function fetchDataUsa() {
    try {
      const openApiStatus = new OpenApiStatus();
      await openApiStatus.fetchOpenAIStatus();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }
  useEffect(() => {
    fetchDataUsa();
  }, []);

  return (
    <div>
      <div className="flex h-full">
        <nav
          style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}
          className="z-[1] relative flex w-[30%]  bg-[#FEFEFE] py-8"
        >
          <div className="w-full flex mx-auto h-full">
            <div className="w-full h-full justify-center text-gray-900 text-xl">
              <SopSidebar data={data} />
            </div>
          </div>
        </nav>
        <main
          className={`${
            sopStore.loading && 'justify-center items-center h-screen'
          } flex flex-col w-[70%] h-full overflow-hidden relative`}
        >
          <div className="flex w-full flex-col mx-auto py-8 mt-8 items-center">
            {!sopStore.loading? (
              <>
                <div className="px-2 w-full mb-20">
                  <GeneratedOutput sopStore={sopStore} />
                </div>
                <div
                  className="fixed bottom-0 z-[2] bg-white px-10 py-4 mx-auto"
                  id="footer"
                  style={{
                    width: dashboardStore.showDashboardSideBar ? '60%' : '70%',
                  }}
                >
                  <SopFooter sopStore={sopStore} steps={5} />
                </div>
              </>
            ) : (
              <>
                <Loader />
                <div className="text-[#4E5664] text-center text-base">
                  <p className="mt-40 mb-4">Your output is being generated..</p>
                  <p>Please give us a moment</p>
                </div>
              </>
            )}
          </div>
        </main>
      </div>
    </div>
  );
});

export default NewSopContainer;
