import {action, makeAutoObservable, toJS} from "mobx";
import {makePersistable} from 'mobx-persist-store';
import {ToolsApi} from "../api/tools-api";
import {makeid_alpa_numeric, replaceObjectKeysWithoutChangingOrder, safe_get} from "../pages/utils";
import {toast} from "react-toastify";

const newSliderInputsObj = {
  "type": "slider",
  "title": ""
}
const newSliderOptionsObj = {
  "max": 0,
  "min": 0,
  "step": 0,
  "default": 0
}

const newTextareaInputsObj = {
  "type": "textarea",
  "title": "",
  "placeholder": "",
  "suggestions": []
}

const newTextareaOptionsObj = {
  "default": ""
}

const newSingleOneOptionsObj = {
  "choices": {
    "": ""
  },
  "default": ""
}

const newSingleOneWithOthersOptionsObj = {
  "choices": {
    "": ""
  },
  "default": ""
}

const newMultiSelectOptionsObj = {
  "choices": {
    "": ""
  },
  "default": ""
}

const newSingleOneInputsObj = {
  "type": "select-one",
  "title": ""
}

const newSingleOneWithOthersInputsObj = {
  "type": "select-one-others",
  "title": ""
}

const newMultiSelectInputsObj = {
  "type": "multi-select",
  "title": ""
}

export class NewToolsStore {
  loading: boolean = false
  category_expanded_status: object = {}
  toolsList: object = {}
  activeToolDetails: object = {}
  composeObject = {}
  allToolsDetails: object = {}
  content_composer_expanded_status: boolean = true
  selectedToolId: number = 0
  toolJSONData: object = {}
  composeHistory = []
  active_history_obj = {}
  current_history_details = []
  compose_response = []
  credits:number;
  activate_tool_id: any = -1;

  constructor(private toolsApi: ToolsApi) {
    makeAutoObservable(this);
    makePersistable(this, {name: 'NewToolsStore', properties: [], storage: window.localStorage});
  }

  @action
  async getToolsList(searchQuery: string) {
    this.loading = true;
    const payload = {
      'query': searchQuery
    }
    const res = await this.toolsApi.get_tools_list(payload);
    this.loading = false;
    this.credits=res.data.availableCredits
    return res
  }

  @action
  async getMyToolsList(searchQuery: string) {
    this.loading = true;
    const payload = {
      'query': searchQuery,
      "offset": 0,
      "count": 200
    }
    const res = await this.toolsApi.get_my_tools_list(payload);
    this.loading = false;
    return res
  }

  @action
  async getToolDetails(id: any) {
    this.loading = true;
    const payload = {
      'id': parseInt(id)
    }
    const res = await this.toolsApi.get_tool_details(payload);
    this.loading = false;
    return res
  }

  @action
  clear_compose_object() {
    this.composeObject = {}
  }

  @action
  async compose(payloadData: Object, id: number) {
    this.loading = true;
    const payload = {
      'gentool': id,
      'payload': payloadData
    }
    const res = await this.toolsApi.compose(payload);
    //this.clear_compose_object()
    this.loading = false
    return res
  }

  @action
  async tokenCount(payloadData: Object, id: number) {
    this.loading = true;
    const payload = {
      'gentool': id,
      'payload': payloadData
    }
    const res = await this.toolsApi.tokenCount(payload);
    this.loading = false
    return res
  }

  @action
  async saveTool() {
    this.loading = true;
    /*const temp = this.toolJSONData
    temp['slots']['ordered_inputs'] = []
    Object.keys(temp['slots']['inputs']).map((key) => {
      temp['slots']['ordered_inputs'].push(key)
    })*/
    const payload = this.toolJSONData
    const res = await this.toolsApi.saveTool(payload);
    this.loading = false
    return res
  }

  @action
  async deleteTool(id) {
    this.loading = true;
    const payload = {
      'id': id
    }
    const res = await this.toolsApi.deleteTool(payload);
    if (res.status === 200) {
      const resp = await this.getMyToolsList('')
      if (resp.status === 200) {
        this.setAllToolsDetails(resp.data)
      } else {
        toast.error(resp.data)
      }
    } else {
      toast.error(res.data)
    }
    this.loading = false
    return res
  }

  @action
  async cloneTool(id) {
    this.loading = true;
    const payload = {
      'id': id
    }
    const res = await this.toolsApi.cloneTool(payload);
    if (res.status === 200) {
      const resp = await this.getMyToolsList('')
      if (resp.status === 200) {
        this.setAllToolsDetails(resp.data)
      } else {
        toast.error(resp.data)
      }
    } else {
      toast.error(res.data)
    }
    this.loading = false
    return res
  }

  @action
  async getComposeHistory(offset, count) {
    this.loading = true;
    const payload = {
      "offset": offset,
      "count": count
    }
    const res = await this.toolsApi.get_compose_history(payload);
    this.loading = false;
    return res
  }

  @action
  setComposeHistory(history) {
    this.composeHistory = history
  }

  @action
  setToolsList(toolsList: object) {
    this.toolsList = toolsList
  }

  @action
  setActiveToolDetails(toolDetails) {
    this.activeToolDetails = toolDetails
  }

  @action
  clear_active_tool_details() {
    this.activeToolDetails = {}
  }

  @action
  updateComposeObject(key, value) {
    this.composeObject[key] = value
  }

  @action
  setAllToolsDetails(toolsDetails) {
    this.allToolsDetails = toolsDetails
  }

  @action
  reset_category_expanded_status() {
    this.category_expanded_status = {}
  }

  @action
  get_category_expanded_status(keyname) {
    return safe_get(this.category_expanded_status, keyname, false)
  }

  @action
  toggle_category_expanded_status(keyname) {
    const current_status = safe_get(this.category_expanded_status, keyname, true)
    console.log(current_status,'current_status')
    this.category_expanded_status[keyname] = current_status
  }

  @action
  toggle_content_composer_expanded_status() {
    this.content_composer_expanded_status = !this.content_composer_expanded_status
  }

  @action
  set_selected_tool_id(id: number) {
    this.selectedToolId = id
  }

  @action
  set_tool_json_data(data: object) {
    this.toolJSONData = data
  }

  @action
  update_active_tool_choices(slot_name, old_key, new_key) {
    const active_obj = toJS(this.toolJSONData)
    const choices = safe_get(active_obj, `slots.options.${slot_name}.choices`, {})
    const updated = replaceObjectKeysWithoutChangingOrder(choices, old_key, new_key)
    this.toolJSONData = {
      ...this.toolJSONData,
      'slots': {
        ...this.toolJSONData['slots'],
        'options': {
          ...this.toolJSONData['slots']['options'],
          [slot_name]: {
            ...this.toolJSONData['slots']['options'][slot_name],
            'choices': {...updated}
          }
        }
      }
    }
  }

  @action
  update_active_tool_choice_values = (display, newValue, slot_name) => {
    const temp = safe_get(this.toolJSONData, `slots.options.${slot_name}.choices`, {})
    const tempChoices = {...temp}
    Object.keys(tempChoices).map((key) => {
      if (key === display) {
        tempChoices[display] = newValue
      }
    })
    this.set_tool_json_data({
      ...this.toolJSONData,
      'slots': {
        ...this.toolJSONData['slots'],
        'options': {
          ...this.toolJSONData['slots']['options'],
          [slot_name]: {
            ...this.toolJSONData['slots']['options'][slot_name],
            'choices': {
              ...tempChoices
            }
          }
        }
      }
    })
  }

  @action
  add_new_choice_to_active_tool = (slot_name) => {
    const choice_key = makeid_alpa_numeric(4)
    this.set_tool_json_data({
      ...this.toolJSONData,
      'slots': {
        ...this.toolJSONData['slots'],
        'options': {
          ...this.toolJSONData['slots']['options'],
          [slot_name]: {
            ...this.toolJSONData['slots']['options'][slot_name],
            'choices': {
              ...this.toolJSONData['slots']['options'][slot_name]['choices'],
              [choice_key]: 'New Choice'
            }
          }
        }
      }
    })
  }

  @action
  update_active_tool_meta_data(name, val) {
    this.set_tool_json_data({
      ...this.toolJSONData,
      'meta': {
        ...this.toolJSONData['meta'],
        [name]: val
      }
    })
  }

  @action
  update_active_tool_prompt_data(val) {
    this.set_tool_json_data({
      ...this.toolJSONData,
      'more': {
        ...this.toolJSONData['more'],
        'prompt': val
      }
    })
  }

  @action
  update_active_tool_input_title(slot_name, attr, value) {
    this.set_tool_json_data({
      ...this.toolJSONData,
      'slots': {
        ...this.toolJSONData['slots'],
        'inputs': {
          ...this.toolJSONData['slots']['inputs'],
          [slot_name]: {
            ...this.toolJSONData['slots']['inputs'][slot_name],
            [attr]: value
          }
        }
      }
    })
  }

  @action
  update_input_key(old_key, new_key) {
    const active_obj = toJS(this.toolJSONData)
    const inputs = safe_get(active_obj, 'slots.inputs', {})
    const inputsNew = replaceObjectKeysWithoutChangingOrder(inputs, old_key, new_key)
    const options = safe_get(active_obj, 'slots.options', {})
    const optionsNew = replaceObjectKeysWithoutChangingOrder(options, old_key, new_key)
    active_obj['slots']['inputs'] = inputsNew
    active_obj['slots']['options'] = optionsNew
    const index = active_obj['slots']['ordered_inputs'].indexOf(old_key)
    if (active_obj['slots']['ordered_inputs'][index]) {
      active_obj['slots']['ordered_inputs'][index] = new_key
    }
    this.toolJSONData = active_obj
  }

  @action
  clone_input(slot_json, slot_name) {
    const new_slot_name = 'new_' + makeid_alpa_numeric(4)
    const temp = this.toolJSONData
    temp['slots']['inputs'][new_slot_name] = slot_json
    temp['slots']['options'][new_slot_name] = temp['slots']['options'][slot_name]
    temp['slots']['ordered_inputs'].push(new_slot_name)
    this.set_tool_json_data(temp)
  }

  @action
  delete_input(slot_name) {
    const temp = this.toolJSONData
    delete temp['slots']['inputs'][slot_name]
    delete temp['slots']['options'][slot_name]
    const index = temp['slots']['ordered_inputs'].indexOf(slot_name)
    if (temp['slots']['ordered_inputs'][index]) {
      temp['slots']['ordered_inputs'].splice(index, 1)
    }
    this.set_tool_json_data(temp)
  }

  @action
  add_new_input_comp(type) {
    const new_slot_name = 'new_' + makeid_alpa_numeric(4)
    const temp = this.toolJSONData
    let slot_input_json = {}
    let slot_options_json = {}
    switch (type) {
      case "select-one":
        slot_input_json = newSingleOneInputsObj
        slot_options_json = newSingleOneOptionsObj
        break
      case "select-one-others":
        slot_input_json = newSingleOneWithOthersInputsObj
        slot_options_json = newSingleOneWithOthersOptionsObj
        break
      case "multi-select":
        slot_input_json = newMultiSelectInputsObj
        slot_options_json = newMultiSelectOptionsObj
        break
      case "textarea":
        slot_input_json = newTextareaInputsObj
        slot_options_json = newTextareaOptionsObj
        break
      case "slider":
        slot_input_json = newSliderInputsObj
        slot_options_json = newSliderOptionsObj
        break
    }
    if (!temp['slots'] || !temp['slots']['inputs'] || !temp['slots']['options'] || !temp['slots']['ordered_inputs']) {
      temp['slots'] = {}
      temp['slots']['inputs'] = {}
      temp['slots']['options'] = {}
      temp['slots']['ordered_inputs'] = []
    }
    temp['slots']['inputs'][new_slot_name] = slot_input_json
    temp['slots']['options'][new_slot_name] = slot_options_json
    temp['slots']['ordered_inputs'].push(new_slot_name)
    this.set_tool_json_data(temp)
  }

  @action
  setSliderInputs(name, value, slot_name) {
    this.set_tool_json_data({
      ...this.toolJSONData,
      'slots': {
        ...this.toolJSONData['slots'],
        'options': {
          ...this.toolJSONData['slots']['options'],
          [slot_name]: {
            ...this.toolJSONData['slots']['options'][slot_name],
            [name]: value
          }
        }
      }
    })
  }

  @action
  setTextAreaInputs(name, value, slot_name) {
    this.set_tool_json_data({
      ...this.toolJSONData,
      'slots': {
        ...this.toolJSONData['slots'],
        'inputs': {
          ...this.toolJSONData['slots']['inputs'],
          [slot_name]: {
            ...this.toolJSONData['slots']['inputs'][slot_name],
            [name]: value
          }
        }
      }
    })
  }

  @action
  setTextAreaSuggestionInput(suggArr, slot_name) {
    this.set_tool_json_data({
      ...this.toolJSONData,
      'slots': {
        ...this.toolJSONData['slots'],
        'inputs': {
          ...this.toolJSONData['slots']['inputs'],
          [slot_name]: {
            ...this.toolJSONData['slots']['inputs'][slot_name],
            'suggestions': [...suggArr]
          }
        }
      }
    })
  }

  @action
  setMetaTagInput(tagArr) {
    this.set_tool_json_data({
      ...this.toolJSONData,
      'meta': {
        ...this.toolJSONData['meta'],
        'tags': [...tagArr]
      }
    })
  }

  @action
  async add_new_tool() {
    this.loading = true;
    const res = await this.toolsApi.add_new_tool()
    if (res.status === 200) {
      this.set_selected_tool_id(res.data.id)
    }
    this.loading = false
    return res.data.id
  }

  @action
  set_active_history_obj(selectedObj) {
    this.active_history_obj = selectedObj
  }

  @action
  set_current_history_details(histArr) {
    this.current_history_details = histArr
  }

  @action
  set_compose_response(res) {
    this.compose_response = res
  }

  @action
  set_activate_tool_id(tool_id){
    this.activate_tool_id = tool_id;
  }

  @action
  reset_activate_tool_id(){
    this.set_activate_tool_id(-1);
  }
}
