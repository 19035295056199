import {ContentApi} from "../api/content-api";
import {action, makeAutoObservable, toJS} from "mobx";
import {safe_get} from "../pages/utils";
import { parse, compareAsc,compareDesc } from 'date-fns';

export class NewContentStore {
  activeFolder: any = null;
  content: {}[] = [];
  contents: {}[] = [];
  loading: boolean = false;
  selectedContentType: string = '';
  isAddFolderModalOpen: boolean = false;
  isDeleteFolderModalOpen: boolean = false;
  isAddFileModalOpen: boolean = false;
  isDeleteFileModalOpen: boolean = false;
  parentId: number | null = null;
  isSideNavOpen: boolean = false;
  activeFile = {};
  editMode: boolean = false;
  children: {
    children: [];
    created: string;
    id: number;
    isOpen: boolean;
    isfile: boolean;
    modified: string;
    name: string;
    parent_id: number;
  }[];
  selectedFolderContents: {
    children: [];
    created: string;
    id: number;
    isOpen: boolean;
    isfile: boolean;
    modified: string;
    name: string;
    parent_id: number;
  }[];
  credits: number;
  isFromTopBar: boolean = false;
  fileNameSort: boolean = false;
  lastModified: boolean = false;
  disableSort = { nameSort: false, dateSort: false };
  isActive : boolean = false;
  sopStatus: any;
  libraryClick : boolean = false;

  @action
  setLibraryClick(value){
    this.libraryClick = value;
  }
  constructor(private contentApi: ContentApi) {
    makeAutoObservable(this)
  }

@action 
setActive(val){
  this.isActive = val;
  console.log(this.isActive,"ACTIVEBUTTON")
}

  @action
  set_loading(val) {
    this.loading = val;
  }

  @action
  async search_content(query) {
    return await this.contentApi.search_content({"name": query});
  }



  //   Get all contents ..........................


  @action
  async load_all_folders(parentid) {
    const response = await this.contentApi.get_all_contents({"parent": parentid});
    if (response.status === 200) {
      this.contents = response.data.contents
            if(this.activeFolder === null){
        this.activeFolder = response.data.contents[0].id
      }
      this.credits=response.data.availableCredits;
      this.sopStatus = response.data.contents.map((item)=>{
        return(item.children.map((item)=>{
          return(item.lastActiveStep)
        }))
        })
     
      
    } else {
      this.contents = []
    }
  }

  @action
  async get_all_contents() {
    const parent = safe_get(this.activeFolder, "id", null)
    await this.load_all_folders(parent)
  }

  //   ...............................

  @action
  async load_folder(parentid) {
    const response = await this.contentApi.get_content({"parent": parentid});
    if (response.status === 200) {
      this.content = response.data.contents
      //   this.activeFolder = response.data.folder
    } else {
      this.content = []
    }
  }

  @action
  async get_content() {
    const parent = safe_get(this.activeFolder, "id", null)
    await this.load_folder(parent)
  }

  @action
  async navigate_downwards(folder: any) {
    const parent = safe_get(folder, 'id', null)
    await this.load_folder(parent)
  }

  @action
  async navigate_upwards() {
    const parent = safe_get(this.activeFolder, 'parentid', null)
    await this.load_folder(parent)
  }

  @action
  has_parent() {
    return !(this.activeFolder === null)
  }

  @action
  current_folder_is_root() {
    return safe_get(this.activeFolder, 'id', null) == null
  }

  @action
  current_folder_name() {
    return safe_get(this.activeFolder, 'name', 'My Projects')
  }

  @action
  async create_folder(payload) {
    return await this.contentApi.create_new_folder(payload)
  }

  @action
  async create_file(payload) {
    return await this.contentApi.create_new_file(payload)
  }

  @action
  async rename_folder(payload) {
    return this.contentApi.rename_folder(payload)
  }

  @action
  async rename_file(payload) {
    return this.contentApi.rename_file(payload)
  }

  @action
  async delete_folder() {
    const parent_to_load = safe_get(this.activeFolder, 'parentid', null)
    this.loading = true
    await this.contentApi.delete_folder(safe_get(this.activeFolder, "id", null))
    await this.load_folder(parent_to_load)
    this.loading = false
  }

  @action
  async delete_file() {
    return await this.contentApi.delete_file(safe_get(this.activeFile, "id", null))
  }
  
  @action
  async duplicate_file(id) {
    this.loading = true;
    const payload = {
      'fileid': id
    };
    const resp = await this.contentApi.duplicate_file(payload);
    this.loading = false;
  
    if (resp.status === 200) {
      const parentID = resp.data.duplicate_file.parentid;
        if (parentID === this.activeFolder) {
        await this.get_all_contents();
      }
    }
  
    return resp;
  }
  
  @action
  set_parentid(id) {
    this.parentId = id;
  }

  @action
  set_children(arr:any) {
    this.children = arr;
  }

  @action
  setSelectedFolderContents(arr: any) {
    this.selectedFolderContents = arr;
  }

  @action
  setDisableSort(key, val) {
    this.disableSort[key] = val;
  }

  @action
  fileNameSortFunction() {
    let sortedData = this.selectedFolderContents;
    if (this.fileNameSort) {
      sortedData.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      sortedData.sort((a, b) => b.name.localeCompare(a.name));
    }
    let folders = sortedData.filter((item) => item.isfile == false);
    let files = sortedData.filter((item) => item.isfile == true);
    this.setSelectedFolderContents(folders.concat(files));
  }

  @action
  dateSortFunction() {
    let sortedData;
    const selectedFolderContentsJS = toJS(this.selectedFolderContents);

    if (this.lastModified) {
      sortedData = selectedFolderContentsJS
        .slice()
        .sort((a, b) =>
          compareAsc(
            parse(a.modified, 'MMM d yyyy HH:mm:ss', new Date()),
            parse(b.modified, 'MMM d yyyy HH:mm:ss', new Date())
          )
        );
      console.log(sortedData, "SORTEDDATA");
    } else {
      sortedData = selectedFolderContentsJS
        .slice()
        .sort((a, b) =>
          compareAsc(
            parse(a.modified, 'MMM d yyyy HH:mm:ss', new Date()),
            parse(b.modified, 'MMM d yyyy HH:mm:ss', new Date())
          )
        );
      console.log(sortedData, "SORTEDDATA1");
    }

    let folders = sortedData.filter((item) => item.isfile === false);
    let files = sortedData.filter((item) => item.isfile === true);
    console.log(files, "SORTEDDATA3");

    this.selectedFolderContents = folders.concat(files);
    console.log(toJS(this.selectedFolderContents), "SORTEDDATA2");
  }

  @action
  set_selected_content_type(type) {
    this.selectedContentType = type;
  }

  @action
  set_active_folder(folder) {
    this.activeFolder = folder;
  }

  @action
  set_active_file(file) {
    this.activeFile = file;
  }

  @action
  toggle_modal_status(name: string, value: boolean) {
    switch (name) {
      case "add_folder":
        this.isAddFolderModalOpen = value;
        break;
      case "add_file" :
        this.isAddFileModalOpen = value;
        break;
      case "delete_folder" :
        this.isDeleteFolderModalOpen = value;
        break;
      case "delete_file" :
        this.isDeleteFileModalOpen = value;
        break;
      case "side_bar":
        this.isSideNavOpen = value;
        break;
      case "edit":
        this.editMode = value;
        break;
      default:
        return null;
    }
  }

  @action
  setIsFromTopBar(bool) {
    this.isFromTopBar = bool;
  }

  @action
  setFileNameSort(bool) {
    this.fileNameSort = bool;
  }

  @action
  setLastModifiedSort(bool) {
    this.lastModified = bool;
  }

  @action
  reset_newContentStore() {
    this.children = [];
    this.selectedFolderContents = [];
    this.activeFolder = null;
  }
}
