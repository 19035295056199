import _ from 'lodash';
import S3 from 'react-aws-s3';
import { isValidPhoneNumber } from 'react-phone-number-input';
import keysJson from '../json/keys.json'

export function getParameterByName(name) {
  const match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

export function getWindowDimensions() {
  const {innerWidth: width, innerHeight: height} = window;
  return {
    width,
    height
  };
}

export const safe_get = (map_object, key, defaultValue) => {

  try {
    let current = map_object;
    const parts = key.split('.');
    for (const part of parts) {
      if (part.startsWith('$')) {
//
      } else {
        if (Object.keys(current).includes(part)) {
          current = current[part];
        } else {
          return defaultValue;
        }
      }
    }
    return current;
  } catch (e) {

  }

  return defaultValue;
};

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

export const makeid_alpa_numeric = (length) => {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;

  for (var i = 0; i < length; i++) {
      const randomIndex = getRandomInt(charactersLength);
      result += characters.charAt(randomIndex);
  }
  return result;
};

function getRandomInt(max) {
  const randomBuffer = new Uint32Array(1);
  window.crypto.getRandomValues(randomBuffer);
  return randomBuffer[0] % max;
}

export const replaceObjectKeysWithoutChangingOrder = (obj, old_key, new_key) => {
  return _.mapKeys(obj, (v, k) => {
    return k === old_key ? new_key : k;
  });
};


export const upload_file_to_s3 = (path, file: File, filename, bucket = 'cambri', unique = false) => {
  const getFileExtension = (filename) => {
    const extension = filename.split('.').pop();
    return extension;
  };

  return {location:'www.google.com'};
  /*
    .then(data => {
      console.log('Uploaded', data);
      //toast.success("File uploaded successfully..")
    })
    .catch(err => {
      console.log('Failed', err);
    });

  const s3path = s3config.dirName + '/' + newFileName;
  // toast.success("File uploaded successfully..")
  return s3path
} catch (e) {
  //toast.error("Something went wrong..")
  console.log("error", e);
}
*/
};


export const fetch_articles_by_tag = async (tag) => {
  const headers = new Headers();
  headers.append('Authorization', 'Basic YXBpYWdlbnQ6aWlpamxuQXRBIyM5QV42KEdISnlFeWhx');

  const response = await fetch(
    `https://blogs.cambri.ai/wp-json/wp/v2/posts?filter[tag]=${tag}&embed`,
    { method: 'GET', headers: headers, redirect: 'follow' }
  );

  const articles = await response.json();
  return articles;
};

export const fetch_articles_by_category_id = async (tag) => {
  const headers = new Headers();
  headers.append('Authorization', 'Basic YXBpYWdlbnQ6aWlpamxuQXRBIyM5QV42KEdISnlFeWhx');

  const response = await fetch(
    `https://blogs.cambri.ai/wp-json/wp/v2/posts?categories=${tag}`,
    { method: 'GET', headers: headers, redirect: 'follow' }
  );

  const articles = await response.json();
  return articles;
};

export const get_featured_image = async (href) => {
  const headers = new Headers();
  headers.append('Authorization', 'Basic YXBpYWdlbnQ6aWlpamxuQXRBIyM5QV42KEdISnlFeWhx');

  const response = await fetch(href, { method: 'GET', headers: headers, redirect: 'follow' });

  const object = await response.json();
  const url = safe_get(object, 'media_details.sizes.full.source_url', undefined);
  return url;
};
export const validateMobileNumber =(number)=>{ 
  //if this function returns true, mobile number is valid or else invalid.
  if(!isValidPhoneNumber(number)){
      return false
  }
  else{
    return true
  }
};