import type { LexicalEditor, NodeKey } from 'lexical';
import {
  $createCodeNode,
  $isCodeNode,
  CODE_LANGUAGE_FRIENDLY_NAME_MAP,
  CODE_LANGUAGE_MAP,
  getLanguageFriendlyName,
} from '@lexical/code';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import {
  $isListNode,
  INSERT_CHECK_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { INSERT_EMBED_COMMAND } from '@lexical/react/LexicalAutoEmbedPlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode';
import { INSERT_HORIZONTAL_RULE_COMMAND } from '@lexical/react/LexicalHorizontalRuleNode';
import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode,
  $isQuoteNode,
  HeadingTagType,
} from '@lexical/rich-text';
import {
  $getSelectionStyleValueForProperty,
  $isParentElementRTL,
  $patchStyleText,
  $setBlocksType,
} from '@lexical/selection';
import { $isTableNode } from '@lexical/table';
import {
  $findMatchingParent,
  $getNearestBlockElementAncestorOrThrow,
  $getNearestNodeOfType,
  mergeRegister,
} from '@lexical/utils';
import {
  $createParagraphNode,
  $getNodeByKey,
  $getRoot,
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  $isTextNode,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_NORMAL,
  DEPRECATED_$isGridSelection,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  INDENT_CONTENT_COMMAND,
  KEY_MODIFIER_COMMAND,
  OUTDENT_CONTENT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from 'lexical';
import { Fragment, useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import useModal from '../../hooks/useModal';
import catTypingGif from '../../images/cat-typing.gif';
import { $createStickyNode } from '../../nodes/StickyNode';
import DropDown, { DropDownItem } from '../../ui/DropDown';
import DropdownColorPicker from '../../ui/DropdownColorPicker';
import { getSelectedNode } from '../../utils/getSelectedNode';
import { sanitizeUrl } from '../../utils/url';
import { EmbedConfigs } from '../AutoEmbedPlugin';
import { INSERT_COLLAPSIBLE_COMMAND } from '../CollapsiblePlugin';
import { INSERT_EXCALIDRAW_COMMAND } from '../ExcalidrawPlugin';
import { INSERT_IMAGE_COMMAND, InsertImageDialog, InsertImagePayload } from '../ImagesPlugin';
import { InsertInlineImageDialog } from '../InlineImagePlugin';
import { InsertPollDialog } from '../PollPlugin';
import { InsertNewTableDialog, InsertTableDialog } from '../TablePlugin';
import { IS_APPLE } from '../../shared/environment';
import CommentPlugin from '../CommentPlugin';
import { SPEECH_TO_TEXT_COMMAND, SUPPORT_SPEECH_RECOGNITION } from '../SpeechToTextPlugin';
import { exportFile } from '@lexical/file';
import { ShowClearDialog } from '../ActionsPlugin';
import { $generateHtmlFromNodes } from '@lexical/html';
import htmlDocx from 'html-docx-js/dist/html-docx';
import { saveAs } from 'file-saver';
import { useStore } from '../../../../hooks/use-store';
import { useParams } from 'react-router';
import Plagiarism from '../../../../pages/sops/usa_sop/final_output_screen/Plagiarism/plagiarism';
import { SideOverlayPlagiarsim } from '../../../../side-overlay-plagiarsim';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../../../utils';
import PDF from '../../../../pages/sop-components/PDF';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { build_save_payload } from '../../editor-state-hydration-utils';
import { toast } from 'react-toastify';
import { toJS } from 'mobx';

const blockTypeToBlockName = {
  bullet: 'Bulleted List',
  check: 'Check List',
  code: 'Code Block',
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  h4: 'Heading 4',
  h5: 'Heading 5',
  h6: 'Heading 6',
  number: 'Numbered List',
  paragraph: 'Normal',
  quote: 'Quote',
};

const rootTypeToRootName = {
  root: 'Root',
  table: 'Table',
};

function getCodeLanguageOptions(): [string, string][] {
  const options: [string, string][] = [];

  for (const [lang, friendlyName] of Object.entries(CODE_LANGUAGE_FRIENDLY_NAME_MAP)) {
    options.push([lang, friendlyName]);
  }

  return options;
}

const CODE_LANGUAGE_OPTIONS = getCodeLanguageOptions();

const FONT_FAMILY_OPTIONS: [string, string][] = [
  ['Arial', 'Arial'],
  ['Courier New', 'Courier New'],
  ['Georgia', 'Georgia'],
  ['Times New Roman', 'Times New Roman'],
  ['Trebuchet MS', 'Trebuchet MS'],
  ['Verdana', 'Verdana'],
];

const FONT_SIZE_OPTIONS: [string, string][] = [
  ['10px', '10px'],
  ['11px', '11px'],
  ['12px', '12px'],
  ['13px', '13px'],
  ['14px', '14px'],
  ['15px', '15px'],
  ['16px', '16px'],
  ['17px', '17px'],
  ['18px', '18px'],
  ['19px', '19px'],
  ['20px', '20px'],
];

function dropDownActiveClass(active: boolean) {
  if (active) return 'active dropdown-item-active';
  else return '';
}

function BlockFormatDropDown({
  editor,
  blockType,
  rootType,
  disabled = false,
}: {
  blockType: keyof typeof blockTypeToBlockName;
  rootType: keyof typeof rootTypeToRootName;
  editor: LexicalEditor;
  disabled?: boolean;
}): JSX.Element {
  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
    });
  };

  const formatHeading = (headingSize: HeadingTagType) => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
          $setBlocksType(selection, () => $createHeadingNode(headingSize));
        }
      });
    }
  };

  const formatBulletList = () => {
    if (blockType !== 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatCheckList = () => {
    if (blockType !== 'check') {
      editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatQuote = () => {
    if (blockType !== 'quote') {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
          $setBlocksType(selection, () => $createQuoteNode());
        }
      });
    }
  };

  const formatCode = () => {
    if (blockType !== 'code') {
      editor.update(() => {
        let selection = $getSelection();

        if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
          if (selection.isCollapsed()) {
            $setBlocksType(selection, () => $createCodeNode());
          } else {
            const textContent = selection.getTextContent();
            const codeNode = $createCodeNode();
            selection.insertNodes([codeNode]);
            selection = $getSelection();
            if ($isRangeSelection(selection)) selection.insertRawText(textContent);
          }
        }
      });
    }
  };

  return (
    <DropDown
      disabled={disabled}
      buttonClassName="toolbar-item block-controls"
      buttonIconClassName={'icon block-type ' + blockType}
      buttonLabel={blockTypeToBlockName[blockType]}
      buttonAriaLabel="Formatting options for text style"
    >
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'paragraph')}
        onClick={formatParagraph}
      >
        <i className="icon paragraph" />
        <span className="text">Normal</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'h1')}
        onClick={() => formatHeading('h1')}
      >
        <i className="icon h1" />
        <span className="text">Heading 1</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'h2')}
        onClick={() => formatHeading('h2')}
      >
        <i className="icon h2" />
        <span className="text">Heading 2</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'h3')}
        onClick={() => formatHeading('h3')}
      >
        <i className="icon h3" />
        <span className="text">Heading 3</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'bullet')}
        onClick={formatBulletList}
      >
        <i className="icon bullet-list" />
        <span className="text">Bullet List</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'number')}
        onClick={formatNumberedList}
      >
        <i className="icon numbered-list" />
        <span className="text">Numbered List</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'check')}
        onClick={formatCheckList}
      >
        <i className="icon check-list" />
        <span className="text">Check List</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'quote')}
        onClick={formatQuote}
      >
        <i className="icon quote" />
        <span className="text">Quote</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'code')}
        onClick={formatCode}
      >
        <i className="icon code" />
        <span className="text">Code Block</span>
      </DropDownItem>
    </DropDown>
  );
}

function Divider(): JSX.Element {
  return <div className="divider" />;
}

function FontDropDown({
  editor,
  value,
  style,
  disabled = false,
}: {
  editor: LexicalEditor;
  value: string;
  style: string;
  disabled?: boolean;
}): JSX.Element {
  const handleClick = useCallback(
    (option: string) => {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $patchStyleText(selection, {
            [style]: option,
          });
        }
      });
    },
    [editor, style]
  );

  const buttonAriaLabel =
    style === 'font-family'
      ? 'Formatting options for font family'
      : 'Formatting options for font size';

  return (
    <DropDown
      disabled={disabled}
      buttonClassName={'toolbar-item ' + style}
      buttonLabel={value}
      buttonIconClassName={style === 'font-family' ? 'icon block-type font-family' : ''}
      buttonAriaLabel={buttonAriaLabel}
    >
      {(style === 'font-family' ? FONT_FAMILY_OPTIONS : FONT_SIZE_OPTIONS).map(([option, text]) => (
        <DropDownItem
          className={`item ${dropDownActiveClass(value === option)} ${
            style === 'font-size' ? 'fontsize-item' : ''
          }`}
          onClick={() => handleClick(option)}
          key={option}
        >
          <span className="text">{text}</span>
        </DropDownItem>
      ))}
    </DropDown>
  );
}

// async function sendEditorState(editor: LexicalEditor): Promise<void> {

//      const stringifiedEditorState = JSON.stringify(
//       editor.getEditorState().toJSON(),
//     );
//     const parsedEditorState = editor.parseEditorState(stringifiedEditorState);

//     const editorStateTextString = parsedEditorState.read(() => $getRoot().getTextContent())
//     console.log(editorStateTextString, parsedEditorState,'check')
//   // const stringifiedEditorState = JSON.stringify(editor.getEditorState());
//   // try {
//   //   await fetch('http://localhost:1235/setEditorState', {
//   //     body: stringifiedEditorState,
//   //     headers: {
//   //       Accept: 'application/json',
//   //       'Content-type': 'application/json'
//   //     },
//   //     method: 'POST'
//   //   });
//   // } catch {
//   //   // NO-OP
//   // }
// }

export default function ToolbarPlugin(): JSX.Element {
  const [editor] = useLexicalComposerContext();
  const { editorStore, sopStore, lorStore, uKSopStore, canadaSopStore, germanyStore } = useStore();
  const { documentID, folderID } = useParams();
  const [activeEditor, setActiveEditor] = useState(editor);
  const [blockType, setBlockType] = useState<keyof typeof blockTypeToBlockName>('paragraph');
  const [rootType, setRootType] = useState<keyof typeof rootTypeToRootName>('root');
  const [selectedElementKey, setSelectedElementKey] = useState<NodeKey | null>(null);
  const [fontSize, setFontSize] = useState<string>('15px');
  const [fontColor, setFontColor] = useState<string>('#000');
  const [bgColor, setBgColor] = useState<string>('#fff');
  const [fontFamily, setFontFamily] = useState<string>('Arial');
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isSubscript, setIsSubscript] = useState(false);
  const [isSuperscript, setIsSuperscript] = useState(false);
  const [isCode, setIsCode] = useState(false);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [modal, showModal] = useModal();
  const [isRTL, setIsRTL] = useState(false);
  const [codeLanguage, setCodeLanguage] = useState<string>('');
  const [isEditable, setIsEditable] = useState(() => editor.isEditable());
  const [isSpeechToText, setIsSpeechToText] = useState(false);

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));
      setIsSubscript(selection.hasFormat('subscript'));
      setIsSuperscript(selection.hasFormat('superscript'));
      setIsCode(selection.hasFormat('code'));
      setIsRTL($isParentElementRTL(selection));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }

      const tableNode = $findMatchingParent(node, $isTableNode);
      if ($isTableNode(tableNode)) {
        setRootType('table');
      } else {
        setRootType('root');
      }

      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode);
          const type = parentList ? parentList.getListType() : element.getListType();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          if (type in blockTypeToBlockName) {
            setBlockType(type as keyof typeof blockTypeToBlockName);
          }
          if ($isCodeNode(element)) {
            const language = element.getLanguage() as keyof typeof CODE_LANGUAGE_MAP;
            setCodeLanguage(language ? CODE_LANGUAGE_MAP[language] || language : '');
            return;
          }
        }
      }
      // Handle buttons
      setFontSize($getSelectionStyleValueForProperty(selection, 'font-size', '15px'));
      setFontColor($getSelectionStyleValueForProperty(selection, 'color', '#000'));
      setBgColor($getSelectionStyleValueForProperty(selection, 'background-color', '#fff'));
      setFontFamily($getSelectionStyleValueForProperty(selection, 'font-family', 'Arial'));
    }
  }, [activeEditor]);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        $updateToolbar();
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor, $updateToolbar]);

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener((editable) => {
        setIsEditable(editable);
      }),
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar();
        });
      }),
      activeEditor.registerCommand<boolean>(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      activeEditor.registerCommand<boolean>(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      )
    );
  }, [$updateToolbar, activeEditor, editor]);

  useEffect(() => {
    return activeEditor.registerCommand(
      KEY_MODIFIER_COMMAND,
      (payload) => {
        const event: KeyboardEvent = payload;
        const { code, ctrlKey, metaKey } = event;

        if (code === 'KeyK' && (ctrlKey || metaKey)) {
          event.preventDefault();
          return activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl('https://'));
        }
        return false;
      },
      COMMAND_PRIORITY_NORMAL
    );
  }, [activeEditor, isLink]);

  const applyStyleText = useCallback(
    (styles: Record<string, string>) => {
      activeEditor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $patchStyleText(selection, styles);
        }
      });
    },
    [activeEditor]
  );

  const clearFormatting = useCallback(() => {
    activeEditor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const anchor = selection.anchor;
        const focus = selection.focus;
        const nodes = selection.getNodes();

        if (anchor.key === focus.key && anchor.offset === focus.offset) {
          return;
        }

        nodes.forEach((node, idx) => {
          // We split the first and last node by the selection
          // So that we don't format unselected text inside those nodes
          if ($isTextNode(node)) {
            if (idx === 0 && anchor.offset !== 0) {
              node = node.splitText(anchor.offset)[1] || node;
            }
            if (idx === nodes.length - 1) {
              node = node.splitText(focus.offset)[0] || node;
            }

            if (node.__style !== '') {
              node.setStyle('');
            }
            if (node.__format !== 0) {
              node.setFormat(0);
              $getNearestBlockElementAncestorOrThrow(node).setFormat('');
            }
          } else if ($isHeadingNode(node) || $isQuoteNode(node)) {
            node.replace($createParagraphNode(), true);
          } else if ($isDecoratorBlockNode(node)) {
            node.setFormat('');
          }
        });
      }
    });
  }, [activeEditor]);

  const onFontColorSelect = useCallback(
    (value: string) => {
      applyStyleText({ color: value });
    },
    [applyStyleText]
  );

  const onBgColorSelect = useCallback(
    (value: string) => {
      applyStyleText({ 'background-color': value });
    },
    [applyStyleText]
  );

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl('https://'));
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  const onCodeLanguageSelect = useCallback(
    (value: string) => {
      activeEditor.update(() => {
        if (selectedElementKey !== null) {
          const node = $getNodeByKey(selectedElementKey);
          if ($isCodeNode(node)) {
            node.setLanguage(value);
          }
        }
      });
    },
    [activeEditor, selectedElementKey]
  );
  const insertGifOnClick = (payload: InsertImagePayload) => {
    activeEditor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);
  };

  const sendEditorState = async (editor: LexicalEditor): Promise<void> => {
    /*
    const editor_state = editor.getEditorState().toJSON();
    const parsedEditorState = editor.parseEditorState(JSON.stringify(editor_state));
    const plain_text = parsedEditorState.read(() => $getRoot().getTextContent()) || '';
    editorStore.put_editor(editorStore.payload?.documentID, plain_text, editor_state, {});
    */
    const resp = await editorStore.save_editor(editor);
    if (
      window.location.pathname.includes('/sop') ||
      window.location.pathname.includes('/australiasop')
    ) {
      sopStore.setGeneratedOutput(resp);
    } else if (window.location.pathname.includes('/canadasop')) {
      canadaSopStore.setGeneratedOutput(resp);
    } else if (window.location.pathname.includes('/uksop')) {
      uKSopStore.setGeneratedOutput(resp);
    } else if (window.location.pathname.includes('/lor')) {
      lorStore.setGeneratedOutput(resp);
    } else if (window.location.pathname.includes('/germanysop')) {
      germanyStore.setGeneratedOutput(resp);
    } else {
      editorStore.setGeneratedOutput(resp);
    }
  };
  const OpenApiStatus = localStorage.getItem('openApiStatus');
  return (
    <div
      className="toolbar border-b border-gray-300 overflow-hidden"
      style={{
        position: 'fixed',
        top: editorStore.stepper_show_toggle
          ? '12rem'
          : editorStore.stepper_show_toggle && OpenApiStatus
          ? '14rem'
          : '5rem',
        width: '100%',
        zIndex: 1,
        borderTop: '1px solid #AAB7B8',
        borderBottom: '1px solid #AAB7B8',
      }}
    >
      {/*made it ffixed*/}
      <button
        disabled={!canUndo || !isEditable}
        onClick={() => {
          activeEditor.dispatchCommand(UNDO_COMMAND, undefined);
        }}
        title={IS_APPLE ? 'Undo (⌘Z)' : 'Undo (Ctrl+Z)'}
        type="button"
        className="toolbar-item spaced"
        aria-label="Undo"
      >
        <i className="format undo" />
      </button>
      <button
        disabled={!canRedo || !isEditable}
        onClick={() => {
          activeEditor.dispatchCommand(REDO_COMMAND, undefined);
        }}
        title={IS_APPLE ? 'Redo (⌘Y)' : 'Redo (Ctrl+Y)'}
        type="button"
        className="toolbar-item"
        aria-label="Redo"
      >
        <i className="format redo" />
      </button>
      <Divider />
      {blockType in blockTypeToBlockName && activeEditor === editor && (
        <>
          <BlockFormatDropDown
            disabled={!isEditable}
            blockType={blockType}
            rootType={rootType}
            editor={editor}
          />
          <Divider />
        </>
      )}
      {blockType === 'code' ? (
        <DropDown
          disabled={!isEditable}
          buttonClassName="toolbar-item code-language"
          buttonLabel={getLanguageFriendlyName(codeLanguage)}
          buttonAriaLabel="Select language"
        >
          {CODE_LANGUAGE_OPTIONS.map(([value, name]) => {
            return (
              <DropDownItem
                className={`item ${dropDownActiveClass(value === codeLanguage)}`}
                onClick={() => onCodeLanguageSelect(value)}
                key={value}
              >
                <span className="text">{name}</span>
              </DropDownItem>
            );
          })}
        </DropDown>
      ) : (
        <>
          {/*<FontDropDown
            disabled={!isEditable}
            style={'font-family'}
            value={fontFamily}
            editor={editor}
          />
          <FontDropDown
            disabled={!isEditable}
            style={'font-size'}
            value={fontSize}
            editor={editor}
          />
          <Divider />*/}
          <button
            disabled={!isEditable}
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
            }}
            className={'toolbar-item spaced ' + (isBold ? 'active' : '')}
            title={IS_APPLE ? 'Bold (⌘B)' : 'Bold (Ctrl+B)'}
            type="button"
            aria-label={`Format text as bold. Shortcut: ${IS_APPLE ? '⌘B' : 'Ctrl+B'}`}
          >
            <i className="format bold" />
          </button>
          <button
            disabled={!isEditable}
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
            }}
            className={'toolbar-item spaced ' + (isItalic ? 'active' : '')}
            title={IS_APPLE ? 'Italic (⌘I)' : 'Italic (Ctrl+I)'}
            type="button"
            aria-label={`Format text as italics. Shortcut: ${IS_APPLE ? '⌘I' : 'Ctrl+I'}`}
          >
            <i className="format italic" />
          </button>
          <button
            disabled={!isEditable}
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
            }}
            className={'toolbar-item spaced ' + (isUnderline ? 'active' : '')}
            title={IS_APPLE ? 'Underline (⌘U)' : 'Underline (Ctrl+U)'}
            type="button"
            aria-label={`Format text to underlined. Shortcut: ${IS_APPLE ? '⌘U' : 'Ctrl+U'}`}
          >
            <i className="format underline" />
          </button>
          <button
            disabled={!isEditable}
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'code');
            }}
            className={'toolbar-item spaced ' + (isCode ? 'active' : '')}
            title="Insert code block"
            type="button"
            aria-label="Insert code block"
          >
            <i className="format code" />
          </button>
          <button
            disabled={!isEditable}
            onClick={insertLink}
            className={'toolbar-item spaced ' + (isLink ? 'active' : '')}
            aria-label="Insert link"
            title="Insert link"
            type="button"
          >
            <i className="format link" />
          </button>
          <DropdownColorPicker
            disabled={!isEditable}
            buttonClassName="toolbar-item color-picker"
            buttonAriaLabel="Formatting text color"
            buttonIconClassName="icon font-color"
            color={fontColor}
            onChange={onFontColorSelect}
            title="text color"
          />
          <DropdownColorPicker
            disabled={!isEditable}
            buttonClassName="toolbar-item color-picker"
            buttonAriaLabel="Formatting background color"
            buttonIconClassName="icon bg-color"
            color={bgColor}
            onChange={onBgColorSelect}
            title="bg color"
          />
          <DropDown
            disabled={!isEditable}
            buttonClassName="toolbar-item spaced"
            buttonLabel=""
            buttonAriaLabel="Formatting options for additional text styles"
            buttonIconClassName="icon dropdown-more"
          >
            <DropDownItem
              onClick={() => {
                activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
              }}
              className={'item ' + dropDownActiveClass(isStrikethrough)}
              title="Strikethrough"
              aria-label="Format text with a strikethrough"
            >
              <i className="icon strikethrough" />
              <span className="text">Strikethrough</span>
            </DropDownItem>
            <DropDownItem
              onClick={() => {
                activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'subscript');
              }}
              className={'item ' + dropDownActiveClass(isSubscript)}
              title="Subscript"
              aria-label="Format text with a subscript"
            >
              <i className="icon subscript" />
              <span className="text">Subscript</span>
            </DropDownItem>
            <DropDownItem
              onClick={() => {
                activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'superscript');
              }}
              className={'item ' + dropDownActiveClass(isSuperscript)}
              title="Superscript"
              aria-label="Format text with a superscript"
            >
              <i className="icon superscript" />
              <span className="text">Superscript</span>
            </DropDownItem>
            <DropDownItem
              onClick={clearFormatting}
              className="item"
              title="Clear text formatting"
              aria-label="Clear all text formatting"
            >
              <i className="icon clear" />
              <span className="text">Clear Formatting</span>
            </DropDownItem>
          </DropDown>
          <Divider />
          {rootType === 'table' && (
            <>
              <DropDown
                disabled={!isEditable}
                buttonClassName="toolbar-item spaced"
                buttonLabel="Table"
                buttonAriaLabel="Open table toolkit"
                buttonIconClassName="icon table secondary"
              >
                <DropDownItem
                  onClick={() => {
                    /**/
                  }}
                  className="item"
                >
                  <span className="text">TODO</span>
                </DropDownItem>
              </DropDown>
              <Divider />
            </>
          )}
          <DropDown
            disabled={!isEditable}
            buttonClassName="toolbar-item spaced"
            buttonLabel="Insert"
            buttonAriaLabel="Insert specialized editor node"
            buttonIconClassName="icon plus"
          >
            <DropDownItem
              onClick={() => {
                activeEditor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined);
              }}
              className="item"
            >
              <i className="icon horizontal-rule" />
              <span className="text">Horizontal Rule</span>
            </DropDownItem>
            <DropDownItem
              onClick={() => {
                showModal('Insert Image', (onClose) => (
                  <InsertImageDialog activeEditor={activeEditor} onClose={onClose} />
                ));
              }}
              className="item"
            >
              <i className="icon image" />
              <span className="text">Image</span>
            </DropDownItem>
            <DropDownItem
              onClick={() => {
                showModal('Insert Inline Image', (onClose) => (
                  <InsertInlineImageDialog activeEditor={activeEditor} onClose={onClose} />
                ));
              }}
              className="item"
            >
              <i className="icon image" />
              <span className="text">Inline Image</span>
            </DropDownItem>
            <DropDownItem
              onClick={() =>
                insertGifOnClick({
                  altText: 'Cat typing on a laptop',
                  src: catTypingGif,
                })
              }
              className="item"
            >
              <i className="icon gif" />
              <span className="text">GIF</span>
            </DropDownItem>
            {/*<DropDownItem
              onClick={() => {
                activeEditor.dispatchCommand(
                  INSERT_EXCALIDRAW_COMMAND,
                  undefined,
                );
              }}
              className="item">
              <i className="icon diagram-2" />
              <span className="text">Excalidraw</span>
            </DropDownItem>*/}
            {/*<DropDownItem
              onClick={() => {
                showModal('Insert Table', (onClose) => (
                  <InsertTableDialog
                    activeEditor={activeEditor}
                    onClose={onClose}
                  />
                ));
              }}
              className="item">
              <i className="icon table" />
              <span className="text">Table</span>
            </DropDownItem>*/}
            <DropDownItem
              onClick={() => {
                showModal('Insert Table', (onClose) => (
                  <InsertNewTableDialog activeEditor={activeEditor} onClose={onClose} />
                ));
              }}
              className="item"
            >
              <i className="icon table" />
              <span className="text">Table</span>
            </DropDownItem>
            {/*<DropDownItem
              onClick={() => {
                showModal('Insert Poll', (onClose) => (
                  <InsertPollDialog
                    activeEditor={activeEditor}
                    onClose={onClose}
                  />
                ));
              }}
              className="item">
              <i className="icon poll" />
              <span className="text">Poll</span>
            </DropDownItem>*/}

            {/*<DropDownItem
              onClick={() => {
                showModal('Insert Equation', (onClose) => (
                  <InsertEquationDialog
                    activeEditor={activeEditor}
                    onClose={onClose}
                  />
                ));
              }}
              className="item">
              <i className="icon equation" />
              <span className="text">Equation</span>
            </DropDownItem>*/}
            {/*<DropDownItem
              onClick={() => {
                editor.update(() => {
                  const root = $getRoot();
                  const stickyNode = $createStickyNode(0, 0);
                  root.append(stickyNode);
                });
              }}
              className="item">
              <i className="icon sticky" />
              <span className="text">Sticky Note</span>
            </DropDownItem>*/}
            {/*<DropDownItem
              onClick={() => {
                editor.dispatchCommand(INSERT_COLLAPSIBLE_COMMAND, undefined);
              }}
              className="item">
              <i className="icon caret-right" />
              <span className="text">Collapsible container</span>
            </DropDownItem>*/}
            {EmbedConfigs.map((embedConfig) => (
              <DropDownItem
                key={embedConfig.type}
                onClick={() => {
                  activeEditor.dispatchCommand(INSERT_EMBED_COMMAND, embedConfig.type);
                }}
                className="item"
              >
                {embedConfig.icon}
                <span className="text">{embedConfig.contentName}</span>
              </DropDownItem>
            ))}
          </DropDown>
        </>
      )}
      <Divider />
      <DropDown
        disabled={!isEditable}
        buttonLabel="Align"
        buttonIconClassName="icon left-align"
        buttonClassName="toolbar-item spaced alignment"
        buttonAriaLabel="Formatting options for text alignment"
      >
        <DropDownItem
          onClick={() => {
            activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
          }}
          className="item"
        >
          <i className="icon left-align" />
          <span className="text">Left Align</span>
        </DropDownItem>
        <DropDownItem
          onClick={() => {
            activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
          }}
          className="item"
        >
          <i className="icon center-align" />
          <span className="text">Center Align</span>
        </DropDownItem>
        <DropDownItem
          onClick={() => {
            activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
          }}
          className="item"
        >
          <i className="icon right-align" />
          <span className="text">Right Align</span>
        </DropDownItem>
        <DropDownItem
          onClick={() => {
            activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');
          }}
          className="item"
        >
          <i className="icon justify-align" />
          <span className="text">Justify Align</span>
        </DropDownItem>
        <Divider />
        <DropDownItem
          onClick={() => {
            activeEditor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
          }}
          className="item"
        >
          <i className={'icon ' + (isRTL ? 'indent' : 'outdent')} />
          <span className="text">Outdent</span>
        </DropDownItem>
        <DropDownItem
          onClick={() => {
            activeEditor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
          }}
          className="item"
        >
          <i className={'icon ' + (isRTL ? 'outdent' : 'indent')} />
          <span className="text">Indent</span>
        </DropDownItem>
      </DropDown>

      <Divider />

      <div className="flex flex-row-reverse" style={{}}>
        {/* comment plugin commmented*/}
        {/*
        <CommentPlugin providerFactory={undefined}/>

        <Divider/> */}
        {/* sppech plugin commmented*/}
        {/* {SUPPORT_SPEECH_RECOGNITION && (
          <button
            onClick={() => {
              editor.dispatchCommand(SPEECH_TO_TEXT_COMMAND, !isSpeechToText);
              setIsSpeechToText(!isSpeechToText);
            }}
            className={
              'toolbar-button ' +
              (isSpeechToText ? 'active' : '')
            }
            style={{ marginTop: -1 }}
            title="Dictate"
            aria-label={`${
              isSpeechToText ? 'Enable' : 'Disable'
            } speech to text`}>
            <i className="fa-solid fa-microphone"/>
          </button>
        )}

        <Divider/> */}

        <button
          className="toolbar-button"
          onClick={(event) => {}}
          style={{ marginTop: -1 }}
          title="Share Document"
          aria-label="Share document with others"
        >
          <i className="fa-solid fa-share"></i>
        </button>

        <Divider />

        <button
          className="toolbar-button"
          onClick={(event) => {
            editorStore.stepper_display_toggle();
          }}
          style={{ marginTop: -1 }}
          title="Show Progress Visual"
          aria-label="Show Progress Visual"
        >
          <i className="fa-solid fa-bars-progress" />
        </button>

        <Divider />

        <button
          className="toolbar-button"
          onClick={(event) => {
            editorStore.history_pane_show();
          }}
          style={{ marginTop: -1 }}
          title="History"
          aria-label="See history of this document"
        >
          <i className="fa-solid fa-rectangle-vertical-history"></i>
        </button>

        <Divider />

        <button
          className="toolbar-button"
          onClick={(event) => {
            editorStore.stepper_update_export();

            const html_nodes = editor
              .getEditorState()
              .read(() => $generateHtmlFromNodes(editor, null));
            editorStore.export_as_word(editor, html_nodes);
            /*
            console.log(html_nodes);
            const fileBuffer = htmlDocx.asBlob(html_nodes);
            const path = window.location.pathname;
            let filename = path.includes('/lor') ? 'LOR.docx' : 'SOP.docx';
            saveAs(fileBuffer, filename);
            */
          }}
          style={{ marginTop: -1 }}
          title="Export Word Document"
          aria-label="Export Word Document"
        >
          <i className="fa-solid fa-file-word" />
        </button>

        <Divider />
        {editorStore.save === false && !window.location.pathname.includes('/lor') ? (
          editorStore.save === false && !window.location.pathname.includes('/germanysop') ? (
            <button
              className="toolbar-button"
              onClick={(event) => {}}
              style={{ marginTop: -1 }}
              title="Export PDF Document"
              aria-label="Export PDF Document"
            >
              {editorStore.loading ? null : (
                <PDFDownloadLink
                  document={<PDF sopStore={sopStore} />}
                  fileName={window.location.pathname.includes('/lor') ? 'LOR.pdf' : 'SOP.pdf'}
                >
                  <i className="fa-solid fa-file-pdf" />
                </PDFDownloadLink>
              )}
            </button>
          ) : editorStore.save === false && window.location.pathname.includes('/germanysop') ? (
            <button
              className="toolbar-button"
              onClick={(event) => {}}
              style={{ marginTop: -1 }}
              title="Export PDF Document"
              aria-label="Export PDF Document"
            >
              {editorStore.loading ? null : (
                <PDFDownloadLink
                  document={<PDF sopStore={editorStore.sopText} />}
                  fileName={window.location.pathname.includes('/lor') ? 'LOR.pdf' : 'SOP.pdf'}
                >
                  <i className="fa-solid fa-file-pdf" />
                </PDFDownloadLink>
              )}
            </button>
          ) : (
            <button
              className="toolbar-button"
              onClick={(event) => {}}
              style={{ marginTop: -1 }}
              title="Export PDF Document"
              aria-label="Export PDF Document"
            >
              {editorStore.loading ? null : (
                <PDFDownloadLink
                  document={<PDF sopStore={editorStore.pdfDetails} />}
                  fileName={window.location.pathname.includes('/lor') ? 'LOR.pdf' : 'SOP.pdf'}
                >
                  <i className="fa-solid fa-file-pdf" />
                </PDFDownloadLink>
              )}
            </button>
          )
        ) : editorStore.save === false && window.location.pathname.includes('/lor') ? (
          <button
            className="toolbar-button"
            onClick={(event) => {}}
            style={{ marginTop: -1 }}
            title="Export PDF Document"
            aria-label="Export PDF Document"
          >
            {editorStore.loading ? null : (
              <PDFDownloadLink
                document={<PDF sopStore={editorStore.sopText} />}
                fileName={window.location.pathname.includes('/lor') ? 'LOR.pdf' : 'SOP.pdf'}
              >
                <i className="fa-solid fa-file-pdf" />
              </PDFDownloadLink>
            )}
          </button>
        ) : (
          <button
            className="toolbar-button"
            onClick={(event) => {}}
            style={{ marginTop: -1 }}
            title="Export PDF Document"
            aria-label="Export PDF Document"
          >
            {editorStore.loading ? null : (
              <PDFDownloadLink
                document={<PDF sopStore={editorStore.pdfDetails} />}
                fileName={window.location.pathname.includes('/lor') ? 'LOR.pdf' : 'SOP.pdf'}
              >
                <i className="fa-solid fa-file-pdf" />
              </PDFDownloadLink>
            )}
          </button>
        )}

        <Divider />
        {editorStore.save === false && !window.location.pathname.includes('/lor') ? (
          !window.location.pathname.includes('/germanysop') ? (
            <button
              className="toolbar-button"
              onClick={(event) => {
                navigator.clipboard.writeText(sopStore?.generatedOutput);
                toast.success('Copied');
              }}
              style={{ marginTop: -1 }}
              title="Copy"
              aria-label="Copy"
            >
              <i className="fa-solid fa-copy"></i>
            </button>
          ) : (
            <>
              <button
                className="toolbar-button"
                onClick={(event) => {
                  navigator.clipboard.writeText(editorStore.sopText);
                  toast.success('Copied');
                }}
                style={{ marginTop: -1 }}
                title="Copy"
                aria-label="Copy"
              >
                <i className="fa-solid fa-copy"></i>
              </button>
            </>
          )
        ) : editorStore.save === false && window.location.pathname.includes('/lor') ? (
          <button
            className="toolbar-button"
            onClick={(event) => {
              navigator.clipboard.writeText(editorStore.sopText);
              toast.success('Copied');
            }}
            style={{ marginTop: -1 }}
            title="Copy"
            aria-label="Copy"
          >
            <i className="fa-solid fa-copy"></i>
          </button>
        ) : editorStore.save === false && window.location.pathname.includes('/germanysop') ? (
          <button
            className="toolbar-button"
            onClick={(event) => {
              navigator.clipboard.writeText(editorStore.sopText);
              toast.success('Copied');
            }}
            style={{ marginTop: -1 }}
            title="Copy"
            aria-label="Copy"
          >
            <i className="fa-solid fa-copy"></i>
          </button>
        ) : (
          <button
            className="toolbar-button"
            onClick={(event) => {
              navigator.clipboard.writeText(editorStore.pdfDetails);
              toast.success('Copied');
            }}
            style={{ marginTop: -1 }}
            title="Copy"
            aria-label="Copy"
          >
            <i className="fa-solid fa-copy"></i>
          </button>
        )}

        <Divider />
        <button
          className={`toolbar-button ${!isEditable ? 'unlock' : 'lock'}`}
          onClick={() => {
            // Send latest editor state to commenting validation server
            if (isEditable) {
              sendEditorState(editor);
            }
            editor.setEditable(!editor.isEditable());
          }}
          style={{ marginTop: -1 }}
          title="Read-Only Mode"
          aria-label={`${!isEditable ? 'Unlock' : 'Lock'} read-only mode`}
        >
          <i className={!isEditable ? 'fa-regular fa-unlock' : 'fa-regular fa-lock'} />
        </button>

        <Divider />
        {/*save contents commented*/}

        <button
          className={`toolbar-button`}
          onClick={() => {
            editorStore.stepper_update_refine();
            sendEditorState(editor);
            editorStore.setSave(true);
          }}
          style={{ marginTop: -1 }}
          title="Save contents"
          aria-label={`Save contents`}
        >
          <i className={'fa-regular fa-floppy-disk'} />
        </button>

        <Divider />

        <button
          className={`toolbar-button`}
          onClick={() => {
            sopStore.toggle_show_plagiarism();
          }}
          style={{ marginTop: -1 }}
          title="Plagiarism Checker"
          aria-label={`Plagiarism Checker`}
        >
          <i className="fa-regular fa-print-magnifying-glass" />
        </button>

        <Divider />

        <button
          className="toolbar-button "
          onClick={() => {
            showModal('Clear editor', (onClose) => (
              <ShowClearDialog editor={editor} onClose={onClose} />
            ));
          }}
          style={{ marginTop: -1 }}
          title="Clear"
          aria-label="Clear editor contents"
        >
          <i className="fa-regular fa-eraser" />
        </button>

        {/* ask cambri plugin commmented*/}
        {/* <Divider/>

        <button
          className="toolbar-button items-center mx-auto "
          onClick={() => {

          }}
          style={{ marginTop: -2, marginLeft: 4 }}
          title="Ask Cambri"
          aria-label="Invoke Cambri AI Writer">
          <svg style={{ marginLeft: 18 }} width="32" height="32" viewBox="0 0 24 24" fill="none">
            <path
              d="M21.5279 20.4706L16.8338 15.7774C18.1944 14.144 18.8728 12.049 18.728 9.92813C18.5832 7.80726 17.6263 5.82387 16.0564 4.39054C14.4865 2.95721 12.4245 2.1843 10.2992 2.2326C8.17394 2.2809 6.14912 3.14669 4.64595 4.64986C3.14278 6.15303 2.277 8.17785 2.2287 10.3031C2.1804 12.4284 2.9533 14.4904 4.38663 16.0603C5.81996 17.6302 7.80336 18.5871 9.92422 18.7319C12.0451 18.8767 14.1401 18.1983 15.7735 16.8378L20.4667 21.5318C20.5363 21.6015 20.6191 21.6568 20.7101 21.6945C20.8012 21.7322 20.8987 21.7516 20.9973 21.7516C21.0958 21.7516 21.1934 21.7322 21.2845 21.6945C21.3755 21.6568 21.4582 21.6015 21.5279 21.5318C21.5976 21.4621 21.6529 21.3794 21.6906 21.2884C21.7283 21.1973 21.7477 21.0997 21.7477 21.0012C21.7477 20.9026 21.7283 20.8051 21.6906 20.714C21.6529 20.623 21.5976 20.5403 21.5279 20.4706ZM3.74728 10.5012C3.74728 9.16617 4.14316 7.86112 4.88486 6.75109C5.62656 5.64106 6.68077 4.7759 7.91417 4.265C9.14757 3.75411 10.5048 3.62044 11.8141 3.88089C13.1235 4.14134 14.3263 4.78422 15.2703 5.72822C16.2143 6.67222 16.8571 7.87496 17.1176 9.18433C17.378 10.4937 17.2444 11.8509 16.7335 13.0843C16.2226 14.3177 15.3574 15.3719 14.2474 16.1136C13.1374 16.8553 11.8323 17.2512 10.4973 17.2512C8.70768 17.2492 6.99195 16.5374 5.72651 15.272C4.46107 14.0065 3.74927 12.2908 3.74728 10.5012Z"
              fill="#A033FF"/>
            <path
              d="M6.30844 14.09C6.12177 14.09 5.96844 14.0467 5.84844 13.96C5.7351 13.8733 5.66177 13.7567 5.62844 13.61C5.60177 13.4633 5.63177 13.3 5.71844 13.12L8.27844 7.51C8.3851 7.28333 8.51177 7.12 8.65844 7.02C8.8051 6.91333 8.9751 6.86 9.16844 6.86C9.36177 6.86 9.53177 6.91333 9.67844 7.02C9.8251 7.12 9.94844 7.28333 10.0484 7.51L12.6284 13.12C12.7151 13.3 12.7451 13.4667 12.7184 13.62C12.6984 13.7667 12.6318 13.8833 12.5184 13.97C12.4051 14.05 12.2584 14.09 12.0784 14.09C11.8518 14.09 11.6751 14.0367 11.5484 13.93C11.4284 13.8233 11.3218 13.6567 11.2284 13.43L10.6284 12.04L11.2484 12.47H7.07844L7.69844 12.04L7.10844 13.43C7.00844 13.6633 6.90177 13.8333 6.78844 13.94C6.68177 14.04 6.52177 14.09 6.30844 14.09ZM9.14844 8.6L7.86844 11.65L7.59844 11.26H10.7284L10.4584 11.65L9.16844 8.6H9.14844ZM14.2677 14.08C14.0211 14.08 13.8311 14.01 13.6977 13.87C13.5711 13.7233 13.5077 13.52 13.5077 13.26V9.84C13.5077 9.57333 13.5711 9.37 13.6977 9.23C13.8311 9.08333 14.0211 9.01 14.2677 9.01C14.5077 9.01 14.6911 9.08333 14.8177 9.23C14.9511 9.37 15.0177 9.57333 15.0177 9.84V13.26C15.0177 13.52 14.9544 13.7233 14.8277 13.87C14.7011 14.01 14.5144 14.08 14.2677 14.08ZM14.2677 8.19C13.9877 8.19 13.7711 8.12667 13.6177 8C13.4711 7.86667 13.3977 7.68 13.3977 7.44C13.3977 7.19333 13.4711 7.00667 13.6177 6.88C13.7711 6.74667 13.9877 6.68 14.2677 6.68C14.5477 6.68 14.7611 6.74667 14.9077 6.88C15.0544 7.00667 15.1277 7.19333 15.1277 7.44C15.1277 7.68 15.0544 7.86667 14.9077 8C14.7611 8.12667 14.5477 8.19 14.2677 8.19Z"
              fill="#A033FF"/>
          </svg>
        </button> */}
      </div>
      {modal}
    </div>
  );
}
