import { observer } from 'mobx-react-lite';
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useStore } from '../../../../hooks/use-store';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDF from '../../../sop-components/PDF';
import InfoIcon from '../../../icons/circle.svg';
import { toast } from 'react-toastify';

export const TextEditor = observer((props) => {
  const { sopStore } = useStore();

  const onContentStateChange = (data) => {
    sopStore.update_editor_data(data);
  };

  /*if (documentStore.loading) return <Loading/>*/

  /* const saveDocument = async () => {
    const res = await documentStore.save_active_document()
    if (res.status === 200) {
      toast('Document saved successfully')
    } else {
      toast.error(res.data)
    }
  }*/

  return (
    <>
      <div className="flex flex-row flex-wrap items-center">
        {/*<div className='text-xl uppercase font-bold' style={{color: '#2E86C1'}}>sop</div>
        <div className='ml-auto'>
          <button onClick={saveDocument} className='py-2 px-16 my-2 bg-green-200 text-black text-bold rounded-lg uppercase'>Save</button>
        </div>*/}
      </div>
      {/* <div className="flex flex-row flex-wrap items-center justify-end my-4">
        <PDFDownloadLink
          className="p-[10px] rounded-lg bg-[#A033FF] text-[#FEFEFE] h-[40px]"
          document={<PDF />}
          fileName="sop.pdf"
        >
          {({ blob, url, loading, error }) => (loading ? 'Loading...' : 'Download')}
        </PDFDownloadLink>
      </div> */}
      <div className="flex justify-between p-2 bg-[#FCF3FF] rounded-t-lg border border-[#C7CBD4]">
        <div className="flex items-center pl-2">
          <p className="text-xl">Output</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#A033FF"
            className="w-6 h-6 ml-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
            />
          </svg>
        </div>
        <div className="flex items-center">
          <i
            onClick={() => {
              navigator.clipboard.writeText(sopStore.generatedOutput);
              toast.success('Copied');
            }}
            className="fa-regular fa-copy text-[#A033FF] text-[25px] text-sm mx-8 cursor-pointer"
          />
          {/* <div>
              <button className='uppercase text-[#A033FF] text-xs font-bold'>Open in Cambri Editor</button>
            </div> */}
        </div>
      </div>
      <div className="mb-5">
        <Editor
          key={sopStore.editor_id}
          className="bg-white"
          textAlignment={'left'}
          toolbarHidden
          toolbarClassName="toolbarClassName"
          initialContentState={sopStore.get_initial_json_for_editor()}
          wrapperClassName="wrapperClassName"
          editorClassName="z-0 relative text-[#4E5664] bg-white border border-[#C7CBD4] rounded-b-xl leading-6 p-8 text-base min-h-code-50"
          onContentStateChange={onContentStateChange}
        />
      </div>
    </>
  );
});
