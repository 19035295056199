import { action, makeAutoObservable, toJS } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { ResumeAPI } from '../api/resume-api';


const resume_progress_stepper_states = [
  {
    num:0,
    key:'users',
    name:'Tell us the user’s name',
  },
  {
    num: 1,
    key: 'upload',
    name: 'Add your Resume to Cambri',
  },
  {
    num: 2,
    key: 'location',
    name: 'Your Location',
  },
  {
    num: 3,
    key: 'education',
    name: 'Add your education',
  },
  {
    num: 4,
    key: 'workex',
    name: 'Add Work Experience',
  },
  {
    num: 5,
    key: 'skills',
    name: 'Add your skills',
  },
  {
    num: 6,
    key: 'certs',
    name: 'Add your Certifications or Licenses',
  },
];

export class ResumeStore {
  api: ResumeAPI;

  showResumeCreationModal: boolean = false;
  current_step_num: number = resume_progress_stepper_states[0]['num'];
  current_step: any = resume_progress_stepper_states[0];
  upload_screen = 'initial-choice';
  continueClicked: boolean = false;
  extracted_resume: any = {};
  navigate: boolean = false;
  continueButton: boolean = true;
  backButton: boolean = true;
  processing_complete: boolean = false;
  clearResumeData:boolean = false;
  selectedCard ='';

  constructor(private resumeAPI: ResumeAPI) {
    this.api = resumeAPI;
    makeAutoObservable(this);
    makePersistable(this, { name: 'ResumeStore', properties: [], storage: window.localStorage });
    this.setSelectedCard = this.setSelectedCard.bind(this);
  }


@action 
setClearResumeData(value){
  this.clearResumeData = value;
}
  @action set_current_step(value,name){
    this.current_step_num = value
    this.current_step['num'] = value
    this.current_step['name'] = name
  }
  
  @action
  setNavigate(value) {
    this.navigate = value;
  }
  @action
  setContinueClicked(value) {
    this.continueClicked = value;
  }
  @action
  setContinueButton(value) {
    this.continueButton = value;
  }
  @action
  setBackButton(value) {
    this.backButton = value;
  }
  @action
  setUploadScreen(value) {
    this.upload_screen = value;
  }
  @action
  show_resume_creation_modal() {
    this.showResumeCreationModal = true;
  }

  @action
  hide_resume_creation_modal() {
    this.showResumeCreationModal = false;
    setTimeout(() => {
      this.resetState();
    }, 300);
  }

@action 
 setProcessing_complete(value){
  this.processing_complete = value
 }

  @action
  resume_progress_nav_next() {
    const new_idx = this.current_step_num + 1;
    if (new_idx >= resume_progress_stepper_states.length) return;
    this.current_step_num = new_idx;
    this.current_step = resume_progress_stepper_states[new_idx];
    // if (this.resume_progress_is_last_step()) {
    //   this.resetState();
    //   // return; 
    // }
  }

  @action
  resume_progress_nav_previous() {
    const new_idx = this.current_step_num - 1;
    if (new_idx < 0) return;
    this.current_step_num = new_idx;
    this.current_step = resume_progress_stepper_states[new_idx];
  }

  @action
  resume_progress_is_last_step() {
    return this.current_step_num === resume_progress_stepper_states.length - 1;
  }

  @action
  resume_progress_get_current_state() {
    return `${this.current_step_num + 1}/${resume_progress_stepper_states.length}`;
  }
  @action
  resume_progress_get_previous_state() {
    return `${this.current_step_num - 1}/${resume_progress_stepper_states.length}`;
  }
  @action
  resume_progress_stepper_status() {
    const pre = this.current_step_num;
    const post = resume_progress_stepper_states.length - this.current_step_num - 1;
    return { pre, post };
  }

  @action
  set_extracted_resume(ds) {
    this.extracted_resume = ds;
    this.unpack_to_store(ds);
  }
  @action
  setSelectedCard(payload){
  
    this.selectedCard = payload
    console.log(this.selectedCard,"SELECTEDCARD");
  }
  @action
  resetState() {
    this.showResumeCreationModal = false;
    this.current_step_num = resume_progress_stepper_states[0]['num'];
    this.current_step = resume_progress_stepper_states[0];
    this.upload_screen = 'initial-choice';
    this.continueClicked = false;
    this.extracted_resume = {};
    this.navigate = false;
    this.continueButton = true;
    this.backButton = true;
  }
  unpack_to_store(ds) {}
}
