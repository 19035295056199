import { Disclosure, Transition } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../hooks/use-store';

const Accordion = observer((props: any) => {
  const {sopStore} = useStore()
  return (
    <>
      <div className="w-full px-4 pt-4 border-b-[#E3E5E9] border-b">
        <div className="mx-auto w-full max-w-md rounded-2xl bg-white p-2">
          <Disclosure defaultOpen = {props.label2 == 'step 1' || props.label2 == ""}>
            {({ open }) => (
              <>
                <Disclosure.Button onClick={()=>sopStore.set_active_step(props.label2)} className="flex w-full items-center justify-between rounded-lg px-4 py-2 text-left text-sm font-medium text-purple-900 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <div>
                    <p className="uppercase text-[#737E93] text-[10px]">{props.label2}</p>
                    <p className="text-lg text-[#292D35]">{props.label}</p>
                  </div>

                  <ChevronUpIcon
                    className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-black`}
                  />
                </Disclosure.Button>

                <Transition
                  show={open}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel static className="px-4 pt-4 pb-2 text-sm text-gray-500">
                    {props.children}
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        </div>
      </div>
    </>
  );
});

export default Accordion;
