import React, { useEffect,useState } from 'react';
// import Header from "components/Header";
import Tree from './tree';
import { useStore } from '../../hooks/use-store';
// import ExternalInfo from "components/ExternalInfo";

const TreeList = () => {
  const { newContentStore,sopStore,uKSopStore ,userStore, canadaSopStore,lorStore} = useStore();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        await load_contents();
        sopStore.setIsFromCreateSop(false);
        uKSopStore.setIsFromCreateSop(false);
        canadaSopStore.setIsFromCreateSop(false);
        lorStore.setIsFromCreateSop(false);
        userStore.credits = newContentStore.credits;
      } catch (error) {
        // Handle any errors that occur during the async operation
        console.error('Error loading contents:', error);
      }
    }
  
    fetchData();
  }, []);
  
  const load_contents = async () => {
    setLoading(true);
    await newContentStore.get_all_contents();
    setLoading(false);
  };

  return (
    <> 
      <div className="flex w-full">
        <div className="flex-col w-full">
            <div className="flex w-full">
              <div className="col-lg-8 text-left text-dark w-full">
                <Tree data={newContentStore.contents} />
              </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default TreeList;
