import { useStore } from '../../hooks/use-store';
import { observer } from 'mobx-react-lite';
import RadioButton from '../../components/radio-button/radio-button';
export const SelectB2b = observer((props: any) => {
  const { b2bStore ,userStore} = useStore();

  const handle_form_submit = async (event) => {
    console.log(event);
  };
  const card_style = 'shadow-lg rounded-xl mx-auto px-2 py-2 cursor-pointer';
  const handleCardClick = (cardName) => {
    b2bStore.setSelectedCard(cardName);
  };

  return (
    <>
      <div
        className={`${
          b2bStore.selectedCard == 'business' && 'border border-[#A033FF] bg-[#FCF3FF]'
        } ${card_style} mb-10 `}
        style={{ width: 300, height: 150 }}
        onClick={() => {
          handleCardClick('business');
          b2bStore.setSelectedCard('business');
          userStore.isenterprise = true;
        }}
      >
        <div className="flex justify-end relative">
          <RadioButton checked={b2bStore.selectedCard == 'business'} />
        </div>
        <div className="flex flex-col">
          <i className="fa-duotone fa-briefcase fa-2xl m-auto " style={{ color: '#7751e1' }}></i>
          <div className="text-[24px] leading-[32px] mx-auto pt-2 text-[#3C424C] mt-5">
            BUSINESS
          </div>
        </div>
      </div>

      <div
        className={`${
          b2bStore.selectedCard == 'regular' && 'border border-[#A033FF] bg-[#FCF3FF]'
        } ${card_style} mb-10 `}
        style={{ width: 300, height: 150 }}
        onClick={() => {
          handleCardClick('regular');
          b2bStore.setSelectedCard('regular');
          userStore.isenterprise = false;
        }}
      >
        <div className="flex justify-end relative">
          <RadioButton checked={b2bStore.selectedCard == 'regular'} />
        </div>
        <div className="flex flex-col">
          <i className="fa-solid fa-user-tie fa-2xl m-auto" style={{ color: '#1c5bca' }}></i>
          <div className="text-[24px] leading-[32px] mx-auto pt-2 text-[#3C424C] mt-5">STUDENT</div>
        </div>
      </div>
    </>
  );
});
